import { useEffect, useState } from "react";
import { getSiteTheme, editSiteTheme } from "../../api/siteSetting";
import PageContainer from "../layout/PageContainer";
import PageTitle, { Title } from "../PageTitle";
import Line from "./Line";
import DataBox from "./DataBox";
import Button, { ButtonType } from "../Button";
import { SiteSettingType } from "../../types/types";
import AnalysisBox from "./AnalysisBox";

const SiteSetting = () => {
  const [loading, setLoading] = useState(false);
  const [dataState, setDataState] = useState<SiteSettingType>({
    gtTrackingId: "",
    logoRedirectLink: "",
    logoSecondaryMobileImage: { id: 0, thumbnail: "", url: "" },
    logoImage: { id: 0, thumbnail: "", url: "" },
    logoMobileImage: { id: 0, thumbnail: "", url: "" },
    logoSecondaryImage: { id: 0, thumbnail: "", url: "" },
    id: "",
    favicon: { id: 0, thumbnail: "", url: "" },
  });
  const [mainDataState, setMainDataState] = useState<SiteSettingType>({
    gtTrackingId: "",
    logoRedirectLink: "",
    logoImage: { id: 0, thumbnail: "", url: "" },
    logoSecondaryMobileImage: { id: 0, thumbnail: "", url: "" },
    logoMobileImage: { id: 0, thumbnail: "", url: "" },
    logoSecondaryImage: { id: 0, thumbnail: "", url: "" },
    id: "",
    favicon: { id: 0, thumbnail: "", url: "" },
  });

  useEffect(() => {
    getSiteTheme()
      .then((response) => {
        if (response.status === 200) {
          console.log(response.data);
          setDataState(response.data);
          setMainDataState(response.data);
        }
      })
      .catch();
  }, []);

  return (
    <PageContainer>
      <PageTitle selectedMenu={Title.siteSetting} className="py-[48px]" />
      <Line />

      <div className="1140:flex mt-7 mx-[40px] ">
        <div className="w-full 1140:w-1/2 1140:mr-2 mr-8">
          <DataBox
            title="Logo Image"
            logoRedirectLink={dataState.logoRedirectLink ?? ""}
            image={dataState.logoImage ? dataState.logoImage : null}
            onFileUpload={(_value) => setDataState({ ...dataState, logoImage: _value })}
            onTrashClick={() => setDataState({ ...dataState, logoImage: null })}
            redirectLinkOnChange={(value) => setDataState({ ...dataState, logoRedirectLink: value })}
            hasRedirectLink
          />
          <DataBox
            title="Logo Secondary Image"
            image={dataState.logoSecondaryImage ? dataState.logoSecondaryImage : null}
            onFileUpload={(_value) => setDataState({ ...dataState, logoSecondaryImage: _value })}
            onTrashClick={() => setDataState({ ...dataState, logoSecondaryImage: null })}
            hasRedirectLink={false}
          />
          <DataBox
            title="Logo Mobile Image"
            image={dataState.logoMobileImage ? dataState.logoMobileImage : null}
            onFileUpload={(_value) => setDataState({ ...dataState, logoMobileImage: _value })}
            onTrashClick={() => setDataState({ ...dataState, logoMobileImage: null })}
            hasRedirectLink={false}
          />
          <DataBox
            title="Logo Secondary Mobile Image"
            image={dataState.logoSecondaryMobileImage ? dataState.logoSecondaryMobileImage : null}
            onFileUpload={(_value) => setDataState({ ...dataState, logoSecondaryMobileImage: _value })}
            onTrashClick={() => setDataState({ ...dataState, logoSecondaryMobileImage: null })}
            hasRedirectLink={false}
          />
          <DataBox
            title="Favicon"
            image={dataState.favicon ? dataState.favicon : null}
            onFileUpload={(_value) => setDataState({ ...dataState, favicon: _value })}
            onTrashClick={() => setDataState({ ...dataState, favicon: null })}
            hasRedirectLink={false}
          />
        </div>
        <AnalysisBox dataState={dataState} setDataState={setDataState} />
      </div>

      <div className="flex flex-row-reverse mt-16 pr-8">
        <Button
          showLoading={loading}
          onClick={() => {
            setLoading(true);
            editSiteTheme({
              logo_redirect_link: dataState.logoRedirectLink,
              gt_tracking_id: dataState.gtTrackingId,
              logo_image: dataState.logoImage?.id ? dataState.logoImage?.id : null,
              logoMobileImage: dataState.logoMobileImage?.id ? dataState.logoMobileImage?.id : null,
              logoSecondaryImage: dataState.logoSecondaryImage?.id ? dataState.logoSecondaryImage?.id : null,
              favicon: dataState.favicon?.id ? dataState.favicon?.id : null,
              logoSecondaryMobileImage: dataState.logoSecondaryMobileImage?.id ? dataState.logoSecondaryMobileImage?.id : null,
            }).then((response) => {
              if (response.status === 200) {
                setLoading(false);
              }
            });
          }}
          type={ButtonType.PRIMARY}
        >
          Save
        </Button>
        <Button
          className="mr-4 "
          onClick={() => {
            setDataState(mainDataState);
          }}
          type={ButtonType.SECONDARY}
        >
          Discard
        </Button>
      </div>
    </PageContainer>
  );
};

export default SiteSetting;
