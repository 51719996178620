import { useState } from "react";

import { BlockDetailsFieldType, OptionType } from "./utils/types";
import RichTextInput from "./components/inputs/RichTextInput";
import ColorPicker, { isValidHexColor } from "./components/inputs/ColorPicker";
import Dropdown from "./components/inputs/Dropdown";
import InputField from "./components/inputs/InputField";
import FileInput from "./components/inputs/FileInput";
import CmsTable from "./components/cms_table/CmsTable";
import MultiValues from "./components/inputs/MultiValues";
import MultiCheckbox from "./components/inputs/MultiCheckbox";
import MultiSelectCheckBox from "./components/MultiSelectCheckBox";
import AddOrRenameDictionaryModal from "./components/siteSetting/data_dictionary/AddOrRenameDictionaryModal";
import AreYouSureModal from "./components/manage_content/AreYouSureModal";
import SuccessModal from "./components/siteSetting/data_dictionary/SuccessModal";
import AddOrEditValueModal from "./components/siteSetting/data_dictionary/AddOrEditValueModal";
import DataDictionaryHub from "./components/siteSetting/data_dictionary/DataDictionaryHub";

export const OPTIONS = [
  { key: "blue", label: "Blue" },
  { key: "yellow", label: "Yellow" },
  { key: "red", label: "Red" },
  { key: "white", label: "White" },
];

const Test = () => {
  const [data, setData] = useState("");
  const [color, setColor] = useState("#000");

  const [pages, setPages] = useState<OptionType | null>(null);

  const [number, setNumber] = useState("");
  const [text, setText] = useState("");

  const [hasImage, setHasImage] = useState(false);

  const [values, setValues] = useState(["blue", "yellow", "red", "white"]);

  // const [multiCheckboxField, setMultiCheckboxField] = useState(MULTI_CHECKBOX_FIELD);

  return (
    <>
      {/* <BlockImageInput hasImage={hasImage} setHasImage={setHasImage} /> */}
      {/* <RichTextInput setData={setData} />
      <div dangerouslySetInnerHTML={{ __html: data }} />
      <ColorPicker color={color} setColor={setColor} /> */}
      {/* <ColorPicker color={color} setColor={setColor} label="Color" hasError={!isValidHexColor(color)} /> */}
      <div className="">
        {/* <Dropdown
          options={OPTIONS}
          onOptionSelect={(option) => setPages(option)}
          label="Person"
          selectedOption={pages}
          placeholder="Choose a Person!"
          className="w-[283px] mr-4"
          required
          // horizontal
          isSearchable
        /> */}
        {/* <ColorPicker label="Border Color" color="#000" setColor={() => {}} /> */}
        {/* <RichTextInput setData={() => {}} initData="" />  */}
        {/* <InputField type="N" value={number} onChange={(value) => setNumber(value)} label="Percentage" className="m-4" />
        <InputField
          type="T"
          value={text}
          onChange={(value) => setText(value)}
          label="Placeholder"
          placeholder="Describe what you want."
          className="m-4"
        /> */}
        {/* <CmsTable
          value={[
            {
              id: "1",
              col1: "<p>Hello <b>GH</b> !</p><p>Paragraph 1</p>",
              col2: "<p>Hello <b>World</b> !</p><p>Paragraph 2</p>",
            },
            {
              id: "2",
              col1: "<p>Hello <b>World</b> !</p><p>Paragraph 3</p>",
              col2: "Parsa",
            },
          ]}
          onValueChanged={(data) => {
            console.log("new Data: " + JSON.stringify(data));
          }}
        /> */}
      </div>
    </>
  );
};

export default Test;
