import React, { useMemo, useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight, faAngleDown } from "@fortawesome/pro-regular-svg-icons";

const getAllGroupedPages = (pageCount: number) => {
  const groupedPagesLength = pageCount % 5 === 0 ? pageCount / 5 : Math.floor(pageCount / 5) + 1;

  const groupedPages = [];
  for (let i = 0; i < groupedPagesLength; i++) {
    const groupedPage = [];
    for (let j = 1; j <= 5; j++) {
      if (i * 5 + j <= pageCount) {
        groupedPage.push(i * 5 + j);
      }
    }
    groupedPages.push(groupedPage);
  }

  return groupedPages;
};

const Pagination: React.FC<{
  previousPage: () => void;
  nextPage: () => void;
  canPreviousPage: boolean;
  canNextPage: boolean;
  setPageSize: (pageSize: number) => void;
  gotoPage: (updater: number | ((pageIndex: number) => number)) => void;
  pageCount: number;
  pageIndex: number;
  pageSize: number;
  pageLength: number;
  totalLength: number;
  dropdownKeys?: string[];
  details?: {
    firstIndex: number;
    lastIndex: number;
    totalNum: number;
  };
}> = (props) => {
  const {
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    setPageSize,
    gotoPage,
    pageCount,
    pageIndex,
    pageSize,
    pageLength,
    totalLength,
  } = props;

  const allGroupedPages = useMemo(() => getAllGroupedPages(pageCount), [pageCount]);
  const [dropDownIsClicked, setDropDownIsClicked] = useState(false);
  const [selectedOption, setSelectedOption] = useState("5");
  useEffect(() => {
    setPageSize(+selectedOption);
    gotoPage(0);
  }, [selectedOption]);
  if (allGroupedPages.length === 0) {
    return null;
  }

  const activeGroupedPagesIndex = allGroupedPages.findIndex(
    (groupedPages) => groupedPages.indexOf(pageIndex + 1) !== -1
  );

  return (
    <div className="flex justify-between items-center my-6 px-2 h-[40px]">
      <div className="h-7">
        <div className=" inline-block">
          <button
            className="  mx-1"
            onClick={() => {
              previousPage();
            }}
            disabled={!canPreviousPage}
          >
            <div className="grid place-items-center text-primary w-6 h-6 border border-primary rounded-full hover:cursor-pointer">
              <FontAwesomeIcon className="text-xs " icon={faChevronLeft} />
            </div>
          </button>
        </div>
        {activeGroupedPagesIndex !== 0 && (
          <button
            className="pagination-button"
            onClick={() => gotoPage(allGroupedPages[activeGroupedPagesIndex - 1][0] - 1)}
          >
            ...
          </button>
        )}
        {allGroupedPages[allGroupedPages[activeGroupedPagesIndex] ? activeGroupedPagesIndex : 0].map((page) => (
          <button
            className={`pagination-button  mx-1 text-sm ${
              page === pageIndex + 1 ? "bg-primary text-white rounded-full w-6 h-6" : "text-primary"
            }`}
            key={page}
            onClick={() => gotoPage(page - 1)}
          >
            <div className="grid place-items-center">{page}</div>
          </button>
        ))}
        {activeGroupedPagesIndex !== allGroupedPages.length - 1 && (
          <button
            className="pagination-button mx-1 text-primary"
            onClick={() => gotoPage(allGroupedPages[activeGroupedPagesIndex + 1][0] - 1)}
          >
            ...
          </button>
        )}
        <div className="inline-block">
          <button className="pagination-button bg-gray-e8 mx-1" onClick={() => nextPage()} disabled={!canNextPage}>
            <div className="grid place-items-center text-primary w-6 h-6 border border-primary rounded-full hover:cursor-pointer">
              <FontAwesomeIcon className="text-xs" icon={faChevronRight} />
            </div>
          </button>
        </div>
      </div>
      <div className="flex items-center justify-end ">
        <div
          tabIndex={0}
          onBlur={() => setDropDownIsClicked(false)}
          onClick={() => setDropDownIsClicked(!dropDownIsClicked)}
          className="relative w-[62px] h-[31px] px-2 rounded text-primary border border-primary font-normal text-sm flex justify-between items-center hover:cursor-pointer"
        >
          <div>{selectedOption}</div>
          <div>
            <FontAwesomeIcon
              className={`text-xs duration-[500ms] rotate-0 ${dropDownIsClicked && "rotate-180 "}`}
              icon={faAngleDown}
            />
          </div>
          {dropDownIsClicked && (
            <div className="absolute top-[32px] rounded border border-primary left-0 w-[62px]">
              <ul>
                {["5", "10", "20"].map((option, i) => (
                  <li
                    className={`py-1 text-center  ${i !== 2 && "border-b"} hover:bg-secondary-400`}
                    onClick={() => {
                      setSelectedOption(option);
                    }}
                    key={i}
                  >
                    {option}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>

        <p className="text-sm font-normal ml-5 text-primary hidden md:block">
          Showing
          <span className="ml-1">{pageIndex * pageSize + 1}-</span>
          <span>
            {pageIndex * pageSize + pageLength} of {totalLength}
          </span>
        </p>
      </div>
    </div>
  );
};

export default Pagination;
