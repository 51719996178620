import React, { useState } from "react";
import InputField from "../../inputs/InputField";
import { Padding } from "../../../types/types";

export const PaddingContent: React.FC<{
  padding: Padding;
  onChange: (padding: Padding) => void;
}> = ({ padding, onChange }) => {
  const [paddingState, setPaddingState] = useState(padding);

  return (
    <div className="flex justify-between flex-wrap">
      <div className="w-[238px]">
        <InputField
          required
          min={0}
          type="N"
          value={paddingState?.top}
          onChange={(value) => {
            setPaddingState({ ...paddingState, top: value! });
            onChange({ ...paddingState, top: value! });
          }}
          label="Top"
          className="w-[234px]"
        />
      </div>
      <div className="w-[238px]">
        <InputField
          required
          min={0}
          type="N"
          value={paddingState?.right}
          onChange={(value) => {
            setPaddingState({ ...paddingState, right: value! });
            onChange({ ...paddingState, right: value! });
          }}
          label="Right"
          className="w-[234px]"
        />
      </div>
      <div className="w-[238px]">
        <InputField
          required
          min={0}
          type="N"
          value={paddingState?.bottom}
          onChange={(value) => {
            setPaddingState({ ...paddingState, bottom: value! });
            onChange({ ...paddingState, bottom: value! });
          }}
          label="Bottom"
          className="w-[234px]"
        />
      </div>
      <div className="w-[238px]">
        <InputField
          required
          min={0}
          type="N"
          value={paddingState?.left}
          onChange={(value) => {
            setPaddingState({ ...paddingState, left: value! });
            onChange({ ...paddingState, left: value! });
          }}
          label="Left"
          className="w-[234px]"
        />
      </div>
    </div>
  );
};
