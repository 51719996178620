import React from "react";
export enum Title {
  blockDefaults = "Manage Block Defaults",
  buttonDefaults = "Manage Button Defaults",
  formDefaults = "Manage Form Defaults",
  tableDefaults = "Manage Table Defaults",
  elementDefaults = "Manage Element Defaults",
  tooltip = "Manage Tooltip",
  siteDefault = "Manage Site Default",
  siteSetting = "Site Setting",
  language = "Language",
}
const PageTitle: React.FC<{ selectedMenu: string | undefined; className?: string; children?: React.ReactNode }> = ({
  selectedMenu,
  className,
  children,
}) => {
  return (
    <div className={` bg-white z-30 w-full  px-10 max-w-[1550px] mx-auto ${className} flex justify-between flex-wrap py-[45px]`}>
      <h3>
        {(() => {
          switch (selectedMenu) {
            case Title.blockDefaults:
              return "Manage Block Defaults";
            case Title.buttonDefaults:
              return "Manage Button Defaults";
            case Title.formDefaults:
              return "Manage Form Defaults";
            case Title.tableDefaults:
              return "Manage Table Defaults";
            case Title.elementDefaults:
              return "Manage Element Defaults";
            case Title.tooltip:
              return "Manage Tooltip";
            case Title.siteDefault:
              return "Manage Site Default";
            case Title.siteSetting:
              return "Site Setting";
            case Title.language:
              return "Language";
            default:
              return "Site Theme";
          }
        })()}
      </h3>
      <div>{children}</div>
    </div>
  );
};

export default PageTitle;
