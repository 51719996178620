import React from "react";

const TypeBox: React.FC<{ type: string; className?: string }> = ({ type, className }) => {
  let typeBox: JSX.Element;
  switch (type.toLocaleLowerCase()) {
    case "png":
      typeBox = <span className="bg-orange px-4 text-center py-2 rounded text-white text-xs">{type}</span>;
      break;
    case "pdf":
      typeBox = <span className="bg-[#E55D63] px-4 text-center py-2 rounded text-white text-xs">{type}</span>;
      break;
    case "jpg":
      typeBox = <span className="bg-[#94C272] px-4 text-center py-2 rounded text-white text-xs">{type}</span>;
      break;
    case "docx":
      typeBox = <span className="bg-[#C6C0B5] px-4 text-center py-2 rounded text-white text-xs">{type}</span>;
      break;
    case "ico":
      typeBox = <span className="bg-purple-400 px-4 text-center py-2 rounded text-white text-xs">{type}</span>;
      break;
    case "jpeg":
      typeBox = <span className="bg-red-400 px-4 text-center py-2 rounded text-white text-xs">{type}</span>;
      break;
    case "mp4":
      typeBox = <span className="bg-yellow-300 px-4 text-center py-2 rounded text-white text-xs">{type}</span>;
      break;
      case "mp3":
      typeBox = <span className="bg-green px-4 text-center py-2 rounded text-white text-xs">{type}</span>;
      break;
    case "svg":
      typeBox = <span className="bg-pink-300 px-4 text-center py-2 rounded text-white text-xs">{type}</span>;
      break;
    default:
      typeBox = <p>{type}</p>;
  }
  return <div className={`text-sm font-normal uppercase ${className}`}>{typeBox}</div>;
};

export default TypeBox;
