import API from "./_config";

export function getSiteTheme() {
  return API.get("site-theme/")
    .then((response) => {
      return response;
    })
    .catch((e) => {
      throw e;
    });
}

export function editSiteTheme(style: any) {
  return API.patch("site-theme/", style)
    .then((response) => {
      return response;
    })
    .catch((e) => {
      throw e;
    });
}
