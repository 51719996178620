import React from "react";
import Button, { ButtonType } from "../Button";

const SiteThemeContainer: React.FC<{
  children: React.ReactNode;
  onSave: () => void;
  onCancel: () => void;
  loading: boolean;
  hasLeftBorder: boolean;
}> = ({ children, onSave, onCancel, loading, hasLeftBorder }) => {
  return (
    <div>
      <div className="flex">
        <div className={`w-full ${hasLeftBorder && "border-4 border-t-0 border-b-0 border-r-0"} pl-6 ml-2`}>{children}</div>
      </div>
      <div className="flex flex-row-reverse py-6">
        <Button showLoading={loading} onClick={onSave} type={ButtonType.PRIMARY}>
          Save
        </Button>

        <Button className="mr-4 " onClick={onCancel} type={ButtonType.SECONDARY}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default SiteThemeContainer;
