import { useLocation, Navigate, Outlet } from "react-router-dom";

const RequiredAuth = () => {
  let location = useLocation();
  if (!localStorage.getItem("token") && window.location.pathname !== "/login") {
    return <Navigate to="/login" state={{ from: location }} replace />;
  } else return <Outlet />;
};

export default RequiredAuth;
