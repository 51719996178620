import React, { useEffect, useRef, useState } from "react";
import TypeBox from "../table/row/TypeBox";
import moment from "moment";
const ShowingData: React.FC<{ type: string; size: string; uploadDate: string; numberOfUsage: number }> = ({
  type,
  size,
  uploadDate,
  numberOfUsage,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [numDots, setNumDots] = useState(0);
  const [uploadDateDots, setUploadDateDots] = useState(0);
  const [numberOfUsageDots, setNumberOfUsageDots] = useState(0);

  const updateDots = () => {
    if (containerRef.current) {
      const container = containerRef.current;
      const tags = container.querySelectorAll(".whitespace-nowrap") as NodeListOf<HTMLSpanElement>;

      const tag1Width = tags[0].offsetWidth;
      const tag3Width = tags[2].offsetWidth;
      const containerWidth = container.offsetWidth;
      const availableWidth = containerWidth - tag1Width - tag3Width;

      const dotWidth = 5; // Approximate width of a single dot
      const dots = Math.floor(availableWidth / dotWidth);
      if (containerWidth < 448) {
        setNumDots(dots / 1.5);
        setUploadDateDots(dots / 2);
        setNumberOfUsageDots(dots / 1.5);
      } else {
        setNumDots(dots);
        setUploadDateDots(dots);
        setNumberOfUsageDots(dots);
      }
    }
  };

  useEffect(() => {
    const handleResize = () => {
      updateDots();
    };

    const resizeObserver = new ResizeObserver(handleResize);

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    // Initial call
    updateDots();

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
      resizeObserver.disconnect();
    };
  }, []);
  return (
    <div className="mt-6 pl-6 1304:pr-0 pr-6 text-sm text-[#848484]" ref={containerRef}>
      <div className="mt-6 flex justify-between">
        <span className="whitespace-nowrap">Type</span>
        <span className="dots flex-grow overflow-hidden whitespace-nowrap text-center text-[#BDBDBD]">{".".repeat(numDots)}</span>
        <span className="whitespace-nowrap">
          <TypeBox type={type} />
        </span>
      </div>
      <div className="mt-6 flex justify-between">
        <span>Size</span>
        <span className="dots flex-grow overflow-hidden text-center text-[#BDBDBD]">{".".repeat(numDots)}</span>
        <span>{size}</span>
      </div>
      <div className="mt-6 flex justify-between">
        <span className="whitespace-nowrap">Upload Date</span>
        <span className="dots flex-grow overflow-hidden whitespace-nowrap text-center text-[#BDBDBD]">{".".repeat(uploadDateDots)}</span>
        <span>{moment(uploadDate).format("MM.DD.YYYY")}</span>
      </div>
      <div className="mt-6 flex justify-between">
        <span className="whitespace-nowrap">No. of Usage</span>
        <span className="dots flex-grow overflow-hidden whitespace-nowrap text-center text-[#BDBDBD]">{".".repeat(numberOfUsageDots)}</span>
        <span>{numberOfUsage}</span>
      </div>
    </div>
  );
};

export default ShowingData;
