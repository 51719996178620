import React from "react";

const BlockBox: React.FC<{ children: React.ReactNode; title: string; className?: string }> = ({
  children,
  title,
  className,
}) => {
  return (
    <div className={`shadow pb-12 ${className}`}>
      <div className="w-full bg-secondary-100 h-[55px] rounded-t text-black2B flex items-center text-base font-medium pl-4">
        {title}
      </div>
      <div className="px-4 pt-4">{children}</div>
    </div>
  );
};

export default BlockBox;
