import { useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolderOpen, faTrash } from "@fortawesome/pro-light-svg-icons";

import API from "../../api/_config";
import { ImageValueType } from "../../utils/types";

const FileInput: React.FC<{
  label: string;
  value: ImageValueType | null;
  onFileUpload: (imageValue: ImageValueType) => void;
  onTrashClick: () => void;
  rootClassName?: string;
  inputContainerCustomStyle?: React.CSSProperties;
  type?: string;
}> = ({ label, value, onFileUpload, onTrashClick, rootClassName, inputContainerCustomStyle, type }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [uploadedPercent, setUploadedPercent] = useState<number>();

  const uploadImage = async (formData: FormData) => {
    return API.post("file-uploader/", formData, {
      onUploadProgress: (progressEvent) => {
        if (!progressEvent.total) return;
        let _uploadedPercent = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
        if (_uploadedPercent === 100) _uploadedPercent--;
        setUploadedPercent(_uploadedPercent);
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };

  const onFileDrop = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (uploadedPercent !== undefined) return;

    if (e.target.files && e.target.files[0]) {
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      uploadImage(formData).then((response) => {
        onFileUpload({ id: response.data.id, url: response.data.url, thumbnail: response.data.thumbnail });
        setUploadedPercent(undefined);
      });
    }
  };

  const onInputClick = () => {
    inputRef.current!.value = "";
  };

  return (
    <div className={rootClassName}>
      <label className="text-sm font-light text-black2B">{label}</label>
      <div
        className="rounded-[5px] border-[0.5px] border-dashed border-secondary-200 relative overflow-hidden grid place-items-center my-2 px-5 py-10 bg-white"
        style={inputContainerCustomStyle}
      >
        <div className="flex items-center text-secondary-200">
          <FontAwesomeIcon icon={faFolderOpen} className="text-primary text-3xl" />
          <div className="flex flex-col">
            <div className="flex">
              <p className="text-sm ml-2 text-black2B">Drag & drop your file here or</p>
              <p className="text-sm ml-1 text-primary">choose here</p>
            </div>
            <p className="text-[12px] ml-2 text-[#AAAAAA]">25 MB max size file</p>
          </div>
        </div>
        <input
          ref={inputRef}
          type="file"
          className="absolute w-full h-full left-0 top-0 opacity-0 cursor-pointer"
          onChange={onFileDrop}
          onClick={onInputClick}
          accept={type ? type : ".jpg,.jpeg,.png,.svg,.mp4,.webm,.ogg,.avc"}
        />
        {value && (
          <div className="w-full h-full left-0 top-0 absolute bg-white">
            <div className="relative grid place-items-center h-full">
              <a target="_blank" href={value.url} className="h-full">
                <img src={value.url} className="h-full w-[95%] max-w-[200px] object-cover" alt="The uploaded file..." />
              </a>
              <div
                className="w-6 h-6 absolute top-2 left-2 rounded-[5px] grid place-items-center text-white text-xs bg-primary cursor-pointer"
                onClick={onTrashClick}
              >
                <FontAwesomeIcon icon={faTrash} />
              </div>
            </div>
          </div>
        )}
      </div>
      {uploadedPercent !== undefined && <ProgressBar percent={uploadedPercent} />}
    </div>
  );
};

const ProgressBar: React.FC<{ percent: number }> = ({ percent }) => {
  return (
    <div className="flex items-center gap-4">
      <div className="h-[6px] bg-secondary-200 rounded flex-1">
        <div style={{ width: `${percent}%` }} className="bg-primary h-full rounded duration-300"></div>
      </div>
      <span className="text-sm text-primary font-light">{percent}%</span>
    </div>
  );
};

export default FileInput;
