import React, { useState } from "react";
import SiteThemeContainer from "./SiteThemeContainer";
import BorderBlock from "./blocks/BorderBlock";
import { TableStyle, SiteThemeType } from "../../types/types";
import ColorBLock from "./blocks/ColorBLock";
import BorderAndBackgroundBlock, { BorderAndBackgroundBlockEnum } from "./blocks/BorderAndBackgroundBlock";

const ManageTableDefaults: React.FC<{
  onCancel: () => void;
  loading: boolean;
  siteThemeStyle: SiteThemeType;
  onSave: (formStyle: TableStyle) => void;
}> = ({ onCancel, loading, siteThemeStyle, onSave }) => {
  const [tableStyleState, setTableStyleState] = useState<TableStyle>(siteThemeStyle.tableStyle!);

  return (
    <SiteThemeContainer
      hasLeftBorder
      loading={loading}
      onCancel={onCancel}
      onSave={() => {
        onSave(tableStyleState);
      }}
    >
      <BorderBlock
        title="Border"
        border={tableStyleState.border}
        onChange={(value) => {
          setTableStyleState({ ...tableStyleState, border: value });
        }}
        className="mt-4"
        borderStyleOptions={siteThemeStyle.borderStyleChoices}
        borderWidthOptions={siteThemeStyle.borderWidthChoices}
        borderSideOptions={siteThemeStyle.borderSideChoices}
      />
      <ColorBLock
        className="mt-4"
        colorPatternChoices={siteThemeStyle.colorPatternChoices}
        colorPattren={siteThemeStyle.tableStyle.colorPattern}
        colorStyle={{
          colorPattern: siteThemeStyle.tableStyle.colorPattern,
          primaryBackgroundColor: siteThemeStyle.tableStyle.primaryBackgroundColor,
          secondaryBackgroundColor: siteThemeStyle.tableStyle.secondaryBackgroundColor,
        }}
        onChange={(color) => {
          setTableStyleState({
            ...tableStyleState,
            primaryBackgroundColor: color.primaryBackgroundColor,
            secondaryBackgroundColor: color.secondaryBackgroundColor,
            colorPattern: color.colorPattern,
          });
        }}
      />
      <BorderAndBackgroundBlock
        headerOrStickyColumn={BorderAndBackgroundBlockEnum.Header}
        setAddedColor={(color) => {
          setTableStyleState({ ...tableStyleState, headerBackgroundColor: color });
        }}
        title="Header"
        border={tableStyleState.headerBorder}
        onChange={(value) => {
          setTableStyleState({ ...tableStyleState, headerBorder: value });
        }}
        className="mt-4"
        borderStyleOptions={siteThemeStyle.borderStyleChoices}
        borderWidthOptions={siteThemeStyle.borderWidthChoices}
        borderSideOptions={siteThemeStyle.borderSideChoices}
        addedColor={tableStyleState.headerBackgroundColor}
      />

      <BorderAndBackgroundBlock
        setAddedColor={(color) => setTableStyleState({ ...tableStyleState, stickyBackgroundColor: color })}
        headerOrStickyColumn={BorderAndBackgroundBlockEnum.StickyColumn}
        title="Sticky Column"
        border={tableStyleState.stickyBorder}
        addedColor={tableStyleState.stickyBackgroundColor}
        onChange={(value) => {
          setTableStyleState({ ...tableStyleState, stickyBorder: value });
        }}
        className="mt-4"
        borderStyleOptions={siteThemeStyle.borderStyleChoices}
        borderWidthOptions={siteThemeStyle.borderWidthChoices}
        borderSideOptions={siteThemeStyle.borderSideChoices}
      />
    </SiteThemeContainer>
  );
};

export default ManageTableDefaults;
