import { useEffect, useState } from "react";
import PageContainer from "../../layout/PageContainer";
import PageTitle, { Title } from "../../PageTitle";
import SearchInput from "../../inputs/SearchInput";
import Table from "./table/Table";
import { getLanguage, editLanguage } from "../../../api/languages";
import { LanguageList } from "../../../types/types";
import Line from "../Line";

const Language = () => {
  const [languages, setLanguages] = useState<LanguageList[]>([{ active: false, code: "", default: false, language: "" }]);
  const [shownLanguages, setShownLanguages] = useState<LanguageList[]>([{ active: false, code: "", default: false, language: "" }]);

  const columns = [
    { Header: "Language", accessor: "languages" },
    { Header: "Code", accessor: "code" },
    { Header: "Active", accessor: "active" },
  ];

  useEffect(() => {
    getLanguage().then((payload) => {
      setLanguages(payload.data.languages);
      setShownLanguages(payload.data.languages);
    });
  }, []);

  const activeInactiveHandler = (code: string, status: boolean) => {
    editLanguage(code, status).then((payload) => {
      if (payload.status === 200) {
        setShownLanguages(payload.data.languages);
        setLanguages(payload.data.languages);
      }
    });
  };

  const searchHandler = (input: string) => {
    const newArray = [...languages];
    const filterArray = newArray.filter((item) => item.language.toLowerCase().includes(input.toLowerCase()));
    setShownLanguages(filterArray);
  };

  return (
    <PageContainer>
      <PageTitle selectedMenu={Title.language}>
        <SearchInput searchHandler={searchHandler} className="md:w-[400px]"/>
      </PageTitle>
      <Line />
      <div className="mt-6 mx-12">
        <Table columns={columns} data={shownLanguages} activeInactiveHandler={activeInactiveHandler} />
      </div>
    </PageContainer>
  );
};

export default Language;
