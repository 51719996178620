import React, { useEffect, useState } from "react";
import Header from "./header/Header";

const PageContainer: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [showChildren, setShowChildren] = useState<boolean>(true);
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="relative w-full h-full">
      <header className="fixed top-0 w-full h-[100px] z-50">
        <Header showContentChildren={() => setShowChildren(!showChildren)} />
      </header>

      <main className="mt-[100px]  max-w-[1550px] mx-auto">{showChildren && children}</main>

      <footer className="h-[200px]" />
    </div>
  );
};

export default PageContainer;
