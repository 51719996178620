import React, { useState } from "react";
import BlockBox from "./BlockBox";
import ColorPicker from "../../inputs/ColorPicker";
import { Padding, FormStyleType } from "../../../types/types";
import { PaddingContent } from "./PaddingContent";

export type FormFieldsStyleType = {
  placeholderColor: string;
  backgroundColor: string;
  textColor: string;
  padding: Padding;
};

const StyleOfFormFields: React.FC<{
  className?: string;
  formStyle: FormStyleType;
  onChange: (style: FormFieldsStyleType) => void;
}> = ({ className, formStyle, onChange }) => {
  const [textColor, setTextColor] = useState(formStyle.textColor);
  const [backgroundColor, setBackgroundColor] = useState(formStyle.backgroundColor);
  const [placeholderColor, setPlaceholderColor] = useState(formStyle.placeholderColor);

  return (
    <BlockBox className={className} title="Style of Form's Fields">
      <div className="flex justify-between flex-wrap">
        <div className="w-[238px]">
          <ColorPicker
            color={textColor}
            label="Text Color"
            setColor={(color) => {
              setTextColor(color.toString());
              onChange({
                ...formStyle,
                textColor: color.toString(),
              });
            }}
          />
        </div>
        <div className="w-[238px]">
          <ColorPicker
            color={backgroundColor}
            label="Background Color"
            setColor={(color) => {
              setBackgroundColor(color);
              onChange({ ...formStyle, backgroundColor: color.toString() });
            }}
          />
        </div>
        <div className="w-[238px]">
          <ColorPicker
            color={placeholderColor}
            label="Placeholder Color"
            setColor={(color) => {
              setPlaceholderColor(color.toString());
              onChange({ ...formStyle, placeholderColor: color.toString() });
            }}
          />
        </div>
      </div>
      <div className="font-medium text-base my-6">Padding</div>
      <PaddingContent padding={formStyle.padding} onChange={(padiing) => onChange({ ...formStyle, padding: padiing })} />
    </BlockBox>
  );
};

export default StyleOfFormFields;
