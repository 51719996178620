import { TreeModel } from "../types/types";
import { OptionType, SlugValuePairType } from "../utils/types";
import API from "./_config";

export enum HeaderStatus {
  Publish,
  Unpublish,
  Discard,
}

export const editMenuItemStyle = async (body: SlugValuePairType[]) => {
  return API.patch("menu-item-style/", body)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const getHeader = async () => {
  return API.get("header/")
    .then((response) => {
      return JSON.parse(JSON.stringify(response.data));
    })
    .catch((error) => {
      throw error;
    });
};

export const editHeader = async (body: SlugValuePairType[]) => {
  return API.put("header/", body)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export function getMenuItems() {
  return API.get("menu-items/")
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function getBlocksDropdown(id: number) {
  return API.get(`page-blocks-dropdown/${id}/`)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function getMenuItem(id: number) {
  return API.get(`menu-items/${id}/`)
    .then((response) => {
      return response.data as TreeModel;
    })
    .catch((e) => {
      throw e;
    });
}

export function getMenuItemParentAndPages() {
  return API.get(`/dropdowns/?type=Menu`)
    .then((response) => {
      return {
        parentList: response.data.parentList as OptionType[],
        pages: response.data.pages as OptionType[],
      };
    })
    .catch((e) => {
      throw e;
    });
}

export function addMenuItem(newMenuItem: TreeModel) {
  return API.post(`/menu-items/`, newMenuItem)
    .then((response) => {
      return {
        status: response,
      };
    })
    .catch((e) => {
      throw e;
    });
}

export function updateMenuItem(menuObj: TreeModel) {
  return API.put(`/menu-items/${menuObj.id}/`, menuObj)
    .then((response) => {
      return {
        status: response,
      };
    })
    .catch((e) => {
      throw e;
    });
}

export function reorderMenuItems(menuId: number, newOrder: number, oldOrder: number) {
  const body = {
    new_order: newOrder,
    old_order: oldOrder,
  };
  return API.post(`/menu-items/${menuId}/reorder/`, body)
    .then((response) => {
      return {
        status: response.status,
      };
    })
    .catch((e) => {
      throw e;
    });
}

export function deleteMenuItem(menuId: number) {
  return API.delete(`/menu-items/${menuId}/`)
    .then((response) => {
      return {
        status: response.status,
      };
    })
    .catch((e) => {
      throw e;
    });
}

export function determineHeaderStatus(headerStatus: HeaderStatus) {
  let apiRoute = `/header/`;

  switch (headerStatus) {
    case HeaderStatus.Publish:
      apiRoute += "publish/";
      break;
    case HeaderStatus.Unpublish:
      apiRoute += "unpublish/";
      break;
    case HeaderStatus.Discard:
      apiRoute += "discard/";
      break;
  }

  return API.post(apiRoute)
    .then((response) => {
      return {
        status: response.status,
      };
    })
    .catch((e) => {
      throw e;
    });
}
