import { useEffect, useState, useContext } from "react";
import { useLoaderData, LoaderFunctionArgs, useParams, useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPalette, faPlus } from "@fortawesome/pro-regular-svg-icons";

import { getBlockDetails, addBlock, editBlock, blockPreview, dropdownAPIChange } from "../../../api/block";
import { editHeader, getHeader } from "../../../api/menuItem";
import { getCookie, editCookie, editCookieStatus } from "../../../api/content";
import {
  BlockDetailsFieldType,
  BlockDetailsType,
  ImageValueType,
  LangValuePairType,
  OptionType,
  SlugValuePairType,
} from "../../../utils/types";
import { RowData } from "../../cms_table/CmsTable";
import {
  getTargetField,
  getOrderableItem,
  getTargetFieldParent,
  blockAddedItemSetSlug,
  blockDetailGetSlugValuePairs,
  isValidEmail,
  // isValidURL,
  getStatusTypeStr,
  setRequiredFields,
} from "../../../utils/utils";
import { StatusBoxType } from "../../../types/types";
import { ManageBlockDetailContext } from "../../../store/ManageBlockErrorContext";
import PageContainer from "../../layout/PageContainer";
import StyleSidebarLayout from "../../layout/StyleSidebarLayout";
import StylesSidebar from "../../layout/StylesSidebar";
import BlockDetailsMetaData from "../../manage_block_detail/BlockDetailsMetaData";
import BlockPreview from "../../manage_block_detail/BlockPreview";
import BlockDetailsSectionContainer from "../../layout/BlockDetailsSectionContainer";
import BlockDetailsSection from "../../manage_block_detail/BlockDetailsSection";
import BlockDetailsList from "../../manage_block_detail/BlockDetailsList";
import Button, { ButtonType } from "../../Button";
import Loading, { LoadingMode } from "../../Loading";
import CmsModalMainShape from "../../CmsModalMainShape";
import Dropdown from "../../inputs/Dropdown";
import NotFound from "../404";

let uniqueCounter = 0;

const getBlockStyleFields = (blockDetails: BlockDetailsType) => [
  ...blockDetails.fields.find(({ name }) => name === "style")!.fields!,
  ...blockDetails.fields.filter(({ isStyle, type }) => isStyle && type !== "tabStyle"),
];

const getBlockDetailsItemOrder = (blockDetails: BlockDetailsType) => {
  const itemOrder: number[] = [];
  blockDetails.fields
    .find(({ type }) => type === "itemList")!
    .fields!.forEach(({ slug }) => itemOrder.push(+slug.substring(slug.lastIndexOf(".") + 1, slug.length)));
  return itemOrder;
};

const getBlockDetailsOrderableItemOrder = (orderableItem: BlockDetailsFieldType) => {
  const orderableItemOrder: number[] = [];
  orderableItem.fields!.forEach(({ slug }) => orderableItemOrder.push(+slug.substring(slug.lastIndexOf(".") + 1, slug.length)));
  return orderableItemOrder;
};

const setBlockDetailsItemOrderAndNestedItemOrder = (blockDetails: BlockDetailsType, itemOrder: number[], nestedItemOrder: number[][]) => {
  blockDetails.fields
    .find(({ type }) => type === "itemList")!
    .fields!.forEach((field) => {
      const nestedItem: number[] = [];
      itemOrder.push(+field.slug.substring(field.slug.lastIndexOf(".") + 1, field.slug.length));
      field
        .fields!.find(({ type }) => type === "itemList")!
        .fields!.forEach((_field) => {
          nestedItem.push(+_field.slug.substring(_field.slug.lastIndexOf(".") + 1, _field.slug.length));
        });
      nestedItemOrder.push(nestedItem);
    });
};

const getTabStyleFields = (fields: BlockDetailsFieldType[]) => [...fields.filter(({ type }) => type === "tabStyle")];

const setStyleFieldsValueToBlockDetails = (blockDetails: BlockDetailsType, styleFields: BlockDetailsFieldType[]) => {
  styleFields.forEach((styleField) => {
    const targetField = getTargetField(blockDetails.fields, styleField.slug);
    if (targetField) targetField.value = styleField.value;
    if (styleField.fields) setStyleFieldsValueToBlockDetails(blockDetails, styleField.fields);
  });
};

// This is the component that will render all the blocks details.
const ManageBlock = () => {
  const loaderData = useLoaderData() as {
    result: BlockDetailsType;
    languages: string[];
    defaultLanguage: string;
    hasError: boolean;
    notFound?: boolean;
    header?: boolean;
    cookie?: boolean;
    status?: StatusBoxType;
  };

  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const locationState = location.state as { ParentBlock: string | null };

  const [blockDetails, setBlockDetails] = useState<BlockDetailsType>(
    loaderData.hasError ? null : JSON.parse(JSON.stringify(loaderData.result))
  );
  const [selectedStyleFields, setSelectedStyleFields] = useState(loaderData.hasError ? [] : getBlockStyleFields(blockDetails));
  const [tabStyleFields, setTabStyleFields] = useState<BlockDetailsFieldType[]>(
    loaderData.header ? getTabStyleFields(blockDetails.fields) : []
  );
  const [modal, setModal] = useState<{
    show: boolean;
    title: string;
    isForNestedAdding: boolean;
    fieldType: string | number;
    parentItemSlug: string;
  }>({
    show: false,
    title: "",
    isForNestedAdding: false,
    fieldType: "",
    parentItemSlug: "",
  });
  const [responsiveSidebarOpen, setResponsiveSidebarOpen] = useState(false);
  const [previewValue, setPreviewValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(loaderData.status);
  const [changeStatusLoading, setChangeStatusLoading] = useState<"D" | "P" | "U" | "-">("-");

  const getIsSubscribeDefault = () => {
    const formActionField = getTargetField(blockDetails.fields, "form_action.action");
    return formActionField && formActionField.value === "SB";
  };
  const [isSubscribe, setIsSubscribe] = useState(getIsSubscribeDefault());

  const { hasErrorSlugs, addSlug, removeSlug, language, setLanguage, setReload } = useContext(ManageBlockDetailContext);

  useEffect(() => {
    if (loaderData.hasError) return;

    const itemListFieldIndex = blockDetails.fields.findIndex(({ type }) => type === "itemList");
    if (itemListFieldIndex !== -1) {
      uniqueCounter = blockDetails.fields[itemListFieldIndex].fields!.length + 1;
    }
    setLanguage(loaderData.defaultLanguage);
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => sendBlockPreviewRequest(), 3000);
    return () => clearTimeout(timeout);
  }, [blockDetails]);

  useEffect(() => sendBlockPreviewRequest(), [language]);

  useEffect(() => {
    if (loaderData.hasError) return;

    const reloadedBlockDetails: BlockDetailsType = JSON.parse(JSON.stringify(loaderData.result));
    setBlockDetails(reloadedBlockDetails);
    setSelectedStyleFields(getBlockStyleFields(reloadedBlockDetails));
    if (loaderData.cookie) {
      setStatus(loaderData.status);
    }
    setReload(true);
  }, [loaderData]);

  useEffect(() => {
    if (tabStyleFields.length > 0) {
      const newBlockDetails = { ...blockDetails };
      tabStyleFields.forEach((tab) => {
        const targetTab = getTargetField(newBlockDetails.fields, tab.slug);
        if (targetTab) targetTab.fields = tab.fields;
      });
      setBlockDetails(newBlockDetails);
    }
  }, [tabStyleFields]);

  useEffect(() => {
    const newBlockDetails = { ...blockDetails };
    setStyleFieldsValueToBlockDetails(newBlockDetails, selectedStyleFields);
    setBlockDetails(newBlockDetails);
  }, [selectedStyleFields]);

  const setHoverFieldValue = (field: BlockDetailsFieldType, parentField: BlockDetailsFieldType) => {
    const relatedParentField = parentField.fields?.find(({ name }) => name === field.name);
    if (!relatedParentField) return;

    if (relatedParentField.value !== null) {
      field.value = relatedParentField.value;
    } else {
      field.fields?.forEach((_field) => setHoverFieldValue(_field, relatedParentField));
    }
  };

  const setHoverFieldValues = (field: BlockDetailsFieldType) => {
    const newBlockDetails = JSON.parse(JSON.stringify(blockDetails)) as BlockDetailsType;
    const hoverField = getTargetField(newBlockDetails.fields, field.slug);
    const hoverFieldParent = getTargetFieldParent(newBlockDetails.fields, field.slug);

    if (!hoverField || !hoverFieldParent) return;
    const hoverFieldParentNames = hoverFieldParent.fields?.map((_field) => _field.name);

    hoverField.fields?.forEach((_field) => {
      if (_field.type === "switch") {
        _field.value = true;
      }

      if (hoverFieldParentNames?.includes(_field.name)) setHoverFieldValue(_field, hoverFieldParent);
    });

    if (setBlockDetails) {
      setBlockDetails(newBlockDetails);
      setReload(true);
    }
  };

  const sendBlockPreviewRequest = () => {
    if (!language) return;
    const requestBody = getRequestBody(blockDetails);
    blockPreview(params.contentId!, requestBody, language).then((response) => {
      if (response.status === 200) setPreviewValue(JSON.stringify(response.data));
    });
  };

  const getRequestBody = (_blockDetails: BlockDetailsType, isHeader?: boolean, isCookie?: boolean) => {
    const requestBody: SlugValuePairType[] = [];
    blockDetailGetSlugValuePairs(_blockDetails.fields, requestBody);

    requestBody.push({ slug: "name", value: _blockDetails.name });
    requestBody.push({ slug: "type", value: _blockDetails.type });
    requestBody.push({ slug: "parent", value: locationState ? locationState.ParentBlock : null });

    if (orderableItem) {
      requestBody.push({ slug: "orderable_item_order", value: getBlockDetailsOrderableItemOrder(orderableItem) });
    }

    if (isHeader) return requestBody;

    if (["CardListBlock", "TimelineBlock", "PostListBlock"].includes(_blockDetails.type) || isCookie) {
      requestBody.push({ slug: "item_order", value: getBlockDetailsItemOrder(_blockDetails) });
    } else if (_blockDetails.type === "CollapsibleBlock" || _blockDetails.type === "FormBlock") {
      const itemOrder: number[] = [];
      const nestedItemOrder: number[][] = [];
      setBlockDetailsItemOrderAndNestedItemOrder(_blockDetails, itemOrder, nestedItemOrder);
      requestBody.push({ slug: "item_order", value: itemOrder });
      requestBody.push({ slug: "nested_item_order", value: nestedItemOrder });
    }

    return requestBody;
  };

  const dropdownAPIChangeHandler = async (_blockDetails: BlockDetailsType) => {
    const requestBody = getRequestBody(_blockDetails);
    console.log(requestBody);
    const result = await dropdownAPIChange(params.contentId!, params.blockId!, requestBody);
    console.log(result);
    if (result.data) setBlockDetails(result.data);
  };

  const onChangeValue = (
    slug: string,
    value: string | number | boolean | ImageValueType | RowData[] | string[] | null,
    richTextLanguage?: string,
    neededTranslation?: boolean
  ) => {
    if (slug === "form_action.action") {
      setIsSubscribe(value === "SB");
    }

    setBlockDetails((_blockDetails) => {
      const newBlockDetails = { ..._blockDetails };
      const targetField = getTargetField(newBlockDetails.fields, slug);
      if (targetField) {
        if (neededTranslation) {
          const _value = JSON.parse(JSON.stringify(targetField.value)) as LangValuePairType[];
          if (richTextLanguage) {
            _value.find(({ lang }) => lang === richTextLanguage)!.value = value as string;
          } else {
            _value.find(({ lang }) => lang === language)!.value = value as string | string[] | RowData[];
          }
          targetField.value = _value;

          if (targetField.required && (targetField.value as LangValuePairType[]).find(({ lang }) => lang === language)!.value === "") {
            targetField.errorMessage = "The field is required.";
            addSlug(slug);
          }
        } else {
          targetField.value = value;
        }

        // Req team must decide about URL validation policies!
        // if (targetField.value && targetField.fieldType === "url" && !isValidURL(`${targetField.value}`)) {
        //   targetField.errorMessage = "The url is invalid.";
        //   addSlug(slug);
        // }
        if (targetField.fieldType === "email" && !isValidEmail(`${targetField.value}`)) {
          targetField.errorMessage = "The email is invalid.";
          addSlug(slug);
        } else if (!targetField.value && targetField.required) {
          targetField.errorMessage = "The field is required.";
          addSlug(slug);
        } else {
          targetField.errorMessage = "";
          removeSlug(slug);
        }
      }

      if (targetField?.type === "dropdownAPI") dropdownAPIChangeHandler(newBlockDetails);

      return newBlockDetails;
    });
  };

  const onCancelButtonClick = () => {
    if (loaderData.header) {
      navigate(`/menu`, { replace: true });
    } else if (loaderData.cookie) {
      navigate(`/content`, { replace: true });
    } else {
      navigate(`/content/${params.contentId}/blocks/`, { replace: true });
    }
  };

  const requiredFieldsErrorHandler = () => {
    // Collecting all required fields in the requiredFields array.
    const requiredFields: BlockDetailsFieldType[] = [];
    setRequiredFields(blockDetails.fields, requiredFields);

    let isError = false;
    if (requiredFields.length > 0) {
      // If any of required fields has not a value,
      // We show the error style and error message.
      for (const requiredField of requiredFields) {
        if (!requiredField.value) {
          addSlug(requiredField.slug);
          requiredField.errorMessage = "The field is required.";
          isError = true;
        }
      }
    }

    return isError;
  };

  const onSaveButtonClick = () => {
    // If the function returns true,
    // It means there's at least a single required field which has not any value.
    // So we don't continue the saving process!
    if (requiredFieldsErrorHandler()) return;

    setLoading(true);

    if (loaderData.header) {
      const headerRequestBody = getRequestBody(blockDetails, true);
      console.log(JSON.stringify(headerRequestBody));

      editHeader(headerRequestBody).then((response) => {
        if (response.status === 200) {
          setLoading(false);
          navigate("/menu", { replace: true });
        }
      });
    } else if (loaderData.cookie) {
      const cookieRequestBody = getRequestBody(blockDetails, false, true);
      console.log(JSON.stringify(cookieRequestBody));

      editCookie(cookieRequestBody).then((response) => {
        if (response.status === 200) {
          setStatus(response.data.status as StatusBoxType);
          setLoading(false);
        }
      });
    } else {
      const requestBody = getRequestBody(blockDetails);
      console.log(JSON.stringify(requestBody));

      if (params.blockId === "0") {
        addBlock(params.contentId!, requestBody).then((response) => {
          if (response.status === 201) {
            setLoading(false);
            navigate(`/content/${params.contentId}/blocks/`, { replace: true });
          }
        });
      } else {
        editBlock(params.contentId!, params.blockId!, requestBody, blockDetails.type === "PostSummaryBlock").then((response) => {
          if (response.status === 200) {
            setLoading(false);
            navigate(`/content/${params.contentId}/blocks/`, { replace: true });
          }
        });
      }
    }
  };

  const onPaletteIconClick = (fields: BlockDetailsFieldType[], slug?: string) => {
    if (slug === "sticky_header") {
      setTabStyleFields(getTabStyleFields(fields));
      setSelectedStyleFields(fields.find(({ name }) => name === "style")!.fields!);
    } else {
      if (loaderData.header) {
        const _tabStyleFields = getTabStyleFields(fields);
        setTabStyleFields(_tabStyleFields);
      }
      setSelectedStyleFields(fields.filter(({ isStyle }) => isStyle));
    }
    setResponsiveSidebarOpen(true);
  };

  const getBlockDetailsSections = () =>
    blockDetails.fields.filter(
      ({ slug, isStyle, type, excluded }) =>
        !isStyle &&
        type !== "itemList" &&
        ![
          "field_types_dropdown",
          "slider",
          "is_full_width_slider",
          "more_button",
          "form_action.mail_to",
          "gridview",
          "filter_label",
          "filter_dropdown",
          "subscribe",
        ].includes(slug) &&
        !excluded
    );

  const renderBlockDetailsSections = (fields: BlockDetailsFieldType[]) => {
    return fields.map((field) => {
      const switchField = field.fields?.find(({ type }) => type === "switch");
      return (
        <BlockDetailsSectionContainer
          key={field.slug}
          title={field.name}
          headerActions={
            field.fields && field.fields.filter(({ isStyle }) => isStyle).length > 0
              ? [
                  {
                    icon: faPalette,
                    onClick: () => onPaletteIconClick(field.fields!, field.slug),
                  },
                ]
              : []
          }
          switchableActionIndex={0}
          switchValue={switchField ? (switchField.value as boolean) : undefined}
          setSwitchValue={switchField ? (value) => onChangeValue(switchField.slug, value) : undefined}
          collapsible={switchField !== undefined}
          collapsed={(switchField?.value as boolean) === false}
        >
          <BlockDetailsSection
            item={field}
            onChangeValue={onChangeValue}
            blockDetailsAllItems={blockDetails.fields}
            setHoverFieldValues={setHoverFieldValues}
            onPaletteIconClick={onPaletteIconClick}
            expandParentOnDropdownShowOptions={switchField != undefined}
          />
        </BlockDetailsSectionContainer>
      );
    });
  };

  const onAddItemButtonClick = () => {
    if (!itemListField) return;

    if (blockDetails.type === "FormBlock") {
      setModal({ show: true, title: "Adding Form Row", isForNestedAdding: false, fieldType: "", parentItemSlug: "" });
      return;
    }

    const newItem = getItem();
    addItem(newItem);
  };

  const onAddNestedItemButtonClick = (parentItemSlug: string) => {
    if (blockDetails.type === "FormBlock") {
      setModal({ show: true, title: "Adding Form Field", isForNestedAdding: true, fieldType: "", parentItemSlug });
      return;
    }

    const parentItem = getTargetField(blockDetails.fields, parentItemSlug);
    if (!parentItem) return;

    const newNestedItem = getNestedItem(
      parentItemSlug,
      JSON.parse(JSON.stringify(blockDetails.fields.find(({ slug }) => slug === "empty_nested_item")!)) as BlockDetailsFieldType,
      parentItem.fields!.find(({ type }) => type === "itemList")!.name
    );
    addNestedItem(parentItemSlug, newNestedItem);
  };

  const onModalPositiveButtonClick = () => {
    if (!modal.fieldType) return;

    if (modal.isForNestedAdding) {
      const parentItem = getTargetField(blockDetails.fields, modal.parentItemSlug);
      if (!parentItem) return;

      const newNestedItem = getNestedItem(
        modal.parentItemSlug,
        JSON.parse(JSON.stringify(blockDetails.fields.find(({ slug }) => slug === modal.fieldType)!)) as BlockDetailsFieldType,
        parentItem.fields!.find(({ type }) => type === "itemList")!.name
      );
      addNestedItem(modal.parentItemSlug, newNestedItem);
    } else {
      if (!itemListField) return;

      const newItem = getItem();
      const newNestedItem = getNestedItem(
        newItem.slug,
        JSON.parse(JSON.stringify(blockDetails.fields.find(({ slug }) => slug === modal.fieldType)!)) as BlockDetailsFieldType,
        newItem.fields!.find(({ type }) => type === "itemList")!.name
      );
      newNestedItem.excluded = false;
      newItem.fields!.find(({ type }) => type === "itemList")!.fields!.push(newNestedItem);
      addItem(newItem);
    }

    setModal({ ...modal, show: false });
  };

  const getItem = () => {
    const newItem = JSON.parse(JSON.stringify(blockDetails.fields.find(({ slug }) => slug === "empty_item")!)) as BlockDetailsFieldType;
    const newId = uniqueCounter;
    uniqueCounter++;
    newItem.slug = `${itemListField!.slug}.${newId}`;
    newItem.name = `${itemListField!.slug}${newId}`;
    blockAddedItemSetSlug(`${newId}`, newItem, "1");

    if (newItem.fields) {
      const itemTagField = newItem.fields.find(({ type }) => type === "itemTag");
      if (itemTagField)
        itemTagField.value = `${itemListField!.name} ${newItem.slug.substring(newItem.slug.lastIndexOf(".") + 1, newItem.slug.length)}`;

      const _itemListField = newItem.fields.find(({ type }) => type === "itemList");
      if (_itemListField && _itemListField.fields && _itemListField?.fields[0]) {
        let nestedItemName = _itemListField.fields[0].name;
        nestedItemName = nestedItemName.replace("#", `${newId}`);
        nestedItemName = nestedItemName.replace("##", "1");
        _itemListField.fields[0].name = nestedItemName;

        const nestedItemTagField = _itemListField.fields[0].fields!.find(({ type }) => type === "itemTag");
        nestedItemTagField!.value = `${_itemListField.name} ${_itemListField.fields[0].slug.substring(
          _itemListField.fields[0].slug.lastIndexOf(".") + 1,
          _itemListField.fields[0].slug.length
        )}`;
      }
    }

    return newItem;
  };

  const addItem = (newItem: BlockDetailsFieldType) => {
    const newBlockDetails: BlockDetailsType = JSON.parse(JSON.stringify(blockDetails));
    const targetField = newBlockDetails.fields.find(({ type }) => type === "itemList")!;
    newItem.excluded = false;
    targetField.fields!.push(newItem);
    setBlockDetails(newBlockDetails);
  };

  const getNestedItem = (parentItemSlug: string, newNestedItem: BlockDetailsFieldType, itemsLabel: string) => {
    const newNestedId = uniqueCounter;
    uniqueCounter++;
    blockAddedItemSetSlug(
      parentItemSlug.substring(parentItemSlug.lastIndexOf(".") + 1, parentItemSlug.length),
      newNestedItem,
      `${newNestedId}`
    );
    const temp = newNestedItem.fields![0].slug;
    newNestedItem.slug = temp.substring(0, temp.lastIndexOf("."));
    newNestedItem.name =
      newNestedItem.slug.slice(0, newNestedItem.slug.length - 2) + newNestedItem.slug.slice(newNestedItem.slug.length - 1);

    const tagItemField = newNestedItem.fields!.find(({ type }) => type === "itemTag");
    if (tagItemField) {
      tagItemField.value = `${itemsLabel} ${newNestedItem.slug.substring(
        newNestedItem.slug.lastIndexOf(".") + 1,
        newNestedItem.slug.length
      )}`;
    }

    return newNestedItem;
  };

  const addNestedItem = (parentItemSlug: string, newNestedItem: BlockDetailsFieldType) => {
    const newBlockDetails: BlockDetailsType = JSON.parse(JSON.stringify(blockDetails));
    const targetField = getTargetField(newBlockDetails.fields, parentItemSlug)!.fields!.find(({ type }) => type === "itemList")!;
    newNestedItem.excluded = false;
    targetField.fields!.push(newNestedItem);
    setBlockDetails(newBlockDetails);
  };

  const getSecondTabTitle = () => {
    let secondTabTitle;
    if (loaderData.header && tabStyleFields.length > 0) {
      secondTabTitle = "Menu 1";
    } else if (itemListField || orderableItem) {
      secondTabTitle = "Block Tree";
    }
    return secondTabTitle;
  };

  const getThirdTabTitle = () => {
    let thirdTabTitle;
    if (loaderData.header && tabStyleFields.length > 1) {
      thirdTabTitle = "Menu 2";
    }
    return thirdTabTitle;
  };

  const getAddItemButtonLabel = () => {
    if (blockDetails.type === "CollapsibleBlock") {
      return "Category";
    } else if (blockDetails.type === "FormBlock") {
      return "Row";
    }

    return "Item";
  };

  const renderStatusSection = () => {
    const statusButtonClickHandler = (url: string) => {
      if (url === "publish") {
        const cookieRequestBody = getRequestBody(blockDetails, false, true);
        console.log(JSON.stringify(cookieRequestBody));

        editCookie(cookieRequestBody).then((response) => {
          if (response.status === 200) changeStatusRequest(url);
        });
      } else if (url === "discard") {
        changeStatusRequest(url, () => {
          navigate(".", { replace: true });
        });
      } else {
        changeStatusRequest(url);
      }
    };

    const changeStatusRequest = (url: string, successHandler?: () => void) => {
      editCookieStatus(url).then((response) => {
        if (response.statusText === "OK") {
          setStatus(response.data.status);
          if (successHandler) successHandler();
        }
        setChangeStatusLoading("-");
      });
    };

    return (
      <div
        className="p-6 bg-secondary-100 rounded-[5px] flex flex-wrap gap-6 items-center justify-between"
        style={{ boxShadow: "0 4px 4px 0 rgba(0, 0, 0, 0.15)" }}
      >
        <p>
          Published Status: <span className="font-light">{getStatusTypeStr(status!)}</span>
        </p>
        <div className="flex flex-wrap items-center gap-2">
          {status === "DP" && (
            <div className="flex items-center justify-center">
              {changeStatusLoading === "D" && <Loading style={{ marginRight: 8 }} loadingMode={LoadingMode.Button} />}
              <p
                className="text-primary mr-2 font-medium cursor-pointer"
                onClick={() => {
                  setChangeStatusLoading("D");
                  statusButtonClickHandler("discard");
                }}
              >
                Discard Unpublished Changes
              </p>
            </div>
          )}
          {(status === "PB" || status === "DP") && (
            <Button
              className="font-normal"
              type={ButtonType.SECONDARY}
              showLoading={changeStatusLoading === "U"}
              onClick={() => {
                setChangeStatusLoading("U");
                statusButtonClickHandler("unpublish");
              }}
            >
              Unpublish
            </Button>
          )}
          {(status === "DF" || status === "DP") && (
            <Button
              className="font-normal"
              showLoading={changeStatusLoading === "P"}
              type={ButtonType.PRIMARY}
              onClick={() => {
                setChangeStatusLoading("P");
                statusButtonClickHandler("publish");
              }}
            >
              Save & Publish
            </Button>
          )}
        </div>
      </div>
    );
  };

  const tabStyleFieldsChangeHandler = (fields: BlockDetailsFieldType[], index: number) => {
    const newStyleSidebar2Tab = [...tabStyleFields];
    newStyleSidebar2Tab[index].fields = fields;
    setTabStyleFields(newStyleSidebar2Tab);
  };

  if (loaderData.hasError && loaderData.notFound) {
    return <NotFound />;
  }

  if (!language) return null;

  const itemListField = blockDetails.fields.find(({ type }) => type === "itemList");
  const orderableItem = getOrderableItem(blockDetails.fields);
  const itemStyleField = blockDetails.fields.find(({ slug }) => slug === "item_style");
  const popupDropdownField = blockDetails.fields.find(({ slug }) => slug === "field_types_dropdown");

  // console.log(blockDetails);
  // console.log(hasErrorSlugs);

  return (
    <PageContainer>
      <StyleSidebarLayout
        defaultLanguage={loaderData.defaultLanguage}
        languages={loaderData.languages}
        contentName={blockDetails.contentName ?? blockDetails.name}
        contentId={params.contentId ?? "header"}
        blockName={blockDetails.name}
        sidebar={
          <StylesSidebar
            firstTabStyleFields={selectedStyleFields}
            setFirstTabStyleFields={setSelectedStyleFields}
            secondTabStyleFields={tabStyleFields.length > 0 ? tabStyleFields[0].fields : undefined}
            thirdTabStyleFields={tabStyleFields.length > 0 ? tabStyleFields[1].fields : undefined}
            tabStyleFieldsChangeHandler={tabStyleFields.length > 0 ? tabStyleFieldsChangeHandler : undefined}
            firstTabTitle="Style"
            secondTabTitle={getSecondTabTitle()}
            thirdTabTitle={getThirdTabTitle()}
            onSaveButtonClick={onSaveButtonClick}
            onCancelButtonClick={onCancelButtonClick}
            hasFooter
            saveButtonDisable={hasErrorSlugs.length > 0}
            itemListField={itemListField}
            orderableItem={orderableItem}
            setBlockDetails={setBlockDetails}
            loading={loading}
          />
        }
        responsiveSidebar={
          <aside
            className="fixed top-[100px] left-0 right-0 bottom-0 z-50 duration-300 720:hidden"
            style={{
              transform: responsiveSidebarOpen ? "translateX(0)" : "translateX(100%) translateX(-24px)",
            }}
          >
            <StylesSidebar
              firstTabStyleFields={selectedStyleFields}
              setFirstTabStyleFields={setSelectedStyleFields}
              responsiveSidebarOpen={responsiveSidebarOpen}
              setResponsiveSidebarOpen={setResponsiveSidebarOpen}
              firstTabTitle="Style"
              secondTabTitle={getSecondTabTitle()}
              thirdTabTitle={getThirdTabTitle()}
              onSaveButtonClick={onSaveButtonClick}
              onCancelButtonClick={onCancelButtonClick}
              itemListField={itemListField}
              orderableItem={orderableItem}
              setBlockDetails={setBlockDetails}
              loading={loading}
            />
          </aside>
        }
      >
        <div className="grid grid-cols-1 gap-4">
          {status && renderStatusSection()}
          <BlockPreview value={previewValue} type={blockDetails.type} />
          <BlockDetailsMetaData
            title={blockDetails.name}
            type={blockDetails.type}
            lastUpdate={blockDetails.updatedAt ?? "-"}
            onIconClick={() =>
              onPaletteIconClick(
                loaderData.header
                  ? [...getBlockStyleFields(blockDetails), ...getTabStyleFields(blockDetails.fields)]
                  : getBlockStyleFields(blockDetails)
              )
            }
          />
          {renderBlockDetailsSections(getBlockDetailsSections())}
        </div>
        {itemListField && !itemListField.hiddenList && (
          <div>
            <header className="flex justify-end items-center my-8">
              {itemStyleField && (
                <button
                  className="w-[42px] h-[42px] grid place-items-center text-white bg-primary rounded-[5px]"
                  onClick={() => onPaletteIconClick(itemStyleField.fields!)}
                >
                  <FontAwesomeIcon icon={faPalette} />
                </button>
              )}
              {!isSubscribe && (
                <Button type={ButtonType.PRIMARY} className="ml-2 flex items-center" onClick={onAddItemButtonClick}>
                  <FontAwesomeIcon icon={faPlus} />
                  <p className="ml-2">Add {getAddItemButtonLabel()}</p>
                </Button>
              )}
            </header>
            {isSubscribe ? (
              renderBlockDetailsSections([getTargetField(blockDetails.fields, "subscribe")!])
            ) : (
              <BlockDetailsList
                setBlockDetails={setBlockDetails}
                items={itemListField.fields!.filter(({ excluded }) => !excluded)}
                onChangeValue={onChangeValue}
                onPaletteIconClick={onPaletteIconClick}
                onAddNestedItemButtonClick={onAddNestedItemButtonClick}
                setHoverFieldValues={setHoverFieldValues}
              />
            )}
          </div>
        )}
        <div className="h-10 720:hidden" />
        <footer className="flex justify-end items-center fixed bottom-0 right-7 bg-white py-4 left-0 720:hidden">
          <Button type={ButtonType.TERTIARY} onClick={onCancelButtonClick}>
            Cancel
          </Button>
          <Button type={ButtonType.PRIMARY} className="ml-4 mr-4" onClick={onSaveButtonClick} disable={hasErrorSlugs.length > 0}>
            Save
          </Button>
        </footer>
      </StyleSidebarLayout>
      {popupDropdownField && (
        <CmsModalMainShape
          title={modal.title}
          positiveBtnTitle="Add"
          onPositiveBtnClick={onModalPositiveButtonClick}
          onNegativeBtnClick={() => setModal({ ...modal, show: false, fieldType: "" })}
          showModal={modal.show}
        >
          <Dropdown
            className="mt-8"
            options={popupDropdownField.values! as OptionType[]}
            onOptionSelect={(option) => setModal({ ...modal, fieldType: option.key })}
            selectedOption={(popupDropdownField.values! as OptionType[]).find(({ key }) => key === popupDropdownField.value) ?? null}
            label={popupDropdownField.name}
          />
        </CmsModalMainShape>
      )}
    </PageContainer>
  );
};

export default ManageBlock;

export const loader = async ({ params, request }: LoaderFunctionArgs) => {
  const type = new URL(request.url).searchParams.get("type");
  const result = await getBlockDetails(params.contentId!, params.blockId!, type!);
  if (result.data) {
    return JSON.parse(
      JSON.stringify({ result: result.data, languages: result.languages, defaultLanguage: result.defaultLanguage, hasError: false })
    );
  } else {
    return { hasError: true, notFound: result.response.status === 404 };
  }
};

export const headerLoader = async () => {
  const result = await getHeader();
  return JSON.parse(
    JSON.stringify({ result: result.data, languages: result.languages, defaultLanguage: result.defaultLanguage, header: true })
  );
};

export const cookieLoader = async () => {
  const result = await getCookie();
  return JSON.parse(
    JSON.stringify({
      result: result.data,
      languages: result.languages,
      defaultLanguage: result.defaultLanguage,
      cookie: true,
      status: result.status,
    })
  );
};
