import React, { useState } from "react";
import BlockBox from "./BlockBox";
import ColorPicker from "../../inputs/ColorPicker";
import { BackGroundTextBorderType, DropdownChoice } from "../../../types/types";
import BorderBlock from "./BorderBlock";

const TextBorderAndBackgroundBlock: React.FC<{
  className?: string;
  style: BackGroundTextBorderType;
  title: string;
  onChange: (style: BackGroundTextBorderType) => void;
  borderStyleOptions: DropdownChoice[];
  borderWidthOptions: DropdownChoice[];
  borderSideOptions: DropdownChoice[];
}> = ({ className, style, title, onChange, borderStyleOptions, borderWidthOptions, borderSideOptions }) => {
  const [backgroundColor, setBackgroundColor] = useState(style.backgroundColor);
  const [textColor, setTextColor] = useState(style.textColor);

  return (
    <BlockBox className={className} title={title}>
      <div className="flex justify-between flex-wrap">
        <div className="w-[238px]">
          <ColorPicker
            color={backgroundColor}
            label="Background Color"
            setColor={(value) => {
              setBackgroundColor(value.toString());
              onChange({ ...style, backgroundColor: value.toString() });
            }}
          />
        </div>

        <div className="w-[238px]">
          <ColorPicker
            color={textColor}
            label="Text Color"
            setColor={(value) => {
              setTextColor(value.toString());
              onChange({ ...style, textColor: value.toString() });
            }}
          />
        </div>
      </div>
      <BorderBlock
        title="Border"
        border={style.border}
        onChange={(value) => {
          onChange({ ...style, border: value });
        }}
        className="mt-4"
        borderStyleOptions={borderStyleOptions}
        borderWidthOptions={borderWidthOptions}
        borderSideOptions={borderSideOptions}
      />
    </BlockBox>
  );
};

export default TextBorderAndBackgroundBlock;
