import React from "react";
import { Row } from "react-table";
import { LanguageList } from "../../../../types/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";

const TableRow: React.FC<{
  row: Row<LanguageList>;
  className?: string;
  activeInactiveHandler: (languageCode: string, status: boolean) => void;
}> = ({ row, className, activeInactiveHandler }) => {
  return (
    <tr className={`px-4  text-black2B border border-t-0 border-secondary-200 border-r-0 border-l-0  border-b h-[51px] ${className}`}>
      <td className="text-sm font-light w-[170px] sticky left-0  pl-4  td-firefox table__cell h-[51px]">
        <div className="flex bg-white">
          <div className="text-sm font-light flex items-center">{row.original.language}</div>
          {row.original.default && (
            <div className="text-primary ml-6 border border-primary py-2 px-4 rounded-md text-xs font-normal">Default</div>
          )}
        </div>
      </td>
      <td className="text-sm font-light uppercase td-firefox  pr-4">
        <div className="flex justify-center">{row.original.code}</div>
      </td>
      <td className="text-sm font-light td-firefox flex justify-center h-[51px] items-center">
        {!row.original.default && <div className="flex items-center mr-1">Active</div>}
        <div
          className={`  flex  h-8 w-16 justify-center items-center ${!row.original.default && "border rounded"} ${
            row.original.active ? "border-success" : "border-[#DCDCDC]"
          }`}
        >
          <div
            onClick={() => activeInactiveHandler(row.original.code, true)}
            className={`w-8 h-8  rounded flex justify-center items-center cursor-pointer ${row.original.active && "bg-success"} `}
          >
            <FontAwesomeIcon icon={faCheck} className={`text-2xl  ${row.original.active ? "text-white" : "text-[#DCDCDC]"}`} />
          </div>
          {!row.original.default && (
            <div
              onClick={() => activeInactiveHandler(row.original.code, false)}
              className={`w-8 h-8  rounded rounded-l-none flex justify-center items-center cursor-pointer ${
                !row.original.active && "bg-[#DCDCDC]"
              }`}
            >
              <FontAwesomeIcon icon={faXmark} className={`text-2xl ${row.original.active ? "text-[#DCDCDC]" : "text-white"}`} />
            </div>
          )}
        </div>
        {!row.original.default && <div className="flex items-center ml-1">Inactive</div>}
      </td>
    </tr>
  );
};

export default TableRow;
