import React from "react";
import InputField from "../inputs/InputField";
import { SiteSettingType } from "../../types/types";

const AnalysisBox: React.FC<{ dataState: SiteSettingType; setDataState: (data: SiteSettingType) => void }> = ({
  dataState,
  setDataState,
}) => {
  return (
    <div className="bg-secondary-100 p-4 w-full 1140:w-1/2  1140:my-0 my-2 1140:ml-2 ">
      <div>Anylysis</div>
      <div className="mt-4 max-w-[238px]">
        <InputField
          label="gtTrackingId"
          onChange={(value) => {
            setDataState({ ...dataState, gtTrackingId: value });
          }}
          type="T"
          value={dataState.gtTrackingId ?? ""}
        />
      </div>
    </div>
  );
};

export default AnalysisBox;
