import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTh } from "@fortawesome/pro-light-svg-icons";
import { faList } from "@fortawesome/pro-regular-svg-icons";
import { OptionType } from "../../utils/types";
import SimpleDropDown from "../inputs/SimpleDropDown";

const SortAndDisplayField: React.FC<{
  className?: string;
  setListOrGridstate: (state: boolean) => void;
  selectedSortedAs: (input: string) => void;
  sortedAs: string | null;
}> = ({ className, setListOrGridstate, selectedSortedAs, sortedAs }) => {
  const [tableOrGridstate, setTableOrGridstate] = useState(true);
  const [selectedOption, setSelectedOption] = useState<OptionType | null>(null);

  const sortOptions = [
    { key: "", label: "NoSort" },
    { key: "name", label: "Name" },
    { key: "type", label: "Type" },
    { key: "size", label: "Size" },
    { key: "isUsed", label: "Used" },
    { key: "uploadDate", label: "Upload Date" },
  ];

  useEffect(() => {
    if (!sortedAs) {
      setSelectedOption(null);
    } else {
      const newSelectedOption: OptionType = sortOptions.find((option) => option.key === sortedAs)!;

      setSelectedOption(newSelectedOption);
    }
    console.log("sortedAs ", sortedAs);
  }, [sortedAs]);

  return (
    <div className={`flex flex-row-reverse justify-between px-2 bg-white ${className}`}>
      <div className="flex h-9">
        <div
          onClick={() => {
            setTableOrGridstate(false);
            setListOrGridstate(true);
          }}
          className={`border rounded-md  md:rounded-r-none md:rounded-l-md border-primary md:border-r-primary cursor-pointer ${
            tableOrGridstate
              ? "border-primary md:border-[#F0EFED] md:text-[#7C828A] text-primary"
              : "border-primary text-primary hidden md:block"
          }`}
        >
          <FontAwesomeIcon className={` text-sm px-[12.8px] py-[8px] align-middle`} icon={faTh} />
        </div>
        <div
          onClick={() => {
            setTableOrGridstate(true);
            setListOrGridstate(false);
          }}
          className={`delay-75 border rounded-md md:rounded-r-md md:rounded-l-none  md:border-l-0 hover:cursor-pointer ${
            tableOrGridstate
              ? "border-primary text-primary hidden md:block"
              : "md:border-[#F0EFED] md:text-[#7C828A] text--[#B29B66] borde-[#B29B66]"
          }`}
        >
          <FontAwesomeIcon className={` text-sm px-[12.8px] py-[8px] align-middle`} icon={faList} />
        </div>
      </div>
      {!tableOrGridstate && (
        <div className=" flex">
          <label htmlFor="" className="text-[#D2CEC6]">
            Sort:
          </label>
          <SimpleDropDown
            onChange={(option) => {
              setSelectedOption(option);
              selectedSortedAs(option.key as string);
            }}
            options={sortOptions}
            selectedOption={selectedOption}
            className="w-[217px] ml-2"
            defaultOption="NoSort"
          />
        </div>
      )}
    </div>
  );
};

export default SortAndDisplayField;
