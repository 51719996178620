import React from "react";
import BlockBox from "./BlockBox";
import GeneralSettingsButtonBlock from "./GeneralSettingsButtonBlock";
import BorderBlock from "./BorderBlock";
import { DropdownChoice, ButtonStyleType } from "../../../types/types";
import ShadowBlock from "./ShadowBlock";
import PaddingBlock from "./PaddingBlock";

const HoverBlock: React.FC<{
  className: string;
  borderSideOptions: DropdownChoice[];
  borderStyleOptions: DropdownChoice[];
  borderWidthOptions: DropdownChoice[];
  hoverButtonStyle: ButtonStyleType;
  setButtonStyleState: (hoverButtonStyle: ButtonStyleType) => void;
}> = ({
  className,
  borderSideOptions,
  borderStyleOptions,
  borderWidthOptions,
  hoverButtonStyle,
  setButtonStyleState,
}) => {
  return (
    <BlockBox title="Hover" className={className}>
      <GeneralSettingsButtonBlock
        backgroundColor={hoverButtonStyle.background.color}
        onChange={(color) => {
          setButtonStyleState({ ...hoverButtonStyle, background: { ...hoverButtonStyle.background, color: color } });
        }}
      />
      <BorderBlock
        border={hoverButtonStyle.border}
        borderSideOptions={borderSideOptions}
        borderStyleOptions={borderStyleOptions}
        borderWidthOptions={borderWidthOptions}
        onChange={(border) => setButtonStyleState({ ...hoverButtonStyle, border: { ...border } })}
        title="Border"
        className="mt-4"
      />
      <ShadowBlock
        onChange={(shadow) => setButtonStyleState({ ...hoverButtonStyle, shadow: { ...shadow } })}
        shadowStyle={hoverButtonStyle.shadow}
        className="mt-4"
      />
      <PaddingBlock
        onChange={(padding) => {
          setButtonStyleState({ ...hoverButtonStyle, padding: { ...padding } });
        }}
        padding={hoverButtonStyle.padding}
        className="mt-4"
      />
    </BlockBox>
  );
};

export default HoverBlock;
