import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/pro-regular-svg-icons";
import { ThemeMenuType } from "../../types/types";

const ThemeMenu: React.FC<{ menu: ThemeMenuType }> = ({ menu }) => {
  return (
    <div
      className={`text-[15px] font-medium w-[255px] h-[55px] flex items-center justify-between ${
        menu.isClicked ? "bg-primary text-white" : "bg-secondary-100 text-black2B"
      }  mb-4 rounded  px-6 hover:cursor-pointer shadow`}
    >
      {menu.title}
      <span>
        <FontAwesomeIcon icon={faPen} className={`text-base ml-4 ${menu.isClicked ? "text-white" : "text-primary"}`} />
      </span>
    </div>
  );
};

export default ThemeMenu;
