import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faMultiply } from "@fortawesome/pro-solid-svg-icons";

const IsUsedBox: React.FC<{ isUsed: string }> = ({ isUsed }) => {
  let isUsedBox: JSX.Element;

  switch (isUsed) {
    case "true":
      isUsedBox = (
        <span className="w-[22px] h-[22px] flex border border-[#62A630] rounded-full text-[#62A630] items-center justify-center">
          <FontAwesomeIcon className="text-base" icon={faCheck} />
        </span>
      );
      break;
    case "false":
      isUsedBox = (
        <span className="w-[22px] h-[22px] border border-[#E55D63] rounded-full text-[#E55D63] flex items-center justify-center">
          <FontAwesomeIcon className="text-base" icon={faMultiply} />
        </span>
      );
      break;
    default:
      isUsedBox = <p></p>;
  }

  return <div>{isUsedBox}</div>;
};

export default IsUsedBox;
