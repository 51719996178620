import { useEffect, useState } from "react";
import { logIn } from "../../api/auth";
import { useNavigate } from "react-router-dom";
import Checkbox from "../inputs/Checkbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { faEyeSlash } from "@fortawesome/pro-light-svg-icons";
import LoginContainer from "./LoginContainer";
import FormContainer from "./FormContainer";
import { isValidEmail } from "../../utils/utils";
import useRecaptchaV3 from "./reCaptchaV3/useRecaptchaV3";
import { encode, decode } from "string-encode-decode";

const Login = () => {
  const RECAPTCHA_SITE_KEY = "6LeFlVkoAAAAACXmpvL0bnTMhADka00VW4EhC0p1";

  const executeRecaptcha = useRecaptchaV3(RECAPTCHA_SITE_KEY);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(localStorage.getItem("myapp-email") || "");
  const [password, setPassword] = useState(decode(localStorage.getItem("myapp-password")) || "");
  const [rememberIsChecked, setRememberIsChecked] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loginClicked, setLoginClicked] = useState(false);
  const [emailIsValid, setEmailIsValid] = useState(false);
  const [buttonIsDisable, setButtonIsDisable] = useState(false);
  const [passwordReadOnly, setPasswordReadOnly] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  const encodedPassword = encode(password);

  const handleSignInSuccess = (token: string, refresh: string) => {
    if (rememberIsChecked) {
      localStorage.setItem("myapp-email", email);
      localStorage.setItem("myapp-password", encodedPassword);
    } else {
      localStorage.setItem("myapp-email", "");
      localStorage.setItem("myapp-password", "");
    }
    localStorage.setItem("token", token);
    localStorage.setItem("refresh", refresh);

    navigate("/");

    const today = new Date();
    const dateStr =
      ("00" + (today.getMonth() + 1)).slice(-2) +
      "/" +
      ("00" + today.getDate()).slice(-2) +
      "/" +
      today.getFullYear() +
      " " +
      ("00" + today.getHours()).slice(-2) +
      ":" +
      ("00" + today.getMinutes()).slice(-2) +
      ":" +
      ("00" + today.getSeconds()).slice(-2);
    localStorage.setItem("loginTime", dateStr);
  };

  const submitHandler = async () => {
    const decodedPassword = decode(encodedPassword);
    setLoginClicked(true);
    emailIsValid && setLoading(true);
    const recaptchaToken = await executeRecaptcha("login__submit");

    emailIsValid &&
      logIn({ password: decodedPassword, email: email, token: recaptchaToken })
        .then((payload) => {
          if (payload.access) {
            handleSignInSuccess(payload.access, payload.refresh);
          }
        })
        .catch((e) => {
          if (e.response.status === 404) setErrorMessage(e.response.data.email);
        })
        .finally(() => {
          setLoading(false);
        });
  };

  useEffect(() => {
    setEmailIsValid(isValidEmail(email));
  }, [email]);

  useEffect(() => {
    email === "" || password === "" ? setButtonIsDisable(true) : setButtonIsDisable(false);
  }, [email, password]);

  return (
    <LoginContainer>
      <FormContainer loading={loading} submitHandler={submitHandler} buttonText="Login" disableButton={buttonIsDisable}>
        <h1 className="text-center text-3xl font-bold mt-12">Login</h1>
        {errorMessage && emailIsValid && (
          <div className="mt-2 text-error flex">
            <FontAwesomeIcon onClick={() => setShowPassword(!showPassword)} icon={faExclamationTriangle} />
            <div className="ml-2">{errorMessage}</div>
          </div>
        )}
        <form onSubmit={submitHandler} className="mt-8">
          <div>
            <label htmlFor="email" className="text-base ">
              Email*
            </label>
            <input
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              id="email"
              type="text"
              placeholder="adam.smith@example.com"
              className={`border  w-full mt-2 h-11 px-1 outline-0 border-secondary-300 rounded ${
                errorMessage && "border-error"
              }`}
            ></input>
            <div className="text-red-500">{!emailIsValid && loginClicked && "Email is not valid."}</div>
          </div>
          <div className="mt-4">
            <label htmlFor="password" className="text-base">
              Password *
            </label>
            <div className="relative">
              <input
                readOnly={passwordReadOnly}
                onFocus={() => setPasswordReadOnly(false)}
                onBlur={() => setPasswordReadOnly(false)}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type={`${showPassword ? "text" : "password"}`}
                id="password"
                placeholder="password"
                className="border mt-2 p-1 w-full h-11 outline-0  border-secondary-300 rounded"
              ></input>
              <FontAwesomeIcon
                onClick={() => setShowPassword(!showPassword)}
                icon={faEyeSlash}
                className="text-xs cursor-pointer absolute text-secondary-300 translate-y-6 -translate-x-6"
              />
            </div>
          </div>
          <div className="flex justify-between mt-4 flex-wrap">
            <div>
              <Checkbox
                onChange={() => {
                  setRememberIsChecked(!rememberIsChecked);
                }}
                value={rememberIsChecked}
                name="Remember me"
              />
            </div>
            <a href="/forgot-password" className="text-base text-primary cursor-pointer">
              Forgot password
            </a>
          </div>
        </form>
      </FormContainer>
    </LoginContainer>
  );
};

export default Login;
