import { PostTypeFieldType } from "../components/siteSetting/postType/fieldManagement/manageValue/ManagePostTypeFields";
import { PostTypeType, ManagePostTypeType, PostTypeAdditionalContent } from "../components/siteSetting/postType/manageValue/ManagePostType";
import API from "./_config";

// Post Type

//-------------------------------------------------GET

export const getAllPostType = () => {
  return API.get(`post-type/`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};
export const getPostType = (postTypeId: string) => {
  return API.get(`post-type/${postTypeId}/`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};
/// Post Field
export const getPostTypeFields = (postTypeId: string) => {
  return API.get(`post-type/${postTypeId}/post-field/`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

// Post Category

export const getPostTypeCategory = (postTypeId: string) => {
  return API.get(`post-type/${postTypeId}/post-category/`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

//-------------------------------------------------POST

export const updatePostTypeOrder = (row_id: number, oldOrder: number, newOrder: number) => {
  return API.post(`post-type/${row_id}/reorder/`, {
    old_order: oldOrder,
    new_order: newOrder,
  });
};
export const updatePostTypeFieldOrder = (postTypeId: string, row_id: number, oldOrder: number, newOrder: number) => {
  return API.post(`post-type/${postTypeId}/post-field/${row_id}/reorder/`, {
    old_order: oldOrder,
    new_order: newOrder,
  });
};

export const createPostType = (postTypeValue: PostTypeType & PostTypeAdditionalContent) => {
  const body = {
    post_type: {
      name: postTypeValue.name,
      is_active: postTypeValue.isActive,
      show_summary_in_public_header: postTypeValue.showSummaryInPublicHeader,
    },
    post_additional: {
      max_width: postTypeValue.max_width,
      position: postTypeValue.position,
    },
  };

  return API.post(`post-type/`, body)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const createPostTypeFiled = (
  postTypeId: string,
  postTypeValue: {
    name: string;
    field_type: string;
    showFieldInPublicHeader: boolean;
    publicStyle?: {};
    showFieldInNewsletter: boolean;
    newsletterPublicStyle?: {};
  }
) => {
  const body = {
    name: postTypeValue.name,
    field_type: postTypeValue.field_type,
    show_field_in_public_header: postTypeValue.showFieldInPublicHeader,
    show_field_in_newsletter: postTypeValue.showFieldInNewsletter,
    public_style: postTypeValue.publicStyle,
    newsletterPublicStyle: postTypeValue.newsletterPublicStyle,
  };

  return API.post(`post-type/${postTypeId}/post-field/`, body)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

//-------------------------------------------------PUT

export const updatePostType = (postTypeValue: PostTypeType & PostTypeAdditionalContent, inactivate?: boolean) => {
  const body = {
    post_type: {
      name: postTypeValue?.name,
      is_active: postTypeValue?.isActive,
      show_summary_in_public_header: postTypeValue?.showSummaryInPublicHeader,
    },
    post_additional: {
      max_width: postTypeValue?.max_width,
      position: postTypeValue?.position,
    },
  };
  return API.put(`post-type/${postTypeValue.id}/`, body)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};
export const updatePostTypeField = (postTypeId: string, postTypeFieldValue: PostTypeFieldType) => {
  const body = {
    name: postTypeFieldValue?.name,
    field_type: postTypeFieldValue?.fieldType,
    is_active: postTypeFieldValue?.isActive,
    show_field_in_public_header: postTypeFieldValue?.showFieldInPublicHeader,
    public_style: postTypeFieldValue.publicStyle,
    show_field_in_newsletter: postTypeFieldValue?.showFieldInNewsletter,
    newsletterPublicStyle: postTypeFieldValue.newsletterPublicStyle,
  };
  return API.put(`post-type/${postTypeId}/post-field/${postTypeFieldValue.id}/`, body)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

//-------------------------------------------------DELETE

export const deletePostType = (postTypeId: number) => {
  return API.delete(`post-type/${postTypeId}/`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const deletePostTypeField = (postTypeId: string, postTypeFieldId: number) => {
  return API.delete(`post-type/${postTypeId}/post-field/${postTypeFieldId}/`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};
