import React, { useState } from "react";
import SiteThemeContainer from "./SiteThemeContainer";
import { SiteThemeType } from "../../types/types";
import FontAndTextBlock from "./blocks/FontAndTextBlock";

const ManageSiteDefault: React.FC<{
  onCancel: () => void;
  loading: boolean;
  siteThemeStyle: SiteThemeType;
  onSave: (blockStyle: { fontFamily: string; fontWeight: string; textColor: string }) => void;
}> = ({ loading, onCancel, onSave, siteThemeStyle }) => {
  const [styleState, setStyleState] = useState<{ fontFamily: string; fontWeight: string; textColor: string }>({
    fontFamily: siteThemeStyle.fontFamily,
    fontWeight: siteThemeStyle.fontWeight,
    textColor: siteThemeStyle.textColor,
  });
  return (
    <SiteThemeContainer
      loading={loading}
      onCancel={onCancel}
      onSave={() => {
        onSave(styleState);
      }}
      hasLeftBorder
    >
      <FontAndTextBlock
        fontOptions={siteThemeStyle.fontFamilyChoices}
        style={{ fontFamily: siteThemeStyle.fontFamily, fontWeight: siteThemeStyle.fontWeight, textColor: siteThemeStyle.textColor }}
        fontWeightOptions={siteThemeStyle.fontWeightChoices}
        onChange={(value) => {
          setStyleState(value);
        }}
      />
    </SiteThemeContainer>
  );
};

export default ManageSiteDefault;
