import React from "react";

const Option: React.FC<{ label: string; onclick: () => void }> = ({ label, onclick }) => {
  return (
    <li
      onClick={onclick}
      className="cursor-pointer  w-[144px] text-xs font-normal h-8 flex items-center text-body_black hover:bg-secondary-100 px-2 "
    >
      {label}
    </li>
  );
};

export default Option;
