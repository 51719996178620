const SiteThemeDefault = () => {
  let siteThemeImage = require("../../images/siteThemeImage.png");
  return (
    <div className="h-[530px]">
      <img className="h-full" src={siteThemeImage} alt="site theme"></img>
    </div>
  );
};

export default SiteThemeDefault;
