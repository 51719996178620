import React, { useState } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  DraggableProvided,
} from "react-beautiful-dnd";
import { PostTypeFieldType, PostTypeOptionType } from "./ManagePostTypeFields";
import TableRow from "./TableRow";
import Pagination from "./Pagination";

export type Column = {
  Header: string;
  accessor: any;
};

//////////////////////////////////////////////
const Table: React.FC<{
  isDragDisabled: boolean;
  columns: Column[];
  data: PostTypeFieldType[];
  setShowOptionsMenu: (state: {
    dictValue?: PostTypeFieldType;
    showModal: boolean;
  }) => void;
  showOptionsMenu: {
    dictValue?: PostTypeFieldType;
    showModal: boolean;
  };
  onOptionClick: (
    dictValue: PostTypeFieldType,
    selectedValue: PostTypeOptionType
  ) => void;
  updateData: (result: DropResult, pageIndex: number, pageSize: number) => void;
  update_order: (id: number, _oldOrder: number, _newOrder: number) => void;
  droppableId: string;
}> = ({
  isDragDisabled,
  columns,
  data,
  setShowOptionsMenu,
  showOptionsMenu,
  onOptionClick,
  updateData,
  update_order,
  droppableId,
}) => {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    // @ts-ignore
    page, // Instead of using 'rows', we'll use page,

    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    // @ts-ignore
    canPreviousPage,
    // @ts-ignore
    canNextPage,
    // @ts-ignore
    pageCount,
    // @ts-ignore
    gotoPage,
    // @ts-ignore
    nextPage,
    // @ts-ignore
    previousPage,
    // @ts-ignore
    setPageSize,
    state,
  } = useTable(
    {
      columns,
      data,
      // @ts-ignore
      autoResetPage: false,
    },

    useSortBy,
    usePagination
  );
  // @ts-ignore
  const { pageIndex, pageSize } = state;
  const [rowId, setRowId] = useState(0);

  const onDragEndHandler = (result: DropResult) => {
    if (!result.destination) return;

    update_order(
      rowId,
      pageIndex * pageSize + result.source.index,
      pageIndex * pageSize + result.destination.index
    );

    updateData(result, pageIndex, pageSize);
  };

  return (
    <>
      {/* table */}
      <div className="relative z-0 w-full mx-auto overflow-auto">
        <div>
          <DragDropContext onDragEnd={onDragEndHandler}>
            <Droppable droppableId={droppableId}>
              {(provided) => (
                <table
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  {...getTableProps()}
                  className="border-collapse w-full  "
                >
                  <thead className="bg-secondary-200  rounded  overflow-hidden">
                    {headerGroups.map((headerGroup) => (
                      <tr
                        {...headerGroup.getHeaderGroupProps()}
                        className="border-b border-gray-200 "
                      >
                        {headerGroup.headers.map((column, index) => (
                          // Add the sorting props to control sorting. For this example
                          // we can add them into the header props

                          <th
                            scope="col"
                            className={`border-b td-firefox border-secondary-200 table__cell sticky top-0 z-10 text-sm font-normal  text-gray py-[12.3px]  tracking-wider px-4 bg-secondary-200 ${
                              index === 0 ? "left-0 z-20 " : "left-0 z-10"
                            }`}
                          >
                            {column.render("Header") !== " " && (
                              <div className="flex w-fit items-center">
                                <h3 className="text-sm font-bold text-black41">
                                  {column.render("Header")}
                                </h3>
                              </div>
                            )}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {
                      // @ts-ignore
                      page.map((row, i) => (
                        <Draggable
                          isDragDisabled={isDragDisabled}
                          key={row.id}
                          draggableId={String(row.id)}
                          index={i}
                        >
                          {(_provided: DraggableProvided) => (
                            <TableRow
                              row={row}
                              key={i}
                              setShowOptionsMenu={setShowOptionsMenu}
                              showOptionsMenu={showOptionsMenu}
                              onOptionClick={onOptionClick}
                              rowHandler={(x) => setRowId(x)}
                              provided={_provided}
                            />
                          )}
                        </Draggable>
                      ))
                    }
                  </tbody>
                </table>
              )}
            </Droppable>
          </DragDropContext>
        </div>
        <div className="mb-4  w-full">
          <Pagination
            previousPage={previousPage}
            nextPage={nextPage}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            setPageSize={setPageSize}
            gotoPage={gotoPage}
            pageCount={pageCount}
            // @ts-ignore
            pageIndex={state.pageIndex}
            // @ts-ignore
            pageSize={state.pageSize}
            pageLength={page.length}
            totalLength={data.length}
          />
        </div>
      </div>
    </>
  );
};

export default Table;
