import React, { useEffect, useState } from "react";
import { OptionType } from "../../../utils/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/pro-regular-svg-icons";
import Row from "./Row";

const CheckBoxInput: React.FC<{
  label: string;
  options: OptionType[];
  borderSideArray: string[] | undefined;
  onChange: (input: string[]) => void;
}> = ({ label, options, onChange, borderSideArray }) => {
  const [showOptions, setShowOptions] = useState(false);
  const [selectedBorderSides, setSelectedBorderSides] = useState(borderSideArray);

  const borderSideSelectorHandler = (input: { state: string; status: boolean }) => {
    if (input.status) {
      selectedBorderSides &&
        !selectedBorderSides.includes(input.state) &&
        setSelectedBorderSides([...selectedBorderSides, input.state]);
    } else {
      selectedBorderSides && setSelectedBorderSides(selectedBorderSides.filter((item) => item !== input.state));
    }
  };

  useEffect(() => {
    selectedBorderSides && onChange(selectedBorderSides);
  }, [selectedBorderSides]);

  return (
    <div className="">
      <label className="text-sm font-light text-black2B">{`${label} `}</label>
      <div
        tabIndex={0}
        onClick={() => setShowOptions(true)}
        onBlur={() => setShowOptions(false)}
        className="w-full px-4 py-[14px] rounded-[5px] border border-secondary-300 cursor-pointer bg-white text-[13px] text-black2B font-light min-h-[50px] outline-none hover:border-primary mt-1 relative flex justify-between"
      >
        <div>
          {selectedBorderSides &&
            selectedBorderSides.map((option, i) => (
              <span key={i}>
                {i !== 0 && <span>,</span>}
                <span>{options.find((input) => input.key === option)?.label}</span>
              </span>
            ))}
        </div>
        <div>
          <FontAwesomeIcon
            icon={faAngleDown}
            className={`absolute right-4 top-1/2 -mt-2 text-secondary-300 duration-300 ${
              showOptions ? "rotate-180" : "rotate-0"
            }`}
          />
        </div>
        {showOptions && (
          <ul className="absolute top-full w-full mt-1 border border-secondary-300 rounded-[5px] max-h-36 overflow-y-scroll z-10 left-0 bg-white">
            {options.map((option, i) => (
              <Row
                option={option}
                key={i}
                onChange={borderSideSelectorHandler}
                selectedBorderSides={selectedBorderSides}
              />
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default CheckBoxInput;
