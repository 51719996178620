import React from "react";

const InUsedBox: React.FC<{ isUsed: string }> = ({ isUsed }) => {
  let isUsedBox: JSX.Element;
  switch (isUsed) {
    case "true":
      isUsedBox = <span className=" flex bg-green items-center justify-center rounded-r text-white text-xs h-[28px]">In Use</span>;
      break;
    case "false":
      isUsedBox = <span className="bg-[#E55D63] flex items-center justify-center h-[28px] text-white text-xs rounded-r">Not Used</span>;
      break;
    default:
      isUsedBox = <p></p>;
  }
  return <div className="w-[66px] ">{isUsedBox}</div>;
};

export default InUsedBox;
