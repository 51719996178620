import React from "react";
import ThemeMenu from "./ThemeMenu";
import { ThemeMenuType } from "../../types/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/pro-light-svg-icons";


const LeftMenuSIteTheme: React.FC<{
  menuArray: ThemeMenuType[];
  setSelectedMenu: (index: string) => void;
  openOrClose: () => void;
  openOrCloseState: boolean;
}> = ({ menuArray, setSelectedMenu, openOrClose, openOrCloseState }) => {
  return (
    <div className={`h-[calc(100%-50px)] bg-white top-[186px] fixed z-30 flex duration-500 ${openOrCloseState && "-translate-x-[255px]"}`}>
      <div className="pt-6 pl-4">
        <ul>
          {menuArray.map((menu, i) => (
            <li onClick={() => setSelectedMenu(menu.title)} key={i}>
              <ThemeMenu menu={menu} />
            </li>
          ))}
        </ul>
      </div>
      <div
        onClick={() => {
          openOrClose();
        }}
        className="p-2 h-8 rounded relative top-[75px]  bg-primary ml-1 cursor-pointer"
      >
        <FontAwesomeIcon
          icon={faArrowLeft}
          className={`text-base "text-white" text-white cursor-pointer -translate-y-1 ${openOrCloseState && "rotate-180"}`}
        />
      </div>
    </div>
  );
};

export default LeftMenuSIteTheme;
