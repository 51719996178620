import Button, { ButtonType } from "../Button";
import InputField from "../inputs/InputField";
import PageContainer from "../layout/PageContainer";
import { createPageOrPopup, getContent, updateContent, getContentRelatedLanguages } from "../../api/content";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { StatusBoxType } from "../../types/types";
import ContentSettingsHeader, { ContentMode } from "./ContentSettingsHeader";
import LanguageBox, { DEFAULT_LANGUGAE } from "../LanguageBox";
import { DropdownItem } from "../SearchableDropDown";
import MultiSelectCheckBox from "../MultiSelectCheckBox";
import Loading, { LoadingMode } from "../Loading";
import AreYouSureModal from "./AreYouSureModal";
import Dropdown from "../inputs/Dropdown";
import FileInput from "../inputs/FileInput";
import { ImageValueType, OptionType } from "../../utils/types";
import LanguageSelectionBox from "./LanguageSelectionBox";
import { Option } from "../inputs/multiCheckBoxLanguage/MultiCheckBoxLanguages";

const DEFAULT_WIDTH = "10";

export interface ContentData {
  id: string;
  slug: { [key: string]: string | null };
  title: { [key: string]: string | null };
  maxWidth: string;
  description?: { [key: string]: string | null };
  metaRobots?: { [key: string]: string | null };
  status?: StatusBoxType;
  type?: string;
  relatedContent?: string;
  languages?: Array<string>;

  /** Below states noly used when content is Post */
  postType?: number;
  summaryImage: number | null;
}

type ContentType = {
  key: "PA" | "PO" | "PS";
  title: string;
};

export default function ManageContent() {
  const { contentId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const relatedContentId = location.state?.relatedContentId;

  /** Detecting current page mode */
  const isNormalAddMode = contentId !== undefined && contentId === "0" && !relatedContentId;
  const isTranslationMode = Boolean(relatedContentId);
  const isTranslationAddMode = isTranslationMode && contentId === "0";
  const isAddMode = isNormalAddMode || isTranslationAddMode;

  if (contentId && contentId === "2") {
    navigate(`/content/2/blocks/`, { replace: true });
  }

  const determineContentType = (key: string) => {
    switch (key) {
      case "PA":
      case "Page":
        return { key: "PA", title: "Page" } as ContentType;
      case "PO":
      case "Popup":
        return { key: "PO", title: "Popup" } as ContentType;
      case "PS":
      case "Post":
        return { key: "PS", title: "Post" } as ContentType;
      default:
        return { key: "PA", title: "Page" } as ContentType;
    }
  };

  const [allLanguages, setAllLanguages] = useState<Array<DropdownItem>>([]);
  const [selectedLanguages, setSelectedLanguages] = useState<Array<string>>([]);
  const [defaultSelectedLanguages, setDefaultSelectedLanguages] = useState<Array<String>>([]);
  const [defaultLanguage, setDefaultLanguage] = useState(DEFAULT_LANGUGAE);
  const [language, setLanguage] = useState<string>(DEFAULT_LANGUGAE);
  const [showLoading, setShowLoading] = useState(!isAddMode);
  const [contentType, setContentType] = useState(determineContentType(location.state?.type));

  const [titleError, setTitleError] = useState<string | null>(null);
  const [slugError, setSlugError] = useState<string | null>(null);
  const [languageError, setLanguageError] = useState<string | null>(null);
  const [postTypeErr, setPostTypeErr] = useState<string | null>(null);

  const [dataIsChanged, setDataIsChanged] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [coverImage, setCoverImage] = useState<ImageValueType | null>(null);
  const [allPostTypes, setAllPostTypes] = useState<Array<OptionType>>([]);
  const [loading, setLoading] = useState(false);

  const defaultContentData: ContentData = {
    id: "",
    slug: {},
    title: {},
    description: {},
    metaRobots: {},
    summaryImage: null,
    maxWidth: DEFAULT_WIDTH,
    relatedContent: isTranslationMode ? relatedContentId : undefined,
  };
  const [contentData, setContentData] = useState<ContentData>(defaultContentData);

  useEffect(() => {
    if (!isAddMode) {
      getContent(contentId!)
        .then((res) => {
          res.data.summaryImage ? setContentData({ ...res.data, summaryImage: res.data.summaryImage.id }) : setContentData(res.data);
          setContentType(determineContentType(res.data.type));
          setCoverImage(res.data.summaryImage);
          setDefaultSelectedLanguages(res.data.languages);
          if (!isTranslationAddMode) {
            setSelectedLanguages(res.data.languages);
          }

          setShowLoading(false);
        })
        .catch((e) => {
          setShowLoading(false);
          if (e.response.status === 404) {
            navigate("/404");
          }
        });
    }
  }, []);

  useEffect(() => {
    getContentRelatedLanguages(isTranslationMode, isTranslationAddMode ? relatedContentId : contentId, contentType.key).then((res) => {
      const postTypes: Array<DropdownItem> = res.data.postType;
      if (postTypes) {
        const result: Array<OptionType> = [];
        postTypes.forEach((postType) => {
          result.push({ key: postType.value, label: postType.label });
        });

        setAllPostTypes(result);
      }

      setAllLanguages(res.data.languages);
      if (!isTranslationMode) {
        const defaultLang = res.data.defaultLanguage;
        setDefaultLanguage(defaultLang);
        setLanguage(defaultLang);

        if (isAddMode) {
          setSelectedLanguages([defaultLang]);
          setContentData({ ...contentData, languages: [defaultLang] });
        }
        // else {
        //   setSelectedLanguages(res.data.languages.map((lng: any) => lng.value));
        // }
      }
    });
  }, [contentType]);

  useEffect(() => {
    setContentData({ ...contentData, summaryImage: coverImage ? coverImage.id : null });
  }, [coverImage]);

  const contentCreatedSuccessfully = (newContentId: string) => {
    setLoading(false);
    if (isAddMode) {
      navigate(`/content/${newContentId}/blocks/`, { replace: true });
    } else {
      navigate("/content");
    }
  };

  const handleApiError = (err: any) => {
    setShowWarningModal(false);

    const statusCode = err.response.status;
    switch (statusCode) {
      case 400:
        setSlugError("Slug already exists");
        break;
    }
  };

  const allFieldsAreValid = () => {
    let AllFieldsAreOK = true;

    let allTitlesAreFilled = true;

    const languages = [...selectedLanguages];

    languages.forEach((lng) => {
      if (!contentData.title[lng]) {
        allTitlesAreFilled = false;
      }
    });

    if (!allTitlesAreFilled) {
      setTitleError("The title of all languages is required!");
      AllFieldsAreOK = false;
    }

    if (!isTranslationMode && !contentData.slug[defaultLanguage]) {
      setSlugError("The slug is required for default language!");
      AllFieldsAreOK = false;
    }

    if (isTranslationMode && selectedLanguages.length === 0) {
      setLanguageError("Required Field!");
      AllFieldsAreOK = false;
    }

    if (contentType.key === "PS" && !contentData.postType) {
      setPostTypeErr("Required Field!");
      AllFieldsAreOK = false;
    }

    return AllFieldsAreOK;
  };

  /** Get title depends on selected language */
  const getTargetTitle = () => {
    return contentData.title ? contentData.title[language.toLowerCase()] ?? "" : "";
  };

  const getDefaultSelectedLanguages = () => {
    const res: Array<string> = [];

    selectedLanguages.forEach((lngStr) => {
      const lng = allLanguages.find((lngDict) => lngStr === lngDict.value);
      if (lng) res.push(lng.label);
    });

    return res;
  };

  const saveTheChanges = () => {
    if (isAddMode) {
      createPageOrPopup(contentData, contentType.key)
        .then((res) => contentCreatedSuccessfully(res.id))
        .catch(handleApiError);
    } else {
      updateContent(contentId!, contentData)
        .then((res) => contentCreatedSuccessfully(res.id))
        .catch(handleApiError);
    }
  };

  const handleSelectableLanguages = () => {
    return allLanguages.map((lng) => {
      return { value: lng.label, isReadOnly: isTranslationMode ? false : lng.value === defaultLanguage };
    });
  };

  const languageSelectorHandler = (input: { lang: string; status: boolean }) => {
    let coppiedArray: String[] = [];
    if (input.status) {
      if (!selectedLanguages.includes(input.lang)) {
        setSelectedLanguages([...selectedLanguages, input.lang]);
      }
      coppiedArray = [...selectedLanguages, input.lang];
    } else {
      setSelectedLanguages(selectedLanguages.filter((item) => item !== input.lang));
      coppiedArray = [...selectedLanguages.filter((item) => item !== input.lang)];
    }
    // checher value check if page removed or not
    const checker = defaultSelectedLanguages.every((lang) => coppiedArray.includes(lang));
    !checker && setDataIsChanged(true);
  };

  useEffect(() => {
    // this line is about LanguageSelectionBox
    setContentData({ ...contentData, languages: selectedLanguages });
    if (selectedLanguages.length === 0) {
      setLanguage("");
    }
  }, [selectedLanguages]);

  return (
    <PageContainer>
      <div className="flex flex-col">
        <ContentSettingsHeader
          headerTitle="Manage Content Settings"
          contentId={contentId!}
          contentTitle={isNormalAddMode ? `New ${contentType.title}` : getTargetTitle()}
          contentType={contentType.title}
          contentMode={ContentMode.ManagePage}
          contentStatus={contentData.status!}
          isAddMode={isAddMode}
          saveBlocksBeforeNavigating={async () => {
            if (allFieldsAreValid()) {
              return updateContent(contentId!, contentData)
                .then(() => Promise.resolve(true))
                .catch((err) => {
                  handleApiError(err);
                  return Promise.resolve(false);
                });
            } else {
              return Promise.resolve(false);
            }
          }}
        />
        <div className="w-full h-[8px] bg-secondary-100 mt-6" />

        <div className="flex flex-col mt-6 mx-12 mb-12 bg-secondary-100 pt-4 px-6 pb-6 rounded-[5px] border-[#C4C4C4]">
          {showLoading ? (
            <div className="flex items-center justify-center">
              <Loading loadingMode={LoadingMode.Normal} />
            </div>
          ) : (
            <div className="flex flex-col">
              <label className="text-black2B font-medium mt-1">General Settings</label>

              <div className="flex mt-6">
                <div className="flex-1 mt-1">
                  <label className="text-sm font-light text-black2B">Languages</label>
                  <div className="mt-2">
                    <LanguageSelectionBox
                      languages={allLanguages as Option[]}
                      onChange={(input) => {
                        languageSelectorHandler(input);
                        setLanguageError(null);
                      }}
                      selectedLanguages={selectedLanguages}
                      defaultLanguage={defaultLanguage}
                    />
                  </div>

                  {/* <MultiSelectCheckBox
                    containerClassName="w-full"
                    options={handleSelectableLanguages()}
                    title="Languages"
                    required={isTranslationMode}
                    defaultSelected={getDefaultSelectedLanguages()}
                    onSelectedOptions={(options) => {
                      const finalLanguages: Array<string> = [];
                      
                      options?.forEach((lngStr) => {
                        const langObj = allLanguages.find((language) => language.label === lngStr);
                        if (langObj) finalLanguages.push(langObj.value as string);
                      });

                      setSelectedLanguages(finalLanguages);
                      // options.length < selectedLanguages.length means the previously selected language is deselected Now!
                      if (options) setDataIsChanged(options.length < selectedLanguages.length);
                     
                      setContentData({ ...contentData, languages: finalLanguages });

                      if (finalLanguages.length === 0) {
                        setLanguage("");
                      }
                      setLanguageError(null);
                    }}
                  /> */}

                  <label className="text-red-400 text-sm">{languageError}</label>
                </div>
              </div>

              <div className="flex items-center mt-6">
                <LanguageBox languagesArr={selectedLanguages} defaultLang={defaultLanguage} onChange={(lng) => setLanguage(lng)} />
              </div>

              <div className="flex mt-6">
                <div className="flex-1 mr-6">
                  <InputField
                    label="Title"
                    required={true}
                    value={getTargetTitle()}
                    type="T"
                    onChange={(value) => {
                      setTitleError(null);

                      const newTitleValue = contentData.title;
                      newTitleValue[language] = value;

                      setContentData({ ...contentData, title: newTitleValue });
                    }}
                    placeholder={`Your page title ( ${language.toUpperCase()} )`}
                  />

                  <label className="text-red-400 text-sm">{titleError}</label>
                </div>

                <div className="flex-1">
                  <InputField
                    label="Slug"
                    required={!isTranslationMode && language === defaultLanguage}
                    value={contentData.slug ? contentData.slug[language.toLowerCase()] ?? "" : ""}
                    type="T"
                    onChange={(value) => {
                      setSlugError(null);
                      const newSlugValue = contentData.slug;
                      newSlugValue[language] = value;

                      setContentData({ ...contentData, slug: newSlugValue });
                    }}
                    placeholder={`page-url/slug ( ${language.toUpperCase()} )`}
                  />

                  <label className="text-red-400 text-sm">{slugError}</label>
                </div>
              </div>

              {contentType.key === "PS" && (
                <div className="flex flex-col mt-6">
                  <div className="flex">
                    <div className="flex flex-1 flex-col mr-6">
                      <div className="flex flex-1">
                        <Dropdown
                          label="Post Type"
                          required={true}
                          className="flex-1"
                          options={allPostTypes}
                          onOptionSelect={(option) => {
                            setContentData({ ...contentData, postType: option.key as number });
                            setPostTypeErr(null);
                          }}
                          selectedOption={allPostTypes?.find((type) => type.key === contentData.postType) ?? null}
                          placeholder="Please Specify"
                        />
                      </div>
                      <label className="text-red-400 text-sm mt-1 ml-1">{postTypeErr}</label>

                      <div className="flex flex-col flex-1 mt-6">
                        <div className="font-light mt-2">Cover Image</div>
                        <FileInput
                          inputContainerCustomStyle={{}}
                          label=""
                          value={coverImage}
                          onFileUpload={(newImage) => setCoverImage(newImage)}
                          onTrashClick={() => setCoverImage(null)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {contentType.key === "PO" ? (
                <div className="flex flex-col mt-6">
                  <InputField
                    className="flex-1"
                    label="Width"
                    required={true}
                    value={contentData.maxWidth}
                    type="N"
                    min={Number(DEFAULT_WIDTH)}
                    onChange={(value) => {
                      setContentData({ ...contentData, maxWidth: value ?? DEFAULT_WIDTH });
                    }}
                    placeholder="Width in px"
                  />
                </div>
              ) : (
                <div>
                  <InputField
                    className="flex-1 mt-6"
                    label="Description"
                    isTextArea={true}
                    textAreaCustomHeight={132}
                    value={contentData.description ? contentData.description[language.toLowerCase()] ?? "" : ""}
                    type="T"
                    onChange={(value) => {
                      const newDescriptionValue = contentData.description;
                      if (newDescriptionValue) newDescriptionValue[language] = value;

                      setContentData({ ...contentData, description: newDescriptionValue });
                    }}
                    placeholder={`For SEO purposes ( ${language.toUpperCase()} )`}
                  />

                  <InputField
                    className="flex-1 mt-6"
                    label="Meta Robots"
                    value={contentData.metaRobots ? contentData.metaRobots[language.toLowerCase()] ?? "" : ""}
                    type="T"
                    onChange={(value) => {
                      const newMetaRobotValue = contentData.metaRobots;
                      if (newMetaRobotValue) newMetaRobotValue[language] = value;

                      setContentData({ ...contentData, metaRobots: newMetaRobotValue });
                    }}
                    placeholder={`For SEO purposes ( ${language.toUpperCase()} )`}
                  />
                </div>
              )}

              <div className="flex mt-10 self-end">
                <Button className="mr-4" type={ButtonType.SECONDARY} onClick={() => navigate("/content")}>
                  Cancel
                </Button>
                <Button
                  showLoading={loading}
                  type={ButtonType.PRIMARY}
                  onClick={() => {
                    if (allFieldsAreValid()) {
                      if (!isTranslationAddMode && dataIsChanged) {
                        setShowWarningModal(true);
                      } else {
                        setLoading(true);
                        saveTheChanges();
                      }
                    }
                  }}
                >
                  Save
                </Button>
              </div>

              {showWarningModal && (
                <AreYouSureModal
                  showModal={showWarningModal}
                  title="Are you sure?"
                  description="Your old data will be lost after saving. Do yo continue?"
                  positiveButtonTitle="Save"
                  negativeButtonTitle="Cancel"
                  onPositiveClicked={() => saveTheChanges()}
                  onNegativeClicked={() => setShowWarningModal(false)}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </PageContainer>
  );
}
