import React, { useState } from "react";
import BlockBox from "./BlockBox";
import InputField from "../../inputs/InputField";
import { ShadowType } from "../../../types/types";

const ShadowBlock: React.FC<{
  shadowStyle: ShadowType;
  className?: string;
  onChange: (shadow: ShadowType) => void;
}> = ({ shadowStyle, className, onChange }) => {
  const [shadowStyleState, setShadowStyleState] = useState(shadowStyle);

  return (
    <BlockBox className={className} title="Shadow">
      <div className="flex justify-between flex-wrap">
        <InputField
          type="N"
          value={shadowStyleState.x ? shadowStyleState.x : ""}
          onChange={(value) => {
            setShadowStyleState({ ...shadowStyleState, x: value });
            onChange({ ...shadowStyleState, x: value });
          }}
          label="X (pixel)"
          className="w-[234px]"
        />
        <InputField
          type="N"
          value={shadowStyleState.y ? shadowStyleState.y : ""}
          onChange={(value) => {
            setShadowStyleState({ ...shadowStyleState, y: value });
            onChange({ ...shadowStyleState, y: value });
          }}
          label="Y (pixel)"
          className="w-[234px]"
        />
        <InputField
          type="N"
          value={shadowStyleState.blur ? shadowStyleState.blur : ""}
          onChange={(value) => {
            setShadowStyleState({ ...shadowStyleState, blur: value });
            onChange({ ...shadowStyleState, blur: value });
          }}
          label="Blur (pixel)"
          className="w-[234px]"
        />
      </div>
    </BlockBox>
  );
};

export default ShadowBlock;
