import { useEffect, useRef, useState } from "react";
import ContentEditable from "react-contenteditable";

import {
  IconDefinition,
  faItalic,
  faBold,
  faCheck,
  faAlignCenter,
  faAlignRight,
  faAlignLeft,
  faStrikethrough,
  faTextSize,
  faTextHeight,
  faPalette,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SketchPicker } from "react-color";
import Button, { ButtonType } from "../Button";

interface CmsTableCellInputProps {
  index: number;
  value: string;
  onValueChanged: (newValue: string) => void;
}

export default function CmsTableCellInput(props: CmsTableCellInputProps) {
  const [inputHasFocus, setInputHasFocus] = useState(false);
  const [colorPickerIsShowing, setColorPickerIsShowing] = useState(false);
  const contentEditableRef = useRef<any>(null);

  const savedRange = useRef<Range | null>(null);

  function saveSelection(): Range | null {
    if (window?.getSelection) {
      const sel = window.getSelection();
      if (sel?.rangeCount && sel.getRangeAt) {
        return sel.getRangeAt(0);
      }
    }
    return null;
  }

  function restoreSelection(range: Range | null) {
    if (range) {
      if (window?.getSelection) {
        const sel = window.getSelection();
        if (sel) {
          sel.removeAllRanges();
          sel.addRange(range);
        }
      }
    }
  }

  const handleSaveSelection = () => {
    savedRange.current = saveSelection();
  };

  const handleRestoreSelection = () => {
    restoreSelection(savedRange.current);
  };

  useEffect(() => {
    if (!colorPickerIsShowing) {
      handleRestoreSelection();
    }
  }, [colorPickerIsShowing]);

  useEffect(() => {
    if (inputHasFocus) {
      const toBeRemovedElements = document.querySelectorAll(".cke");
      toBeRemovedElements.forEach((toBeRemovedElement) => {
        if (toBeRemovedElement.id !== "cke_editor1") {
          toBeRemovedElement.remove();
        }
      });
    }
  }, [inputHasFocus]);

  return (
    <div className="relative flex w-full h-auto flex-col items-center">
      <ContentEditable
        innerRef={contentEditableRef}
        id={props.index.toString()}
        onFocus={() => {
          setInputHasFocus(true);
          handleSaveSelection();
        }}
        onMouseUp={() => handleSaveSelection()}
        onBlur={() => setInputHasFocus(false)}
        html={props.value ?? ""}
        className="flex-1 w-full text-center px-2 outline-none"
        onChange={(event) => {
          props.onValueChanged(event.target.value);
        }}
      />
      {(inputHasFocus || colorPickerIsShowing) && (
        <div
          className="absolute w-[400px] border-primary border-2 rounded-md bg-secondary-400 bottom-16 left-0 right-0 mx-auto"
          style={{ zIndex: "10000" }}
        >
          <InputActions
            inputId={props.index}
            contentEditableRef={contentEditableRef}
            colorPickerShowingListener={(isShowing) => setColorPickerIsShowing(isShowing)}
            colorChanged={(newColor) => {
              handleRestoreSelection();
              document.execCommand("foreColor", false, newColor);
            }}
          />
        </div>
      )}
    </div>
  );
}

interface InputActionsProps {
  inputId: number;
  contentEditableRef: React.MutableRefObject<HTMLElement | null>;
  colorPickerShowingListener: (isShowing: boolean) => void;
  colorChanged: (newColor: string) => void;
}

export function InputActions(props: InputActionsProps) {
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [currentColor, setCurrentColor] = useState("#ffffff");

  const DEFAULT_TEXT_COLOR = "#000000";

  useEffect(() => {
    props.colorPickerShowingListener(showColorPicker);
  }, [showColorPicker]);

  interface ActionButton {
    cmd: string;
    fontIcon: IconDefinition;
    argument?: string;
  }

  const actionButtons: Array<ActionButton> = [
    {
      cmd: "italic",
      fontIcon: faItalic,
    },
    {
      cmd: "bold",
      fontIcon: faBold,
    },
    {
      cmd: "selectAll",
      fontIcon: faCheck,
    },
    {
      cmd: "justifyLeft",
      fontIcon: faAlignLeft,
    },
    {
      cmd: "justifyCenter",
      fontIcon: faAlignCenter,
    },
    {
      cmd: "justifyRight",
      fontIcon: faAlignRight,
    },
    {
      cmd: "strikethrough",
      fontIcon: faStrikethrough,
    },
    {
      cmd: "fontSize",
      fontIcon: faTextHeight,
      argument: "15",
    },
    {
      cmd: "fontSize",
      fontIcon: faTextSize,
      argument: "3",
    },
    {
      cmd: "foreColor",
      fontIcon: faPalette,
      argument: DEFAULT_TEXT_COLOR,
    },
  ];

  const RGBAToHex = (r: number, g: number, b: number) => {
    let rHex = r.toString(16);
    let gHex = g.toString(16);
    let bHex = b.toString(16);

    if (rHex.length === 1) rHex = "0" + rHex;
    if (gHex.length === 1) gHex = "0" + gHex;
    if (bHex.length === 1) bHex = "0" + bHex;

    return `#${rHex}${gHex}${bHex}`;
  };

  return (
    <div className="flex relative justify-evenly py-1">
      {actionButtons.map((btn, index) => {
        return (
          <button
            className="border-primary border-2 p-2 rounded-lg bg-white"
            key={index}
            onMouseDown={(evt) => {
              evt.preventDefault(); // Avoids loosing focus from the editable area
              if (btn.cmd === "foreColor") {
                setShowColorPicker(!showColorPicker);
              } else {
                document.execCommand(btn.cmd, false, btn.argument); // Send the command to the browser
              }
            }}
          >
            <FontAwesomeIcon icon={btn.fontIcon} width={16} height={12} />
          </button>
        );
      })}
      {showColorPicker && (
        <div className="flex absolute items-center flex-col top-16 left-2/3">
          <SketchPicker
            color={currentColor}
            onChange={(_color) => {
              const finalColor = RGBAToHex(_color.rgb.r, _color.rgb.g, _color.rgb.b);
              setCurrentColor(finalColor);
            }}
            disableAlpha
          />
          <div className="flex w-full justify-evenly">
            <Button
              className="mt-2 mb-2"
              type={ButtonType.PRIMARY}
              onClick={() => {
                setCurrentColor(DEFAULT_TEXT_COLOR);
                document.execCommand("foreColor", false, DEFAULT_TEXT_COLOR);
                props.colorChanged(DEFAULT_TEXT_COLOR);
                setShowColorPicker(false);
              }}
            >
              Clear
            </Button>
            <Button
              className="mt-2 mb-2"
              type={ButtonType.PRIMARY}
              onClick={() => {
                props.colorChanged(currentColor);
                setShowColorPicker(false);
              }}
            >
              Submit
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
