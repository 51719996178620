import React from "react";

const Title: React.FC<{
  className: string;

  title: string;
  button: JSX.Element;
}> = ({ className, title, button }) => {
  return (
    <div className={`sm:flex justify-between ${className}`}>
      <h3 className="flex items-center">{title}</h3>
      <div className="sm:flex">{button}</div>
    </div>
  );
};

export default Title;
