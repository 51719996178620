import React from "react";

const MenuItem: React.FC<{
  titleKey: string;
  onClick: () => void;
  onBlur?: () => void;
  className?: string;
  children?: React.ReactNode;
  disable?: boolean;
}> = ({ titleKey, onClick, children, className, disable, onBlur }) => {
  return (
    <li
      // tabIndex={0}
      className={`hover:cursor-pointer text-[13px] font-light hover:bg-Gold_300 px-4 ${className} ${disable && "text-gray-300"}`}
      onClick={() => {
        onClick();
      }}
      onBlur={onBlur}
    >
      {children}
      <p>{titleKey}</p>
    </li>
  );
};

export default MenuItem;
