import { useState } from "react";
import CmsModalMainShape from "../../CmsModalMainShape";
import InputField from "../../inputs/InputField";

interface Props {
  dictName?: string;
  onSaved: (dictName: string) => void;
  onClosed: () => void;
}

export default function AddOrRenameDictionaryModal(props: Props) {
  const [name, SetName] = useState<string>(props.dictName ?? "");
  const isEditMode = Boolean(props.dictName);

  return (
    <CmsModalMainShape
      title={"Add/Rename Dictionary"}
      positiveBtnTitle={isEditMode ? "Save" : "Save & Manage Values"}
      showModal={true}
      onNegativeBtnClick={props.onClosed}
      onPositiveBtnClick={() => props.onSaved(name)}
      rootStyle={{ width: 490 }}
    >
      <InputField
        label="Name"
        required={true}
        value={name}
        className="mt-6"
        type="T"
        onChange={(value) => SetName(value ?? "")}
        placeholder={`Country List`}
      />
    </CmsModalMainShape>
  );
}
