import { Link, useNavigate } from "react-router-dom";
import SearchInput from "../../inputs/SearchInput";
import Button, { ButtonType } from "../../Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSortDown } from "@fortawesome/pro-solid-svg-icons";
import { faEllipsisV } from "@fortawesome/pro-regular-svg-icons";
import { useEffect, useState } from "react";
import { getAllDictionaries, createNewDictionary, updateNewDictionary } from "../../../api/data_dictionary";
import AddOrRenameDictionaryModal from "./AddOrRenameDictionaryModal";
import MenuItem from "../../MenuItem";
import { ManageValueAndPostCategoryType } from "./manageValue/ManageValueAndPostCategory";
import PageContainer from "../../layout/PageContainer";

export type SortConfig = {
  columnName: string;
  sortType: "Ascending" | "Descending";
};

type DataDict = {
  id: number;
  name: string;
  activeValueCount: number;
};

const columns = ["Data Dictionary", "No. of Active Values", ""];

export default function DataDictionaryHub() {
  const [allDictionaries, setAllDictionaries] = useState<Array<DataDict>>([]);
  const [showingDictionaries, setShowingDictionaries] = useState<Array<DataDict>>([]);
  const [searchPhrase, setSearchPhrase] = useState<string | null>(null);
  const [showAddOrRenameModal, setShowAddOrRenameModal] = useState<{ name?: string; showModal: boolean }>({
    showModal: false,
  });
  const [showOptionsMenu, setShowOptionsMenu] = useState<{ dict?: DataDict; showModal: boolean }>({
    showModal: false,
  });
  const [sortConfig, setSortConfig] = useState<SortConfig | null>(null);

  const navigate = useNavigate();

  useEffect(() => {
    getAllDicts();
  }, []);

  useEffect(() => {
    search();
  }, [searchPhrase]);

  useEffect(() => {
    setShowingDictionaries(sort());
  }, [sortConfig]);

  const getAllDicts = () => {
    getAllDictionaries().then((res) => {
      setAllDictionaries(res.data);
      setShowingDictionaries(res.data);
    });
  };

  const search = () => {
    setSortConfig(null);

    if (searchPhrase) {
      const targetData = allDictionaries.filter((dict) => dict.name.toLowerCase().includes(searchPhrase?.toLowerCase()));
      setShowingDictionaries(targetData);
    } else {
      setShowingDictionaries(allDictionaries);
    }
  };

  const sort = () => {
    const data = [...showingDictionaries];
    const sortedData = data.sort((itemOne, itemTwo) => {
      if (sortConfig?.columnName === columns[0]) {
        return sortConfig?.sortType === "Ascending" ? itemOne.name.localeCompare(itemTwo.name) : itemTwo.name.localeCompare(itemOne.name);
      } else {
        return sortConfig?.sortType === "Ascending"
          ? itemTwo.activeValueCount - itemOne.activeValueCount
          : itemOne.activeValueCount - itemTwo.activeValueCount;
      }
    });

    return sortedData;
  };

  const handleBreadcrumb = () => {
    return (
      <header>
        <div className="flex text-black font-medium mt-10">
          <Link to="/content" className={`font-light cursor-default`} onClick={(event) => event.preventDefault()}>
            Site Settings
          </Link>
          <div className="flex">
            <span className="mx-1 font-normal">&gt;</span>
            <p className="font-medium">Data Dictionary Hub</p>
          </div>
        </div>
      </header>
    );
  };

  const onOptionClick = (dict: DataDict, selectedValue: ValueOptionItem) => {
    switch (selectedValue) {
      case ValueOptionItem.ManageValue:
        navigate(`/data-dictionary/${dict.id}/`, {
          state: { DictName: dict.name, pageType: ManageValueAndPostCategoryType.ManageValue },
        });
        break;
      case ValueOptionItem.Rename:
        setShowAddOrRenameModal({ name: dict.name, showModal: true });
        break;
    }
  };

  return (
    <PageContainer>
      <div className="flex flex-col justify-between">
        <div className="mx-10">
          {/* <div className="bg-white">{handleBreadcrumb()}</div> */}
          <div className="flex mt-7">
            <h3 className="flex-1">Data Dictionary Hub</h3>
            <div className="flex flex-1 items-center justify-end">
              <SearchInput searchHandler={(searchedPhrase) => setSearchPhrase(searchedPhrase)} className="mt-6 sm:mt-0 mr-4 w-[400px]" />
              <Button type={ButtonType.PRIMARY} onClick={() => setShowAddOrRenameModal({ showModal: true })}>
                <FontAwesomeIcon icon={faPlus} className="mr-2" />
                Add Dictionary
              </Button>
            </div>
          </div>
        </div>

        <div className="w-full h-[8px] bg-secondary-100 mt-7" />

        <div className="flex flex-col mx-12 mt-6">
          <div className="flex w-full bg-secondary-200 rounded-[5px]">
            {columns.map((col, index) => {
              return col ? (
                <div className="flex flex-1 my-3 first:ml-6 items-center first:justify-start justify-center" key={index}>
                  <label className="text-gray text-sm">{col}</label>
                  <div className="flex flex-col-reverse ml-2 relative">
                    <FontAwesomeIcon
                      icon={faSortDown}
                      className={`absolute cursor-pointer top-[3px] ${
                        sortConfig?.columnName === col && sortConfig.sortType === "Descending" ? "text-primary" : "text-[#DBD6CB]"
                      }`}
                      onClick={() => setSortConfig({ columnName: col, sortType: "Descending" })}
                    />
                    <FontAwesomeIcon
                      icon={faSortDown}
                      className={`rotate-180 cursor-pointer ${
                        sortConfig?.columnName === col && sortConfig.sortType === "Ascending" ? "text-primary" : "text-[#DBD6CB]"
                      }`}
                      onClick={() => setSortConfig({ columnName: col, sortType: "Ascending" })}
                    />
                  </div>
                </div>
              ) : (
                <div className="flex-1"></div>
              );
            })}
          </div>

          <div className="flex justify-center">
            <table className="table-auto flex flex-1">
              <tbody className="flex-col w-full">
                {showingDictionaries.map((dict, index) => (
                  <tr key={index} className="flex flex-1 px-2 border-white border-b-[1px]">
                    <td className="flex-1 p-4">{dict.name}</td>
                    <td className="flex-1 p-4">{dict.activeValueCount}</td>
                    <td
                      className="flex flex-[0.1] items-center justify-center"
                      onClick={() => setShowOptionsMenu({ dict: dict, showModal: !showOptionsMenu.showModal })}
                      tabIndex={0}
                      onBlur={() =>
                        setTimeout(() => {
                          setShowOptionsMenu({ dict: undefined, showModal: false });
                        }, 200)
                      }
                    >
                      <FontAwesomeIcon icon={faEllipsisV} className="flex w-full text-xl text-gray cursor-pointer" />
                      <OptionMenu
                        showOptionMenu={showOptionsMenu.showModal && dict.id === showOptionsMenu.dict?.id}
                        onOptionClick={(selectedItem) => onOptionClick(dict, selectedItem)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {showAddOrRenameModal.showModal && (
          <AddOrRenameDictionaryModal
            dictName={showAddOrRenameModal.name}
            onSaved={(dictName) => {
              // If True -> It's Edit mode
              if (showAddOrRenameModal.name) {
                const dictId = showingDictionaries.find((dict) => dict.name === showAddOrRenameModal.name)!.id;
                updateNewDictionary(dictId, dictName).then((res) => {
                  if (res.status === 200) {
                    getAllDicts();
                  }
                  setShowAddOrRenameModal({ name: undefined, showModal: false });
                });
              } else {
                createNewDictionary(dictName).then((res) => {
                  if (res.status === 201) {
                    navigate(`/data-dictionary/${res.data.id}/`, {
                      state: { DictName: res.data.name, pageType: ManageValueAndPostCategoryType.ManageValue },
                    });
                  }
                  setShowAddOrRenameModal({ name: undefined, showModal: false });
                });
              }
            }}
            onClosed={() => setShowAddOrRenameModal({ name: undefined, showModal: false })}
          />
        )}
      </div>
    </PageContainer>
  );
}

enum ValueOptionItem {
  ManageValue,
  Rename,
}

interface OptionMenuProps {
  showOptionMenu: boolean;
  onOptionClick: (selectedItem: ValueOptionItem) => void;
}

const OptionMenu = (props: OptionMenuProps) => {
  return (
    <div className="relative">
      <div className={`absolute z-10 ${props.showOptionMenu ? "block" : "hidden"} right-[5%]`}>
        <ul className="w-36 wrounded shadow bg-white py-1">
          <MenuItem className="py-2" titleKey="Manage Values" onClick={() => props.onOptionClick(ValueOptionItem.ManageValue)} />
          <MenuItem className="py-2" titleKey="Rename" onClick={() => props.onOptionClick(ValueOptionItem.Rename)} />
        </ul>
      </div>
    </div>
  );
};
