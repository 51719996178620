import React, { useEffect, useState } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown } from "@fortawesome/pro-solid-svg-icons";
import TableRow from "./row/TableRow";
import TableGrid from "./TableGrid";
import { FileContentType } from "../../../types/types";
import Pagination from "../../Pagination";

export type Column = {
  Header: string;
  accessor: any;
};

//////////////////////////////////////////////
const Table: React.FC<{
  columns: Column[];
  data: Array<FileContentType>;
  listOrGridstate: boolean;
  deleteHandler: (id: number) => void;
  sortedAs: string | null;
  sortHandler: (id: any) => void;
  resetSortHandler: () => void;
}> = ({ columns, data, listOrGridstate, deleteHandler, sortedAs, resetSortHandler, sortHandler }) => {
  const [sortBy, setSortBy] = useState<[] | { id: string; desc: boolean }[]>([]);
  const [numberOfColumn, setNumberOfColumn] = useState(0);

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    // @ts-ignore
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    // @ts-ignore
    canPreviousPage,
    // @ts-ignore
    canNextPage,
    // @ts-ignore
    pageCount,
    // @ts-ignore
    gotoPage,
    // @ts-ignore
    nextPage,
    // @ts-ignore
    previousPage,
    // @ts-ignore
    setPageSize,
    // @ts-ignore
    setSortBy: applySortBy,
    state,
  } = useTable(
    {
      columns,
      data,
      // @ts-ignore
      initialState: { sortBy },
      // @ts-ignore
      autoResetPage: false,
    },

    useSortBy,
    usePagination
  );

  useEffect(() => {
    if (sortedAs) {
      externalSortHandler(sortedAs);
    } else {
      applySortBy([]);
    }
  }, [sortedAs]);

  useEffect(() => {
    // @ts-ignore
    !listOrGridstate && setSortBy(state.sortBy);
    // @ts-ignore
    !listOrGridstate && sortHandler(state.sortBy);
  }, [state]);

  useEffect(() => {
    applySortBy(sortBy);
  }, [sortBy]);

  useEffect(() => {
    page.length === 0 && previousPage();
  }, [page]);

  useEffect(() => {
    sortedAs && externalSortHandler(sortedAs);
  }, [sortedAs]);

  useEffect(() => {
    const documentsContainer = document.getElementById("table-container");
    new ResizeObserver((entries) => {
      const width = entries[0].contentRect.width;
      if (width < 1379) {
        if (width < 1050) {
          if (width > 729) {
            setNumberOfColumn(2);
          } else {
            setNumberOfColumn(1);
          }
        } else {
          setNumberOfColumn(3);
        }
      } else {
        setNumberOfColumn(4);
      }
    }).observe(documentsContainer!);
  }, []);

  const selectPage = (_pageNum: number) => {
    gotoPage(_pageNum - 1);
  };
  const externalSortHandler = (id: string) => {
    setSortBy([{ id, desc: false }]);
  };
  // Render the UI for your table

  return (
    <div
      id="table-container"
      className={`flex flex-col justify-between min-h-[360px] bg-white ${!listOrGridstate ? "px-2 justify-end" : ""}`}
    >
      {/* table */}
      <div className="relative">
        <div className="w-full mx-auto overflow-auto bg-white scroll-firefox overflow-x-auto overflow-y-hidden">
          <table {...getTableProps()} className="w-full">
            {!listOrGridstate && (
              <thead className="bg-[#F0EFED] rounded inline-block py-2 px-8 w-full">
                {headerGroups.map((headerGroup) => (
                  <tr
                    key={headerGroup.getHeaderGroupProps().key}
                    style={headerGroup.getHeaderGroupProps().style}
                    role={headerGroup.getHeaderGroupProps().role}
                    className="w-full flex justify-between"
                  >
                    {headerGroup.headers.map((column, i) => (
                      <th
                        // @ts-ignore
                        key={column.getHeaderProps(column.getSortByToggleProps()).key}
                        className={`text-sm font-normal text-[#838281] ${i === 0 ? "w-[40%]" : ""}`}
                        // @ts-ignore
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                      >
                        {column.render("Header") !== " " && (
                          <div onClick={() => selectPage(1)} className="flex items-center">
                            <h3 className="text-sm font-normal text-[#838281]">{column.render("Header")}</h3>
                            <div onClick={() => resetSortHandler()} className="relative ml-4">
                              <FontAwesomeIcon
                                className={`text-sm absolute top-1 ${
                                  // @ts-ignore
                                  column.isSorted && column.isSortedDesc ? "text-[#B29B66]" : "text-[#DBD6CB]"
                                }`}
                                icon={faSortDown}
                              />

                              <FontAwesomeIcon
                                className={`text-sm rotate-180 ${
                                  // @ts-ignore
                                  column.isSorted && !column.isSortedDesc ? "text-[#B29B66]" : "text-[#DBD6CB]"
                                }`}
                                icon={faSortDown}
                              />
                            </div>
                          </div>
                        )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
            )}
            <tbody
              {...getTableBodyProps()}
              style={
                listOrGridstate
                  ? {
                      display: "grid",
                      justifyContent: "center",
                      gridTemplateColumns: `repeat(${numberOfColumn}, minmax(0,${318}px))`,
                      gap: "24px",
                      paddingLeft: "12px",
                      paddingRight: "12px",
                    }
                  : {}
              }
            >
              {
                // @ts-ignore
                page.map((row, index) => {
                  prepareRow(row);
                  return listOrGridstate ? (
                    <TableGrid row={row} key={row.id} deleteHandler={deleteHandler} />
                  ) : (
                    <TableRow row={row} key={row.id} index={index} numberOfRows={page.length} deleteHandler={deleteHandler} />
                  );
                })
              }
            </tbody>
          </table>
        </div>
      </div>
      <div className="mb-4">
        <Pagination
          previousPage={previousPage}
          nextPage={nextPage}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          setPageSize={setPageSize}
          gotoPage={gotoPage}
          pageCount={pageCount}
          // @ts-ignore
          pageIndex={state.pageIndex}
          // @ts-ignore
          pageSize={state.pageSize}
          pageLength={page.length}
          totalLength={data.length}
          pageName="FileManager"
          numberOfItemsArray={["4", "8", "16"]}
          selectedNumber="4"
        />
      </div>
    </div>
  );
};

export default Table;
