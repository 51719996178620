import { ReactNode } from "react";
import Modal from "./Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/pro-regular-svg-icons";
import Button, { ButtonType } from "./Button";

export interface CmsModalMainShapePublic {
  showModal: boolean;
  onNegativeBtnClick: () => void;
  onPositiveBtnClick: (resultData?: any[]) => void;
}

export interface CmsModalMainShapeProps extends CmsModalMainShapePublic {
  title: string;
  rootStyle?: React.CSSProperties;
  children?: ReactNode;
  positiveBtnTitle: string;
  maxWidth?: number;
  loading?: boolean;
}

export default function CmsModalMainShape(props: CmsModalMainShapeProps) {
  return (
    <Modal
      containerStyle={
        props.rootStyle ?? {
          maxWidth: props.maxWidth ? props.maxWidth : 320,
          paddingTop: 16,
          paddingBottom: 32,
          paddingLeft: 40,
          paddingRight: 40,
        }
      }
      showModal={props.showModal}
    >
      <div>
        <div className="flex justify-between items-center">
          <h1 className="flex flex-1 font-bold">{props.title}</h1>
          <FontAwesomeIcon className="text-[#504F4E] cursor-pointer" icon={faClose} onClick={props.onNegativeBtnClick} />
        </div>

        {props.children}

        <div className="flex justify-center mt-8">
          <Button className="text-gray border-[#C4C4C4] mr-4" type={ButtonType.SECONDARY} onClick={props.onNegativeBtnClick}>
            Cancel
          </Button>

          <Button type={ButtonType.PRIMARY} onClick={props.onPositiveBtnClick} showLoading={props.loading}>
            {props.positiveBtnTitle}
          </Button>
        </div>
      </div>
    </Modal>
  );
}
