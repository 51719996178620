import { useEffect, useState } from "react";
import CmsModalMainShape, { CmsModalMainShapePublic } from "../../../CmsModalMainShape";
import Dropdown from "../../../inputs/Dropdown";
import InputField from "../../../inputs/InputField";
import { getMenuItemParentAndPages, getMenuItem, getBlocksDropdown } from "../../../../api/menuItem";
import { OptionType } from "../../../../utils/types";
import { TreeModel } from "../../../../types/types";
import LanguageBox, { DEFAULT_LANGUGAE } from "../../../LanguageBox";
import { getLanguages } from "../../../../api/content";

interface Props extends CmsModalMainShapePublic {
  menuItem?: TreeModel;
  loading: boolean;
}

export default function AddAndEditMenu(props: Props) {
  const DEFAULT_PARENT = { key: 0, label: "No Parent" };
  const DEFAULT_PAGE = { key: 0, label: "No Page" };
  const DEFAULT_BLOCK = { key: 0, label: "No Block" };

  /** Get title depends on selected language */
  const [allLanguages, setAllLanguages] = useState<Array<string>>([]);
  const [language, setLanguage] = useState<string>(DEFAULT_LANGUGAE);

  const [parentList, setParentList] = useState<OptionType[]>([]);
  const [pages, setPages] = useState<OptionType[]>([]);
  const [blocks, setBlocks] = useState<OptionType[]>([]);
  const [selectedBlock, setSelectedBlock] = useState<OptionType>(DEFAULT_BLOCK);

  const [menuItem, setMenuItem] = useState<TreeModel>(props.menuItem ?? { id: -1, name: "", child: [] });
  const [selectedParent, setSelectedParent] = useState<OptionType>(DEFAULT_PARENT);
  const [selectedPage, setSelectedPage] = useState<OptionType>(DEFAULT_PAGE);
  const [title, setTitle] = useState<{ [key: string]: string }>({});
  const [url, setUrl] = useState<string>(props.menuItem?.url ?? "");

  const [validationErr, setValidationErr] = useState<{ titleErr?: boolean; urlErr?: boolean; blockErr?: boolean }>({
    titleErr: false,
    urlErr: false,
    blockErr: false,
  });

  useEffect(() => {
    if (props.menuItem) {
      // So, it's edit mode ...
      getMenuItem(props.menuItem.id).then((res) => {
        setMenuItem(res);
        res.title ? setTitle(res.title!) : setTitle({});
        if (res.url) setUrl(res.url);
      });
    }

    getMenuItemParentAndPages().then((res) => {
      res.parentList.unshift(DEFAULT_PARENT);
      res.pages.unshift(DEFAULT_PAGE);

      const parentList =
        menuItem.id === -1 // It's a new menu item so no need to filter the parents array
          ? res.parentList
          : res.parentList.filter((menu) => menu.key !== menuItem.id);

      // Assign default values to the fields when we want edit menu item
      if (props.menuItem) {
        const currentParent = parentList.find((parent) => (parent.key as number) === props.menuItem?.parent);
        setSelectedParent(currentParent ?? DEFAULT_PARENT);
        const currentPage = res.pages.find((page) => page.key === props.menuItem?.content);
        setSelectedPage(currentPage ?? DEFAULT_PAGE);
      }

      setParentList(parentList);
      setPages(res.pages);
    });

    getLanguages().then((res) => {
      setAllLanguages(res.data);
    });
  }, []);

  useEffect(() => {
    menuItem.content &&
      getBlocksDropdown(Number(menuItem.content)).then((res) => {
        res.unshift(DEFAULT_BLOCK);
        // Assign default values to the fields when we want edit menu item
        if (props.menuItem) {
          const currentBlock = res.find((block: any) => block.key === menuItem.scrollBlock);

          setSelectedBlock(currentBlock ?? DEFAULT_BLOCK);
          setBlocks(res);
        }
      });
  }, [menuItem]);
  const checkValidation = () => {
    const titleIsValid = title[language] && title[language].length > 0;
    const blockIsValid = selectedBlock.key !== DEFAULT_BLOCK.key;

    if (selectedPage.key === DEFAULT_PAGE.key) {
      const urlIsValid = url.length > 0;

      setValidationErr({ titleErr: !titleIsValid, urlErr: !urlIsValid });

      if (titleIsValid && urlIsValid) {
        saveMenu();
      }
    } else {
      if ((titleIsValid && blockIsValid) || !blockIsValid) {
        !blockIsValid ? saveMenu(null, null) : saveMenu(title, null);
      } else {
        setValidationErr({ titleErr: !titleIsValid, blockErr: !blockIsValid });
      }
    }
  };

  const saveMenu = (titleInput?: { [key: string]: string } | null, urlInput?: null) => {
    const updatedMenu: TreeModel = {
      ...menuItem,
      parent: selectedParent.key === 0 ? null : (selectedParent.key as number),
      content: selectedPage.key === 0 ? null : (selectedPage.key as string),
      scrollBlock: selectedBlock.key === 0 ? null : (selectedBlock.key as string),
      title: titleInput === null ? null : title,
      url: urlInput === null ? "" : url,
      header: 1,
    };

    props.onPositiveBtnClick([updatedMenu]);
  };

  return (
    <CmsModalMainShape
      {...props}
      title=""
      positiveBtnTitle="Save"
      onPositiveBtnClick={() => checkValidation()}
      maxWidth={600}
      loading={props.loading}
    >
      <div className="w-full flex justify-center">
        <LanguageBox languagesArr={allLanguages} defaultLang={DEFAULT_LANGUGAE} onChange={(lang) => setLanguage(lang)} />
      </div>
      <div className="max-w-[240px]">
        <label className="font-semibold mt-6">Parent</label>
        <Dropdown
          label=""
          options={parentList}
          selectedOption={selectedParent}
          onOptionSelect={(selectedOption) => setSelectedParent(selectedOption)}
        />
      </div>

      <div className="max-w-[240px]">
        <label className="font-semibold mt-6">Page</label>
        <Dropdown
          label=""
          options={pages}
          selectedOption={selectedPage}
          onOptionSelect={(selectedOption) => {
            setSelectedPage(selectedOption);
            setValidationErr({ titleErr: false, urlErr: false });
            setSelectedBlock(DEFAULT_BLOCK);
            getBlocksDropdown(selectedOption.key as number).then((res) => {
              res.unshift(DEFAULT_BLOCK);
              setBlocks(res);
            });
          }}
        />
      </div>

      <div className="flex max-w-[520px] flex-wrap">
        <div className="w-[240px] mr-10">
          <label className="font-semibold mt-6">Block</label>
          <Dropdown
            label=""
            options={selectedPage.key !== 0 ? blocks : []}
            selectedOption={selectedPage.key !== 0 ? selectedBlock : DEFAULT_BLOCK}
            onOptionSelect={(selectedOption) => {
              setSelectedBlock(selectedOption);
              selectedOption.key === DEFAULT_BLOCK.key && setTitle({});
              setValidationErr({ titleErr: false, urlErr: false });
            }}
          />
        </div>
        <div className="w-[240px]">
          <label className="font-semibold block">Block Title</label>
          <InputField
            disabled={selectedPage.key === 0}
            className={`${selectedPage.key === 0 ? "opacity-50" : "opacity-100"}`}
            type={"T"}
            label=""
            value={title[language] && selectedPage.key !== 0 ? title[language] : ""}
            onChange={(value: string | null) => {
              const newTitleValue = { ...title };
              newTitleValue[language] = value ?? "";
              setTitle(newTitleValue);

              if (value && value.length > 0) {
                setValidationErr({ ...validationErr, titleErr: false });
              }
            }}
            placeholder={`Block Title ( ${language} )`}
          />
          {selectedPage.key !== 0 && selectedBlock.key !== 0 && validationErr.titleErr && (
            <label className="text-error mt-1">Required Field *</label>
          )}
        </div>
      </div>

      <div className="flex w-full mt-7 items-center">
        <div className="w-1/2 bg-secondary-200 h-[1px]" />
        <label className="px-2 text-secondary-200">OR</label>
        <div className="w-1/2 bg-secondary-200 h-[1px]" />
      </div>

      <div className="flex max-w-[520px] flex-wrap">
        <div className="w-[240px] mr-10">
          <label className="font-semibold mt-6">URL</label>
          <InputField
            disabled={selectedPage.key !== 0}
            className={`${selectedPage.key !== 0 ? "opacity-50" : "opacity-100"}`}
            type={"T"}
            label=""
            value={url}
            onChange={(value: string | null) => {
              setUrl(value ?? "");
              if (value && value.length > 0) {
                setValidationErr({ ...validationErr, urlErr: false });
              }
            }}
            placeholder="https://..."
          />
          {selectedPage.key === 0 && validationErr.urlErr && <label className="text-error mt-1">Required Field *</label>}
        </div>
        <div className="w-[240px]">
          <label className="font-semibold block">Title</label>
          <InputField
            disabled={selectedPage.key !== 0}
            className={`${selectedPage.key !== 0 ? "opacity-50" : "opacity-100"}`}
            type={"T"}
            label=""
            value={title[language] && selectedPage.key === 0 ? title[language] : ""}
            onChange={(value: string | null) => {
              const newTitleValue = { ...title };
              newTitleValue[language] = value ?? "";
              setTitle(newTitleValue);

              if (value && value.length > 0) {
                setValidationErr({ ...validationErr, titleErr: false });
              }
            }}
            placeholder={`Title ( ${language} )`}
          />
          {selectedPage.key === 0 && validationErr.titleErr && <label className="text-error mt-1">Required Field *</label>}
        </div>
      </div>
    </CmsModalMainShape>
  );
}
