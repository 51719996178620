import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PageContainer from "../../../layout/PageContainer";
import Button, { ButtonType } from "../../../Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDiskCircleXmark } from "@fortawesome/pro-regular-svg-icons";
import { StatusBoxType, TreeModel } from "../../../../types/types";
import {
  getMenuItems,
  addMenuItem,
  updateMenuItem,
  reorderMenuItems,
  deleteMenuItem,
  determineHeaderStatus,
  HeaderStatus,
} from "../../../../api/menuItem";
import OrderableTree, { OptionItemType, TreeMode } from "../../../manage_content/manage_block/OrderableTree";
import AddAndEditMenu from "./AddAndEditMenu";
import AreYouSureModal from "../../../manage_content/AreYouSureModal";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { getStatusTypeStr } from "../../../../utils/utils";
import UnsavedChangesModal from "../../../manage_content/UnsavedChangesModal";
import { ContentSubmit } from "../../../../api/content";
import { faEye } from "@fortawesome/pro-regular-svg-icons";
import Loading, { LoadingMode } from "../../../Loading";

const Menu = () => {
  const [menuItems, setMenuItems] = useState<TreeModel[]>([]);
  const [showEditAndChangeParentModal, setShowEditAndChangeParentModal] = useState<{
    show: boolean;
    menuItem?: TreeModel;
  }>({
    show: false,
    menuItem: undefined,
  });
  const [showAreYouSureModal, setShowAreYouSureModal] = useState<{ show: boolean; menuId: number }>({
    show: false,
    menuId: -1,
  });
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);
  const [refreshPage, setRefreshPage] = useState(true);
  const [menuStatus, setMenuStatus] = useState<StatusBoxType>(StatusBoxType.PB);
  const [showDiscardModal, setShowDiscardModal] = useState(false);
  const [showUnpublishModal, setShowUnpublishModal] = useState(false);
  const [showLoading, setShowLoading] = useState<{
    showPublishLoading: boolean;
    showUnpublishLoading: boolean;
    showDiscardLoading: boolean;
  }>({
    showPublishLoading: false,
    showUnpublishLoading: false,
    showDiscardLoading: false,
  });
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (refreshPage) {
      getMenuItems()
        .then((res) => {
          setMenuItems(res.menu);
          console.log(res.status);
          setMenuStatus(res.status);
          setRefreshPage(false);
        })
        .catch((execption) => setRefreshPage(false));
    }
  }, [refreshPage]);

  const updateList = (responseStatus: number) => {
    if (responseStatus === 200 || responseStatus === 201) {
      setRefreshPage(true);
    }
    setShowEditAndChangeParentModal({ show: false, menuItem: undefined });
  };

  const publishMenu = () => {
    setShowLoading({ ...showLoading, showPublishLoading: true });

    determineHeaderStatus(HeaderStatus.Publish).then(() => {
      setRefreshPage(true);
      setShowLoading({ ...showLoading, showPublishLoading: false });
    });
  };

  return (
    <PageContainer>
      <div className="flex mx-9 justify-between header-title-space">
        <h3>Header & Menu</h3>
        <div>
          <Button onClick={() => {}} type={ButtonType.SECONDARY} style={{ marginRight: 16 }}>
            <div>
              <FontAwesomeIcon icon={faEye} className="mr-2" />
              Preview
            </div>
          </Button>
          <Button onClick={() => navigate("/header")} type={ButtonType.PRIMARY}>
            Manage Header Details
          </Button>
        </div>
      </div>

      <div className="flex flex-col mx-9 mt-6">
        <div className="info-box" style={{ boxShadow: "0 4px 4px 0 rgba(0, 0, 0, 0.15)" }}>
          <div>
            Published Status:
            <span className="ml-2 font-light">
              {getStatusTypeStr(menuStatus)}
              {menuStatus === StatusBoxType.DP && <FontAwesomeIcon icon={faFloppyDiskCircleXmark} className="ml-2" />}
            </span>
          </div>
          <div className="text-primary flex flex-wrap gap-3 items-center">
            {menuStatus === StatusBoxType.DP && (
              <div className="flex items-center justify-center">
                {showLoading.showDiscardLoading && <Loading style={{ marginRight: 8 }} loadingMode={LoadingMode.Button} />}
                <span
                  className="cursor-pointer"
                  onClick={() => {
                    setShowLoading({ ...showLoading, showDiscardLoading: true });

                    determineHeaderStatus(HeaderStatus.Discard).then(() => {
                      setRefreshPage(true);
                      setShowLoading({ ...showLoading, showDiscardLoading: false });
                    });
                  }}
                >
                  Discard Unpublished Changes
                </span>
              </div>
            )}
            {(menuStatus === StatusBoxType.DF || menuStatus === StatusBoxType.DP) && (
              <Button onClick={publishMenu} type={ButtonType.PRIMARY} showLoading={showLoading.showPublishLoading}>
                Publish
              </Button>
            )}
            {menuStatus === StatusBoxType.PB && (
              <Button
                onClick={() => {
                  setShowLoading({ ...showLoading, showUnpublishLoading: true });

                  determineHeaderStatus(HeaderStatus.Unpublish).then(() => {
                    setShowLoading({ ...showLoading, showUnpublishLoading: false });
                    setRefreshPage(true);
                  });
                }}
                type={ButtonType.PRIMARY}
                showLoading={showLoading.showUnpublishLoading}
              >
                Unpublish
              </Button>
            )}
          </div>
        </div>
      </div>
      <div className="border border-t-0 border-b-secondary-100 border-b-8 mt-6" />

      <div className="grid grid-cols-1 gap-8 mx-9 mt-6">
        <div>
          <div className="flex justify-end mb-2">
            {/* <Button type={ButtonType.PRIMARY} onClick={() => navigate("/header")}>
                Edit Header Detail
              </Button> */}
            <Button
              className="flex items-center ml-2"
              type={ButtonType.PRIMARY}
              onClick={() => setShowEditAndChangeParentModal({ show: true, menuItem: undefined })}
            >
              <FontAwesomeIcon className="mx-2" icon={faPlus} />
              Add Menu Item
            </Button>
          </div>
          <OrderableTree
            treeMode={TreeMode.MenuTree}
            blockList={menuItems}
            onOptionItemClick={(_menu, parentId, selectedItem: OptionItemType) => {
              switch (selectedItem) {
                case OptionItemType.ChangeParent:
                  let targetMenuItem = undefined;
                  if (parentId === 0) {
                    targetMenuItem = menuItems.find((menu) => menu.id === _menu.id);
                  } else {
                    targetMenuItem = menuItems.find((menuParent) => menuParent.id === parentId)?.child.find((menu) => menu.id === _menu.id);
                  }

                  setShowEditAndChangeParentModal({
                    show: true,
                    menuItem: targetMenuItem,
                  });
                  break;
                case OptionItemType.Delete:
                  setShowAreYouSureModal({ show: true, menuId: _menu.id });
                  break;
              }
            }}
            onRowMoved={(menuId, originIndex, destinationIndex) => {
              reorderMenuItems(menuId, destinationIndex, originIndex);
              setRefreshPage(true);
            }}
          />
        </div>
      </div>

      {showEditAndChangeParentModal.show && (
        <AddAndEditMenu
          menuItem={showEditAndChangeParentModal.menuItem}
          showModal={showEditAndChangeParentModal.show}
          onPositiveBtnClick={(resultData) => {
            const resMenuItem = resultData![0] as TreeModel;
            setLoading(true);

            if (showEditAndChangeParentModal.menuItem) {
              updateMenuItem(resMenuItem).then((res) => {
                console.log(res.status.status);
                if (res.status.status === 200) setLoading(false);
                updateList(res.status.status);
              });
            } else {
              addMenuItem(resMenuItem).then((res) => {
                if (res.status.status === 201) setLoading(false);
                updateList(res.status.status);
              });
            }
          }}
          onNegativeBtnClick={() => setShowEditAndChangeParentModal({ show: false, menuItem: undefined })}
          loading={loading}
        />
      )}

      {showAreYouSureModal.show && (
        <AreYouSureModal
          showModal={showAreYouSureModal.show}
          title="Delete Menu!"
          description="Are you sure to delete this menu item?"
          positiveButtonTitle="Delete"
          negativeButtonTitle="Cancel"
          onPositiveClicked={() => {
            setDeleteLoading(true);
            deleteMenuItem(showAreYouSureModal.menuId).then((res) => {
              if (res.status === 204) {
                setDeleteLoading(false);
                setShowAreYouSureModal({ show: false, menuId: -1 });
                setRefreshPage(true);
              }
            });
          }}
          onNegativeClicked={() => setShowAreYouSureModal({ show: false, menuId: -1 })}
          loading={deleteLoading}
        />
      )}

      {showUnsavedChangesModal && (
        <AreYouSureModal
          showModal={showUnsavedChangesModal}
          title="Warning !"
          description="You have unsaved changes in styles! All the changes you've made after publishing this page will be lost."
          positiveButtonTitle="Publish anyway"
          negativeButtonTitle="Cancel"
          onPositiveClicked={() => {
            publishMenu();
            setShowUnsavedChangesModal(false);
          }}
          onNegativeClicked={() => setShowUnsavedChangesModal(false)}
        />
      )}

      {showDiscardModal && (
        <UnsavedChangesModal
          contentSubmit={ContentSubmit.Discard}
          showModal={showDiscardModal}
          onPositiveClicked={() => {
            determineHeaderStatus(HeaderStatus.Discard).then(() => setRefreshPage(true));
            setShowDiscardModal(false);
          }}
          onNegativeClicked={() => setShowDiscardModal(false)}
        />
      )}

      {showUnpublishModal && (
        <UnsavedChangesModal
          contentSubmit={ContentSubmit.unPublish}
          showModal={showUnpublishModal}
          onPositiveClicked={() => {
            determineHeaderStatus(HeaderStatus.Unpublish).then(() => setRefreshPage(true));
            setShowUnpublishModal(false);
          }}
          onNegativeClicked={() => setShowUnpublishModal(false)}
        />
      )}
    </PageContainer>
  );
};

export default Menu;
