import React, { useState } from "react";
import SiteThemeContainer from "./SiteThemeContainer";
import { SiteThemeType, BlockType } from "../../types/types";
import BorderBlock from "./blocks/BorderBlock";
import ShadowBlock from "./blocks/ShadowBlock";
import PaddingBlock from "./blocks/PaddingBlock";
import BackGroundBlock from "./blocks/BackGroundBlock";
import GradientBackground from "./blocks/GradientBackground";
import GeneralSettingsBlockBlock from "./blocks/GeneralSettingsBlockBlock";

const ManageBlockDefaults: React.FC<{
  onCancel: () => void;
  loading: boolean;
  siteThemeStyle: SiteThemeType;
  onSave: (blockStyle: BlockType) => void;
}> = ({ onCancel, loading, siteThemeStyle, onSave }) => {
  const [blockStyle, setBlockStyle] = useState(siteThemeStyle.blockStyle);

  return (
    <SiteThemeContainer
      hasLeftBorder
      loading={loading}
      onCancel={onCancel}
      onSave={() => {
        onSave(blockStyle!);
      }}
    >
      <GeneralSettingsBlockBlock
        marginChoices={siteThemeStyle.marginChoices}
        onChange={(value) => {
          setBlockStyle({
            ...blockStyle,
            maxHeight: value.maxHeight,
            minHeight: value.minHeight,
            margin: value.margin,
            maxWidth: value.maxWidth,
            isCentralizedInMobile: value.isCentralizedInMobile,
          });
        }}
        style={{
          isCentralizedInMobile: siteThemeStyle.blockStyle.isCentralizedInMobile,
          margin: siteThemeStyle.blockStyle.margin as "00" | "auto",
          maxHeight: siteThemeStyle.blockStyle.maxHeight,
          maxWidth: siteThemeStyle.blockStyle.maxWidth,
          minHeight: siteThemeStyle.blockStyle.minHeight,
        }}
      />
      <BorderBlock
        className="mt-4"
        border={blockStyle.border}
        borderSideOptions={siteThemeStyle.borderSideChoices}
        borderStyleOptions={siteThemeStyle.borderStyleChoices}
        borderWidthOptions={siteThemeStyle.borderWidthChoices}
        onChange={(value) => {
          setBlockStyle({ ...blockStyle, border: value });
        }}
        title="Border"
      />
      <ShadowBlock
        onChange={(value) => {
          setBlockStyle({ ...blockStyle, shadow: value });
        }}
        shadowStyle={blockStyle.shadow}
        className="mt-4"
      />
      <PaddingBlock
        className="mt-4"
        onChange={(value) => {
          setBlockStyle({ ...blockStyle, padding: value });
        }}
        padding={blockStyle.padding}
      />
      <BackGroundBlock
        className="mt-4"
        backGround={blockStyle.background}
        onChange={(value) => {
          setBlockStyle({ ...blockStyle, background: value });
        }}
      />
      <GradientBackground
        className="mt-4"
        directionChoices={siteThemeStyle.directionChoices}
        gradientBackgroundStyle={blockStyle.gradientBackground}
        onChange={(value) => {
          setBlockStyle({ ...blockStyle, gradientBackground: value });
        }}
      />
    </SiteThemeContainer>
  );
};

export default ManageBlockDefaults;
