import React, { useState } from "react";
import BlockBox from "./BlockBox";
import Dropdown from "../../inputs/Dropdown";
import ColorPicker from "../../inputs/ColorPicker";
import { BorderStyleType, BorderWidth, BorderStyle, DropdownChoice } from "../../../types/types";
import CheckBoxInput from "../../inputs/checkboxInput/CheckBoxInput";

export const enum BorderAndBackgroundBlockEnum {
  Header,
  StickyColumn,
}

const BorderAndBackgroundBlock: React.FC<{
  headerOrStickyColumn: BorderAndBackgroundBlockEnum;
  title: string;
  className?: string;
  border: BorderStyleType | undefined;
  addedColor?: string;
  setAddedColor: (color: string) => void;
  onChange: (borderStyle: any) => void;
  borderStyleOptions: DropdownChoice[];
  borderWidthOptions: DropdownChoice[];
  borderSideOptions: DropdownChoice[];
}> = ({
  className,
  border,
  onChange,
  borderStyleOptions,
  borderWidthOptions,
  borderSideOptions,
  title,
  addedColor,
  headerOrStickyColumn,
  setAddedColor,
}) => {
  const [color, setColor] = useState(border?.borderColor!);
  const [addedColorState, setAddedColorState] = useState(addedColor);
  const [borderStyleState, setBorderStyleState] = useState(border);

  return (
    <BlockBox className={className} title={title}>
      <div className="flex w-full justify-between flex-wrap">
        <div className="w-[238px]">
          <Dropdown
            options={borderStyleOptions}
            onOptionSelect={(value) => {
              setBorderStyleState({ ...borderStyleState! });
              onChange({ ...borderStyleState, borderStyle: value.key as BorderStyle });
            }}
            selectedOption={borderStyleOptions.find((key) => key.key === border?.borderStyle) || null}
            label="Border Style (Please choose an option)"
          />
        </div>
        <div className="w-[238px] ">
          <ColorPicker
            color={color}
            label="Border Color"
            setColor={(color) => {
              setColor(color);
              setBorderStyleState({ ...borderStyleState!, borderColor: color });
              onChange({ ...borderStyleState, borderColor: color.toString() });
            }}
          />
        </div>
        <div className="w-[238px]">
          <ColorPicker
            color={addedColorState!}
            label={`${headerOrStickyColumn === BorderAndBackgroundBlockEnum.Header ? "Header BackgroundColor" : "Sticky BackgroundColor"}`}
            setColor={(color) => {
              setAddedColorState(color);
              setAddedColor(color);
            }}
          />
        </div>
      </div>
      <div className="flex justify-between mt-12 flex-wrap">
        <div className="w-[238px]">
          <CheckBoxInput
            label="Border Side"
            options={borderSideOptions}
            onChange={(input) => {
              setBorderStyleState({ ...borderStyleState!, borderSideArray: input });
              onChange({ ...borderStyleState!, borderSideArray: input });
            }}
            borderSideArray={border?.borderSideArray}
          />
        </div>
        <div className="w-[238px]">
          <Dropdown
            options={borderWidthOptions}
            onOptionSelect={(value) => {
              setBorderStyleState({ ...borderStyleState!, borderWidth: value.key as BorderWidth });
              onChange({ ...borderStyleState, borderWidth: value.key as BorderWidth });
            }}
            selectedOption={borderWidthOptions.find((key) => border?.borderWidth === key.key) || null}
            label="Border Width"
          />
        </div>
        <div className="w-[238px]"></div>
      </div>
    </BlockBox>
  );
};

export default BorderAndBackgroundBlock;
