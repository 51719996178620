import API from "./_config";

export function getLanguage() {
  return API.get(`language/`).then((response) => {
    return response;
  });
}
export function editLanguage(language_code: string, status: boolean) {
  return API.patch(`language/${language_code}/`, { active: status }).then((response) => {
    return response;
  });
}
