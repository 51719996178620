import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolderOpen } from "@fortawesome/pro-light-svg-icons";
import { faFile, faMultiply } from "@fortawesome/pro-solid-svg-icons";
import { characterSpliter } from "../../../utils/utils";
import { FileDataType } from "../../../types/types";

const FileUploader: React.FC<{
  cancelStatus: boolean;
  data: FileDataType;
  uploadProgress: number;
  showPercent: boolean;
  onChange: (input: File) => void;
}> = ({ data, cancelStatus, showPercent, uploadProgress, onChange }) => {
  const [filePreview, setFilePreview] = useState(true);
  const [fileName, setFileName] = useState("");
  const [fileSize, setFileSize] = useState("");

  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setFileName(data.fileName);
    setFileSize(data.size);
  }, [data]);

  useEffect(() => {
    setFileName(data.fileName);
    setFileSize(data.size);
    data.fileName && setFilePreview(true);
  }, [cancelStatus, data]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setFileName(file.name);
      setFileSize(String(Math.floor(file.size / 1000)) + "KB");
      setFilePreview(true);
      onChange(file);
    }
  };

  const handleFileDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      setFileName(file.name);
      setFileSize(String(Math.floor(file.size / 1000)) + "KB");
      setFilePreview(true);
      onChange(file);
    }
  };

  const onDeleteHandler = () => {
    setFilePreview(false);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  return (
    <div className="max-w-[522px] mx-auto">
      <div onDragOver={(e) => e.preventDefault()} onDrop={handleFileDrop} className="w-full flex justify-center">
        <div className="flex mt-6 h-[42px] w-[286px] border-gray-300 ">
          <div className="flex items-center">
            <FontAwesomeIcon icon={faFolderOpen} className={`w-7 h-[22px] text-primary`} />
          </div>
          <div className="ml-2 text-[#121212] text-sm">
            <p>
              Drag & drop your file here or
              <input ref={fileInputRef} id="file-input" type="file" style={{ display: "none" }} onChange={(e) => handleFileChange(e)} />
              <label htmlFor="file-input" className="text-primary cursor-pointer ml-1">
                choose file
              </label>
            </p>
            <p className="text-[#ABAAA9] text-xs mt-1">25 MB max file size</p>
          </div>
        </div>
      </div>

      {filePreview && (
        <div className="flex justify-center mt-6">
          <div className="bg-secondary-200 max-w-[522px] h-[72px] flex-1 border border-secondary-300 shadow p-[10px] rounded flex justify-between">
            <div className="min-w-[238px] h-[52px] bg-white rounded flex items-center justify-center cursor-pointer">
              <div
                onClick={() => {
                  window.open(data.url, "_blank");
                }}
                className="min-w-[166px] h-[41px]"
              >
                <div className="flex items-center">
                  <div className="flex items-center">
                    <FontAwesomeIcon icon={faFile} className={`w-[18px] h-6 text-primary pr-1`} />
                  </div>
                  <div className="ml-2">
                    <p className="text-primary text-sm">
                      {fileName && characterSpliter(fileName, 8)}
                      <span className="ml-2">{fileSize}</span>
                    </p>
                    <p className="text-[#ABAAA9] text-xs">
                      25 MB max file size
                      {showPercent && <span className="ml-2">{uploadProgress}%</span>}
                    </p>
                  </div>
                </div>
              </div>
              <FontAwesomeIcon
                onClick={() => {
                  !showPercent && onDeleteHandler();
                }}
                icon={faMultiply}
                className={`w-[10px] h-[10px] text-primary ${!showPercent ? "cursor-pointer" : "opacity-50"} ml-4`}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FileUploader;
