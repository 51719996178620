import { useEffect } from "react";
import PageContainer from "../layout/PageContainer";
import { useNavigate } from "react-router-dom";
const MainPage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("content");
  }, []);
  return (
    <PageContainer>
      <></>
    </PageContainer>
  );
};

export default MainPage;
