import React from "react";

const BreadCrumbAndHeader: React.FC<{ fileName: string }> = ({ fileName }) => {
  return (
    <div className="px-12 pt-10">
      <div className="text-xs font-light">
        File Manager <span className="translate-y-[1px] inline-block">&gt;</span>
        <span> {fileName} </span>
        <span className="translate-y-[1px] inline-block font-medium">&gt;</span>
        <span className="font-medium"> Edit File</span>
      </div>
      <h1 className="text-base font-medium mt-10">Edit File</h1>
    </div>
  );
};

export default BreadCrumbAndHeader;
