import { useLocation, useParams } from "react-router-dom";
import SearchInput from "../../../inputs/SearchInput";
import Button, { ButtonType } from "../../../Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { DropResult } from "react-beautiful-dnd";
import { useEffect, useState } from "react";
import {
  getAllDictValuesAndPosts,
  createDictValueAndPost,
  updateDictValueAndPost,
  deleteDictValueAndPost,
  updateManageValueOrder,
  updatePostCategoryOrder,
} from "../../../../api/data_dictionary";
import AddOrEditValueModal from "../AddOrEditValueModal";
import DeleteDictValueModal, { DeleteModalType } from "../DeleteDictValueModal";
import { Snackbar, SnackbarContent } from "@mui/material";
import PageContainer from "../../../layout/PageContainer";
import { OptionType } from "../../../../utils/types";
import Table from "./Table";
import Line from "../../Line";
import { PostTypeType } from "../../postType/manageValue/ManagePostType";

export enum PostType {
  Blog = "Blog",
  Vacancies = "Vacancies",
  News = "News",
}

export type DictPostValueType = {
  id: number;
  value: { [key: string]: string | null };
  isActive: boolean;
  isUsed: boolean;
  translation: Array<string>;
  type?: number | PostTypeType;
  publicStyle?: any;
};

export enum ManageValueAndPostCategoryType {
  ManageValue = "ManageValue",
  ManagePostCategory = "ManagePostCategory",
}

export default function ManageValueAndPostCategory() {
  const location = useLocation();
  const { dataDictionaryId, postTypeId } = useParams();
  const parentDictionaryId = dataDictionaryId as unknown as number;
  const parentDictName = location?.state?.DictName;
  const pageType: ManageValueAndPostCategoryType =
    location?.state?.pageType === ManageValueAndPostCategoryType.ManageValue
      ? ManageValueAndPostCategoryType.ManageValue
      : ManageValueAndPostCategoryType.ManagePostCategory;

  const columns = [
    { Header: "Value", accessor: "value" },
    { Header: "Active", accessor: "active" },
    { Header: "Translation", accessor: "translation" },
    { Header: " ", accessor: "" },
  ];

  const [allValues, setAllValues] = useState<Array<DictPostValueType>>([]);
  const [postTypes, setPostTypes] = useState<Array<OptionType>>([]);
  const [showingValues, setShowingValues] = useState<Array<DictPostValueType>>(
    []
  );
  const [searchPhrase, setSearchPhrase] = useState<string | null>(null);
  const [showAddOrEditValueModal, setShowAddOrEditValueModal] = useState<{
    dictValue?: DictPostValueType;
    showModal: boolean;
  }>({
    showModal: false,
  });

  const [showSnackbar, setShowSnackbar] = useState<{
    showSnack: boolean;
    isSuccess: boolean;
    message?: string;
  }>({
    showSnack: false,
    isSuccess: false,
  });
  const [allLanguags, setAllLanguags] = useState<Array<string>>([]);
  const [siteDefaultLanguage, setSiteDefaultLanguage] = useState("");
  const [isDraggable, setIsDraggable] = useState(false);
  const [showOptionsMenu, setShowOptionsMenu] = useState<{
    dictValue?: DictPostValueType;
    showModal: boolean;
  }>({
    showModal: false,
  });
  const [showDeleteModal, setShowDeleteModal] = useState<{
    dictValue?: DictPostValueType;
    showModal: boolean;
  }>({
    showModal: false,
  });

  const fetchData = () => {
    getAllDictValuesAndPosts(pageType, parentDictionaryId, postTypeId).then(
      (res) => {
        setAllValues(res.data.data);
        setShowingValues(res.data.data);
        if (res.data.types) {
          setPostTypes(res.data.types);
        }

        setAllLanguags(res.data.languages);
        setSiteDefaultLanguage(res.data.defaultLanguage);
      }
    );
  };

  useEffect(() => {
    fetchData();
  }, [postTypeId]);

  useEffect(() => {
    search();
  }, [searchPhrase]);

  const search = () => {
    if (searchPhrase) {
      const targetData = allValues.filter((dict) => {
        return JSON.stringify(dict.value)
          .toLowerCase()
          .includes(searchPhrase?.toLowerCase());
      });
      setShowingValues(targetData);
    } else {
      setShowingValues(allValues);
    }
  };

  const onOptionClick = (
    dictValue: DictPostValueType,
    selectedValue: DictValueOptionType
  ) => {
    switch (selectedValue) {
      case DictValueOptionType.Edit:
        setShowAddOrEditValueModal({ dictValue: dictValue, showModal: true });
        break;
      case DictValueOptionType.Delete:
        setShowDeleteModal({ dictValue: dictValue, showModal: true });
        break;
    }
  };

  const operationCompleted = (_isSuccess: boolean, _message: string) => {
    if (_isSuccess) {
      // getAllValues();
    }

    setShowSnackbar({
      showSnack: true,
      isSuccess: _isSuccess,
      message: _message,
    });
    fetchData();
  };

  const updateData = (
    result: DropResult,
    pageIndex: number,
    pageSize: number
  ) => {
    if (!result.destination) return;

    const updatedData = Array.from(showingValues);
    const [reOrderedFriend] = updatedData.splice(
      pageIndex * pageSize + result.source.index,
      1
    );
    updatedData.splice(
      pageIndex * pageSize + result.destination.index,
      0,
      reOrderedFriend
    );

    // setShowLoading(true);
    setShowingValues(updatedData);
  };

  return (
    <PageContainer>
      <div className="w-full">
        <div className="888:flex justify-between mx-10 py-[44px] ">
          <h3>
            {pageType === ManageValueAndPostCategoryType.ManageValue
              ? `Manage Values - ${parentDictName}`
              : "Post Category Management"}
          </h3>
          <div className="616:flex flex-wrap 888:mt-0 mt-2 justify-end">
            <SearchInput
              searchHandler={(searchedPhrase) => {
                searchedPhrase.length !== 0
                  ? setIsDraggable(true)
                  : setIsDraggable(false);
                setSearchPhrase(searchedPhrase);
              }}
              className=" mr-4 888:w-[400px]"
            />
            <Button
              className="h-10 w-[125px] mt-2 616:mt-0"
              type={ButtonType.PRIMARY}
              onClick={() => setShowAddOrEditValueModal({ showModal: true })}
            >
              <FontAwesomeIcon icon={faPlus} className="mr-1" />
              Add Value
            </Button>
          </div>
        </div>

        <Line />

        {showingValues?.length > 0 ? (
          <div className="mx-12 mt-6">
            <Table
              columns={columns}
              data={showingValues}
              siteDefaultLanguage={siteDefaultLanguage}
              allLanguags={allLanguags}
              setShowOptionsMenu={setShowOptionsMenu}
              showOptionsMenu={showOptionsMenu}
              postTypes={postTypes}
              onOptionClick={onOptionClick}
              updateData={updateData}
              pageType={pageType}
              update_order={(id, _oldOrder, _newOrder) => {
                pageType === ManageValueAndPostCategoryType.ManageValue
                  ? updateManageValueOrder(
                      dataDictionaryId!,
                      id,
                      _oldOrder,
                      _newOrder
                    )
                  : updatePostCategoryOrder(id, _oldOrder, _newOrder);
              }}
              isDragDisabled={isDraggable}
              droppableId={
                pageType === ManageValueAndPostCategoryType.ManageValue
                  ? "manageVal"
                  : "postCat"
              }
            />
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center">
            <img
              className="self-center mt-16"
              src={require("../../../../images/no_data_img.png")}
              width={572}
              height={406}
            />
            <label className="font-base text-lg">
              {pageType === ManageValueAndPostCategoryType.ManageValue
                ? "No values are added"
                : "No categories are added"}
            </label>
          </div>
        )}

        {showAddOrEditValueModal.showModal && (
          <AddOrEditValueModal
            modalType={pageType}
            languages={allLanguags}
            postTypes={postTypes}
            defaultLanguage={siteDefaultLanguage}
            dictValue={showAddOrEditValueModal.dictValue}
            onClose={() =>
              setShowAddOrEditValueModal({
                dictValue: undefined,
                showModal: false,
              })
            }
            onSave={(dictValue: DictPostValueType) => {
              if (dictValue.id === -1) {
                createDictValueAndPost(
                  pageType,
                  parentDictionaryId,
                  dictValue,
                  postTypeId,
                  dictValue.publicStyle
                ).then((res) =>
                  operationCompleted(
                    res.status === 201,
                    "Dictionary value created successfully"
                  )
                );
              } else {
                updateDictValueAndPost(
                  pageType,
                  parentDictionaryId,
                  dictValue,
                  undefined,
                  postTypeId
                ).then((res) =>
                  operationCompleted(
                    res.status === 200,
                    "Dictionary value Updated successfully"
                  )
                );
              }

              setShowAddOrEditValueModal({
                dictValue: undefined,
                showModal: false,
              });
            }}
          />
        )}

        {showDeleteModal.showModal && (
          <DeleteDictValueModal
            defaultLanguage={siteDefaultLanguage}
            dictValueType={showDeleteModal.dictValue!}
            onPositiveClicked={(modalType) => {
              if (modalType === DeleteModalType.Delete) {
                deleteDictValueAndPost(
                  pageType,
                  parentDictionaryId,
                  showDeleteModal.dictValue!.id,
                  postTypeId
                ).then((res) =>
                  operationCompleted(
                    res.status === 204,
                    "Dictionary value removed"
                  )
                );
              } else {
                // Just inactivate it
                updateDictValueAndPost(
                  pageType,
                  parentDictionaryId,
                  showDeleteModal.dictValue!,
                  true,
                  postTypeId
                ).then((res) =>
                  operationCompleted(
                    res.status === 200,
                    `${showDeleteModal.dictValue} deactivated`
                  )
                );
              }

              setShowDeleteModal({ dictValue: undefined, showModal: false });
            }}
            onNegativeClicked={() =>
              setShowDeleteModal({ dictValue: undefined, showModal: false })
            }
          />
        )}

        <Snackbar
          open={showSnackbar.showSnack}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          sx={{
            "& .MuiSnackbarContent-root": {
              backgroundColor: `${
                showSnackbar.isSuccess ? "#03A678" : "#D11C1C"
              } `,
            },
            "& .MuiSnackbarContent-message": {
              fontSize: "16px",
              fontFamily: "'Open Sans', sans-serif",
              fontWeight: "100",
            },
          }}
          onClose={() => {
            setShowSnackbar({ ...showSnackbar, showSnack: false });
          }}
        >
          <SnackbarContent message={<p>{showSnackbar.message}</p>} />
        </Snackbar>
      </div>
    </PageContainer>
  );
}

export enum DictValueOptionType {
  Edit,
  Delete,
}
