import PageContainer from "./layout/PageContainer";
import PageTitle, { Title } from "./PageTitle";
import Line from "./Line";
import MultiValues from "./inputs/MultiValues";
import { useEffect, useState } from "react";
import Button, { ButtonType } from "./Button";
import { getBlogSetting, postBlogSetting } from "../api/BlogSetting";

const BlogSetting = () => {
  const [values, setValues] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  let newArray: { title: string }[] = [];
  let newValues: string[] = [];
  useEffect(() => {
    values.map((value) => {
      newArray.push({ title: value });
    });
  }, [values]);

  useEffect(() => {
    getBlogSetting().then((response) => {
      if (response.status === 200) {
        response.data.map((value: any) => {
          newValues.push(value.title);
        });
      }
    });
  }, []);

  return (
    <PageContainer>
      <PageTitle selectedMenu={Title.siteSetting} className="top-[100px]" />
      <Line className="top-[178px]" />
      <div className="flex justify-between mt-[200px] px-10 md:pl-10 md:pr-0">
        <div className="w-full md:w-1/2 md:pr-24">
          <MultiValues
            label="categories"
            onChangeValue={(slug, val) => {
              setValues(val);
            }}
            slug=""
            values={newValues}
          />
        </div>
      </div>
      <div className="flex flex-row-reverse mt-16 pr-8">
        <Button
          showLoading={loading}
          onClick={() => {
            postBlogSetting(newArray).then((response) => {
              if (response.status === 200) {
                setLoading(false);
              }
            });
          }}
          type={ButtonType.PRIMARY}
        >
          Save
        </Button>
      </div>
    </PageContainer>
  );
};

export default BlogSetting;
