import Dropdown from "../../inputs/Dropdown";
import CmsModalMainShape, { CmsModalMainShapePublic } from "../../CmsModalMainShape";
import { getParentBlocks } from "../../../api/blocks";
import { useEffect, useState } from "react";
import { OptionType } from "../../../utils/types";

interface Props extends CmsModalMainShapePublic {
  contentId: string;
}

export default function AddBlockModal(props: Props) {
  const DEFAULT_OPTION = { key: 0, label: "No Parent" };

  const [blockOptions, setBlockOptions] = useState<OptionType[]>([]);
  const [parentOptions, setParentOptions] = useState<OptionType[]>([]);
  const [selectedBlockType, setSelectedBlockType] = useState<OptionType>(DEFAULT_OPTION);
  const [selectedParentBlock, setSelectedParentBlock] = useState<OptionType>(DEFAULT_OPTION);

  useEffect(() => {
    if (props.showModal) {
      getParentBlocks(props.contentId, (blockTypes: OptionType[], parentList: OptionType[]) => {
        setBlockOptions(blockTypes);
        parentList.unshift(DEFAULT_OPTION);
        setParentOptions(parentList);

        setSelectedBlockType(blockTypes[0]);
      });
    }
  }, [props.showModal]);

  return (
    <CmsModalMainShape
      {...props}
      title="Add Block"
      positiveBtnTitle="Add Block"
      onPositiveBtnClick={() =>
        props.onPositiveBtnClick([
          selectedParentBlock?.key === 0 ? null : selectedParentBlock?.key,
          selectedBlockType?.key as string,
        ])
      }
    >
      <h1 className="font-semibold mt-8">Parent Block *</h1>
      <Dropdown
        label=""
        options={parentOptions}
        selectedOption={selectedParentBlock}
        onOptionSelect={(option) => setSelectedParentBlock(option)}
      />

      <h1 className="font-semibold mt-6">Block Type *</h1>
      <Dropdown
        label=""
        options={blockOptions}
        selectedOption={selectedBlockType}
        onOptionSelect={(option) => setSelectedBlockType(option)}
      />
    </CmsModalMainShape>
  );
}
