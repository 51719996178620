import { useRef, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/pro-solid-svg-icons";

import { HeaderAction } from "../../utils/types";
import { EditableInput } from "../manage_content/manage_block/OrderableTree";

const BlockDetailsSectionContainer: React.FC<{
  title: string;
  editableTitle?: boolean;
  headerActions?: HeaderAction[];
  onPenButtonClick?: (newItemTag: string) => void;
  switchableActionIndex?: number;
  children?: React.ReactNode;
  className?: string;
  switchValue?: boolean;
  setSwitchValue?: (value: boolean) => void;
  collapsible?: boolean;
  collapsed?: boolean;
  nested?: boolean;
  previewSource?: string;
  previewValue?: string;
}> = ({
  title,
  editableTitle,
  headerActions,
  onPenButtonClick,
  switchableActionIndex,
  children,
  className,
  switchValue,
  setSwitchValue,
  collapsible,
  collapsed,
  nested,
  previewSource,
  previewValue,
}) => {
  const parentRef = useRef<HTMLDivElement>(null);
  const childRef = useRef<HTMLIFrameElement>(null);

  const containerRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);

  const iframeRef = useRef<HTMLIFrameElement>(null);

  const [childHeight, setChildHeight] = useState(0);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    if (childRef.current) {
      new ResizeObserver((entries) => {
        if (parentRef.current && childRef.current) {
          setChildHeight(entries[0].contentRect.height);
        }
      }).observe(childRef.current);
    }
  }, [parentRef, childRef]);

  useEffect(() => {
    if (parentRef.current && childRef.current) setParentSize();
  }, [collapsible, collapsed, childHeight]);

  useEffect(() => {
    if (previewValue && iframeRef && iframeRef.current) {
      iframeRef.current.contentWindow?.postMessage({ type: "previewValue", previewValue }, process.env.REACT_APP_TARGET_ORIGIN!);
    }
  }, [previewValue, iframeRef]);

  const setParentSize = () => {
    if (collapsible) {
      parentRef.current!.style.height = collapsed ? "0" : `${childRef.current!.clientHeight + 32}px`;
      parentRef.current!.style.padding = collapsed ? "0" : "16px";
    } else {
      parentRef.current!.style.padding = "16px";
    }
  };

  const onHeaderActionClick = (index: number, onClick: () => void) => {
    if (switchableActionIndex !== undefined && switchableActionIndex === index && switchValue === false) return;

    onClick();
  };

  const renderSectionTitle = () => {
    if (editableTitle) {
      return editMode ? (
        <EditableInput
          value={title}
          onSaveClick={(newTitle) => {
            onPenButtonClick!(newTitle);
            setEditMode(false);
          }}
          onCancelClick={() => setEditMode(false)}
        />
      ) : (
        <div className="flex items-center">
          <p className="font-medium text-black2B">{title}</p>
          <button className="text-primary ml-2 w-6 rounded-[4px]" onClick={() => setEditMode(true)}>
            <FontAwesomeIcon icon={faPen} />
          </button>
        </div>
      );
    } else {
      return (
        <div className="flex items-center">
          <p className="font-medium text-black2B">{title}</p>
          {switchValue !== undefined && setSwitchValue && (
            <ToggleSwitch className="ml-6" checked={switchValue} onClick={() => setSwitchValue(!switchValue)} />
          )}
        </div>
      );
    }
  };

  return (
    <section
      ref={containerRef}
      style={{ boxShadow: "0 4px 4px 0 rgba(0, 0, 0, 0.15)" }}
      className={`rounded-[5px] bg-white relative ${collapsible && "overflow-hidden"} ${className}`}
    >
      <header
        ref={headerRef}
        className={`flex items-center flex-wrap gap-4 justify-between rounded-t-[5px] py-[18px] px-4 ${
          nested ? "bg-secondary-400" : "bg-secondary-100"
        }`}
      >
        <div className="flex items-center">{renderSectionTitle()}</div>
        {headerActions && (
          <ul className="grid gap-2" style={{ gridTemplateColumns: `repeat(${headerActions.length}, minmax(0, 1fr))` }}>
            {headerActions.map(({ icon, className, onClick, disable }, index) => (
              <li
                key={index}
                className={`w-6 h-6 rounded-[5px] grid place-items-center text-white text-xs ${
                  switchValue === false || disable ? "bg-secondary-200 cursor-not-allowed" : " bg-primary cursor-pointer"
                }`}
                onClick={() => {
                  if (disable) return;
                  onHeaderActionClick(index, onClick);
                }}
              >
                <FontAwesomeIcon icon={icon} className={`duration-300 ${className}`} />
              </li>
            ))}
          </ul>
        )}
      </header>
      <div ref={parentRef} className={`duration-300 ${collapsible && "overflow-hidden"}`}>
        <div ref={childRef}>
          {previewSource ? (
            <iframe ref={iframeRef} src={previewSource} className="w-full h-full border min-h-[150px]" aria-disabled></iframe>
          ) : (
            <>{children}</>
          )}
        </div>
      </div>
    </section>
  );
};

export const ToggleSwitch: React.FC<{
  className?: string;
  checked: boolean;
  onClick: () => void;
}> = ({ className, checked, onClick }) => {
  return (
    <div className={`w-[34px] h-5 grid place-items-center relative cursor-pointer ${className}`} onClick={onClick}>
      <div className="w-full rounded-full h-4" style={{ background: "#d7d7d7" }} />
      <div
        className={`w-5 h-full rounded-full absolute shadow-md top-0 left-0 duration-300 ${checked ? "bg-primary" : "bg-white"} ${
          checked && "translate-x-[14px]"
        }`}
      />
    </div>
  );
};

export default BlockDetailsSectionContainer;
