import React, { useState } from "react";
import SiteThemeContainer from "./SiteThemeContainer";
import { SiteThemeType, BorderStyleType } from "../../types/types";
import BorderBlock from "./blocks/BorderBlock";
import GeneralSettingsElementDefault from "./GeneralSettingsElementDefault";

const ManageElementDefaults: React.FC<{
  onCancel: () => void;
  loading: boolean;
  siteThemeStyle: SiteThemeType;
  onSave: (style: { border: BorderStyleType; arrow: { arrowColor: string; arrowBackgroundColor: string } }) => void;
}> = ({ onCancel, loading, siteThemeStyle, onSave }) => {
  const [borderElement, setBorderElement] = useState({
    border: siteThemeStyle.elementBorder,
    arrow: { arrowColor: siteThemeStyle.arrowColor, arrowBackgroundColor: siteThemeStyle.arrowBackgroundColor },
  });

  return (
    <SiteThemeContainer
      hasLeftBorder
      loading={loading}
      onCancel={onCancel}
      onSave={() => {
        onSave(borderElement);
      }}
    >
      <GeneralSettingsElementDefault
        onChange={(value) => {
          setBorderElement({
            ...borderElement,
            arrow: { arrowColor: value.arrowColor, arrowBackgroundColor: value.arrowBackgroundColor },
          });
        }}
        style={{
          arrowBackgroundColor: borderElement.arrow.arrowBackgroundColor,
          arrowColor: borderElement.arrow.arrowColor,
        }}
      />
      <BorderBlock
        border={borderElement.border}
        borderSideOptions={siteThemeStyle.borderSideChoices}
        borderStyleOptions={siteThemeStyle.borderStyleChoices}
        borderWidthOptions={siteThemeStyle.borderWidthChoices}
        onChange={(value) => {
          setBorderElement({
            ...borderElement,
            border: {
              id: value.id,
              borderColor: value.borderColor,
              borderRadius: value.borderRadius,
              borderSideArray: value.borderSideArray,
              borderWidth: value.borderWidth,
              borderStyle: value.borderStyle,
            },
          });
        }}
        title="Border"
        className="mt-4"
      />
    </SiteThemeContainer>
  );
};

export default ManageElementDefaults;
