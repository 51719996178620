import { OptionType } from "../../utils/types";
import Checkbox from "./Checkbox";

const MultiCheckbox: React.FC<{
  slug: string;
  label?: string;
  values: OptionType[];
  value: string[];
  onChangeValue: (slug: string, value: string[]) => void;
}> = ({ slug, label, values, value, onChangeValue }) => {
  const onSingleCheckboxValueChange = (_value: boolean, key: string) => {
    if (_value) {
      if (value.indexOf(key) !== -1) return;
      onChangeValue(slug, [...value, key]);
    } else {
      onChangeValue(
        slug,
        value.filter((_key) => _key !== key)
      );
    }
  };

  return (
    <div className="flex flex-col">
      {label && <label className="text-black2B font-light text-sm capitalize mb-2">{label}</label>}
      <ul className="flex gap-4 flex-wrap">
        {values.map(({ key, label }) => (
          <Checkbox
            key={key}
            name={label}
            value={value?.indexOf(`${key}`) !== -1}
            onChange={(_value) => onSingleCheckboxValueChange(_value, `${key}`)}
          />
        ))}
      </ul>
    </div>
  );
};

export default MultiCheckbox;
