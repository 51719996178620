import { useEffect, useState, useContext } from "react";
import { useCKEditor, CKEditorEventAction } from "ckeditor4-react";
import { ManageBlockDetailContext } from "../../store/ManageBlockErrorContext";
import { LangValuePairType } from "../../utils/types";

const config = {
  extraPlugins: "colorbutton,colordialog,justify,font,lineheight,onchange,tooltip,sourcedialog,undo,fontweight",
  toolbar: [
    [
      "Bold",
      "Italic",
      "Underline",
      "Strike",
      "Subscript",
      "Superscript",
      "-",
      "TextColor",
      "BGColor",
      "-",
      "JustifyLeft",
      "JustifyCenter",
      "JustifyRight",
      "JustifyBlock",
      "-",
      "Link",
      "Unlink",
      "-",
      "Image",
      "Table",
      "SpecialChar",
      "HorizontalRule",
      "-",
      "Undo",
      "Redo",
    ],
    "/",
    ["Font", "FontSize", "fontweight", "lineheight", "-", "Tooltip", "RemoveFormat", "Sourcedialog"],
  ],
};

let richTextLanguage = "";

const RichTextInput: React.FC<{
  data: LangValuePairType[];
  setData: (data: string, richTextLanguage: string) => void;
  label: string;
  language: string;
}> = ({ data, setData, label, language }) => {
  const { reload, setReload } = useContext(ManageBlockDetailContext);

  const [element, setElement] = useState<HTMLElement | null>(null);

  const { editor } = useCKEditor({
    editorUrl: "/ckeditor/ckeditor.js",
    dispatchEvent: ({ type, payload }) => {
      if (type === CKEditorEventAction.change) {
        setData(payload.editor.getData().trim(), richTextLanguage);
      } else if (type === CKEditorEventAction.configLoaded) {
        payload.editor.setData(data.find(({ lang }) => lang === richTextLanguage)?.value);
      }
    },
    config,
    element,
  });

  useEffect(() => {
    richTextLanguage = language;
    editor?.setData(data.find(({ lang }) => lang === richTextLanguage)!.value);
  }, [language]);

  useEffect(() => {
    if (reload) {
      editor?.setData(data.find(({ lang }) => lang === richTextLanguage)!.value);
      setReload(false);
    }
  }, [reload]);

  return (
    <div className="flex flex-col">
      <label className="text-sm font-light text-black2B mb-2">{label}</label>
      <div ref={setElement} />
    </div>
  );
};

export default RichTextInput;
