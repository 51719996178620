import React from "react";
import RichTextInput from "../../../inputs/RichTextInput";
import { ToggleSwitch } from "../../../layout/BlockDetailsSectionContainer";
import { LangValuePairType } from "../../../../utils/types";

const PublicStyle: React.FC<{
  language: string;
  getRichTextData: () => LangValuePairType[];
  onChangeValue: (value: string) => void;
  switchLabel: string;
  toggleSwitchValue: boolean;
  toggleSwitchHandler: () => void;
}> = ({ language, getRichTextData, onChangeValue, switchLabel, toggleSwitchValue, toggleSwitchHandler }) => {
  return (
    <div>
      <div className="flex w-full mt-6 justify-between">
        <div className="flex items-center">
          <label className="font-light text-black2B">{switchLabel}</label>
          <ToggleSwitch className="ml-4" checked={toggleSwitchValue} onClick={() => toggleSwitchHandler()} />
        </div>
      </div>

      <label className="font-light mt-8 inline-block text-black2B">Public Styles</label>

      <RichTextInput
        key={`langauage-editor${language}`}
        data={getRichTextData()}
        setData={(data) => onChangeValue(data)}
        label=""
        language={language}
      />
    </div>
  );
};

export default PublicStyle;
