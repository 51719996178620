import Modal from "../Modal";
import { faExclamationCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button, { ButtonType } from "../Button";

interface Props {
  showModal: boolean;
  title: string;
  description: string;
  positiveButtonTitle: string;
  onPositiveClicked: () => void;
  negativeButtonTitle?: string;
  onNegativeClicked?: () => void;
  loading?: boolean;
}

export default function AreYouSureModal(props: Props) {
  return (
    <Modal showModal={props.showModal}>
      <div className="flex flex-col items-center max-w-[568px] py-8">
        <FontAwesomeIcon icon={faExclamationCircle} className="text-[90px] text-error" />

        <label className="mt-[21px] text-error">{props.title}</label>
        <p className="text-center text-[#504F4E] mt-3 whitespace-pre-line">{props.description}</p>

        <div className="flex mt-8 self-center">
          {props.negativeButtonTitle && (
            <Button className="mr-4" type={ButtonType.SECONDARY} onClick={props.onNegativeClicked!}>
              {props.negativeButtonTitle}
            </Button>
          )}
          <Button type={ButtonType.PRIMARY} onClick={props.onPositiveClicked} showLoading={props.loading}>
            {props.positiveButtonTitle}
          </Button>
        </div>
      </div>
    </Modal>
  );
}
