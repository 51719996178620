export default function NotFound() {
  return (
    <div className="absolute top-0 bottom-0 right-0 left-0">
      <div className="flex flex-col h-full items-center justify-center">
        <label className="text-9xl">404</label>
        <label className="text-5xl">Not Found!</label>
      </div>
    </div>
  );
}
