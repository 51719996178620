import API from "./_config";

export function getBlogSetting() {
  return API.get("blog-categories/")
    .then((response) => {
      return response;
    })
    .catch((e) => {
      throw e;
    });
}

export function postBlogSetting(data: any) {
  return API.post("blog-categories/", data)
    .then((response) => {
      return response;
    })
    .catch((e) => {
      throw e;
    });
}
