import React from "react";
import MultiCheckBoxLanguages from "../inputs/multiCheckBoxLanguage/MultiCheckBoxLanguages";
import Checkbox from "../inputs/Checkbox";

const LanguageSelectionBox: React.FC<{
  languages: { value: string; label: string }[];
  onChange: (input: { lang: string; status: boolean }) => void;
  selectedLanguages: string[];
  defaultLanguage: string;
}> = ({ languages, onChange, selectedLanguages, defaultLanguage }) => {

  const index = languages.findIndex((lang) => lang.value === defaultLanguage);
  const newLanguageArray = [...languages];
  newLanguageArray.splice(index, 1);

  return (
    <MultiCheckBoxLanguages languages={newLanguageArray} onChange={onChange} selectedLanguages={selectedLanguages} defaultLanguage={languages[index]}>
      <li
        className={`px-4 py-[14px] flex border-b
                  border-secondary-300 text-[13px] font-light text-black2B`}
      >
        <Checkbox onChange={() => {}} value={true} name="" isDisable />
        <span>{languages.find((lang) => lang.value === defaultLanguage)?.label}</span>
      </li>
    </MultiCheckBoxLanguages>
  );
};

export default LanguageSelectionBox;
