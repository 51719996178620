import React, { useEffect, useState } from "react";
import Checkbox from "../Checkbox";

const Row: React.FC<{
  hasBorder: boolean;
  lang: { value: string; label: string };
  onChange: (input: { lang: string; status: boolean }) => void;
  selectedLanguages: string[];
}> = ({ hasBorder, lang, onChange, selectedLanguages }) => {
  const [rowIsSelected, setRowIsSelected] = useState(false);

  useEffect(() => {
    selectedLanguages.includes(lang.value) && setRowIsSelected(true);
  }, [selectedLanguages]);

  return (
    <li
      onClick={() => {
        setRowIsSelected(!rowIsSelected);
        onChange({ lang: lang.value, status: !rowIsSelected });
      }}
      className={`px-4 py-[14px] flex ${hasBorder && "border-b"}  border-secondary-300 cursor-pointer text-[13px] font-light text-black2B`}
    >
      <Checkbox onChange={() => []} value={rowIsSelected} name="" />
      <span>{lang.label}</span>
    </li>
  );
};

export default Row;
