import { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenCircle, faCircleTrash } from "@fortawesome/pro-light-svg-icons";

const MultiValues: React.FC<{
  slug: string;
  label: string;
  values: string[];
  onChangeValue: (slug: string, value: string[]) => void;
}> = ({ slug, label, values, onChangeValue }) => {
  const [state, setState] = useState({
    targetValue: "",
    value: "",
    hasError: false,
  });
  const inputRef = useRef<HTMLInputElement>(null);

  const onAction = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!state.value) return;

    if (values.findIndex((value) => value.toLocaleLowerCase() === state.value.toLocaleLowerCase()) !== -1) {
      setState({ ...state, hasError: true });
      return;
    }

    if (state.targetValue) {
      onChangeValue(
        slug,
        values.map((value) => {
          if (value === state.targetValue) return state.value;
          return value;
        })
      );
    } else {
      onChangeValue(slug, [...values, state.value]);
    }
    setState({ ...state, value: "", targetValue: "" });
  };

  const onDelete = (value: string) => {
    onChangeValue(
      slug,
      values.filter((_value) => _value !== value)
    );
    setState({ hasError: false, value: "", targetValue: "" });
    inputRef.current!.focus();
  };

  const onEdit = (value: string) => {
    setState({ hasError: false, value: value, targetValue: value });
    inputRef.current!.focus();
  };

  const actionLabel = state.targetValue ? `change ${label}` : `add ${label}`;

  return (
    <div className="flex flex-col">
      <label className="text-black2B font-light text-sm capitalize mb-2" htmlFor={slug}>
        {label}
      </label>
      <form className="flex" onSubmit={onAction}>
        <input
          ref={inputRef}
          className={`flex-1 outline-none border-[1px] border-secondary-300 rounded-[5px] text-sm p-3 max-w-[450px] caret-primary placeholder:text-secondary-300 placeholder:capitalize focus:border-primary ${
            state.hasError && "!border-error !caret-error"
          }`}
          placeholder={`your ${label}`}
          id={slug}
          value={state.value}
          onChange={(e) => setState({ ...state, value: e.target.value, hasError: false })}
          autoComplete="off"
        />
        <button className="ml-6 capitalize text-primary font-light text-sm">{actionLabel}</button>
      </form>
      {state.hasError && <p className="mt-2 text-xs font-light text-error">This {label} already exists!</p>}
      {values.length > 0 && (
        <ul className="flex flex-wrap mt-2 gap-y-2 gap-x-2">
          {values.map((value) => (
            <Value
              key={value}
              value={value}
              onEdit={(_value) => onEdit(_value)}
              onDelete={(_value) => onDelete(_value)}
              inChangeState={value === state.targetValue}
            />
          ))}
        </ul>
      )}
    </div>
  );
};

const Value: React.FC<{
  value: string;
  onEdit: (value: string) => void;
  onDelete: (value: string) => void;
  inChangeState: boolean;
}> = ({ value, onEdit, onDelete, inChangeState }) => {
  const [hover, setHover] = useState(false);

  const actionsContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (actionsContainerRef.current) {
      actionsContainerRef.current.style.width = hover ? "56px" : "0";
    }
  }, [hover]);

  return (
    <li
      className={`flex items-center px-4 py-1 rounded text-sm text-black2B border-[1px] ${
        inChangeState ? "border-primary bg-secondary-400" : "bg-secondary-200 border-secondary-200"
      }`}
      key={value}
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <p>{value}</p>
      <div ref={actionsContainerRef} className="flex overflow-hidden w-0 duration-300">
        <FontAwesomeIcon
          icon={faPenCircle}
          className="text-[16px] ml-[16px] text-primary cursor-pointer"
          onClick={() => onEdit(value)}
        />
        <FontAwesomeIcon
          icon={faCircleTrash}
          className="text-[16px] ml-[8px] text-error cursor-pointer"
          onClick={() => onDelete(value)}
        />
      </div>
    </li>
  );
};

export default MultiValues;
