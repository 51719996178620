import React from "react";
import { Row } from "react-table";
import { DraggableProvided } from "react-beautiful-dnd";
import {
  PostTypeType,
  PostTypeOptionType,
  PostTypeAdditionalContent,
} from "./ManagePostType";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripDotsVertical } from "@fortawesome/pro-solid-svg-icons";
import { faEllipsisV } from "@fortawesome/pro-regular-svg-icons";
import { faCheck, faClose } from "@fortawesome/pro-solid-svg-icons";
import { OptionType } from "../../../../utils/types";
import OptionMenu from "./OptionMenu";
const TableRow: React.FC<{
  row: Row<PostTypeType & PostTypeAdditionalContent>;
  setShowOptionsMenu: (state: {
    dictValue?: PostTypeType & PostTypeAdditionalContent;
    showModal: boolean;
  }) => void;
  showOptionsMenu: {
    dictValue?: PostTypeType & PostTypeAdditionalContent;
    showModal: boolean;
  };
  onOptionClick: (
    dictValue: PostTypeType & PostTypeAdditionalContent,
    selectedValue: PostTypeOptionType
  ) => void;
  rowHandler: (id: number) => void;
  provided: DraggableProvided;
}> = ({
  row,
  setShowOptionsMenu,
  showOptionsMenu,
  onOptionClick,
  rowHandler,
  provided,
}) => {
  return (
    <tr
      {...provided.draggableProps}
      ref={provided.innerRef}
      onMouseDown={() => rowHandler(row.original.id)}
      className={`px-4 text-black2B border border-secondary-200 border-t-0 border-r-0 border-l-0  border-b`}
    >
      <td className="text-sm font-light pl-4 td-firefox table__cell sticky left-0">
        {row.original.name}
      </td>
      {row.original.badge && (
        <td className="text-sm font-light pl-4  td-firefox">
          {row.original.badge}
        </td>
      )}
      <td className="text-sm font-light pl-4  td-firefox">
        <FontAwesomeIcon
          icon={row.original.isActive ? faCheck : faClose}
          className={`text-lg pl-4  ${
            row.original.isActive ? "text-success" : "text-error"
          }`}
        />
      </td>

      <td className="text-sm font-light pl-4  td-firefox">
        <FontAwesomeIcon
          icon={row.original.showSummaryInPublicHeader ? faCheck : faClose}
          className={`text-lg pl-4  ${
            row.original.showSummaryInPublicHeader
              ? "text-success"
              : "text-error"
          }`}
        />
      </td>

      <td
        className="text-sm font-light pl-4 py-[13px] flex flex-row-reverse mr-3  td-firefox"
        onClick={() =>
          setShowOptionsMenu({
            dictValue: row.original,
            showModal: !showOptionsMenu.showModal,
          })
        }
        tabIndex={0}
        onBlur={() => {
          setShowOptionsMenu({ dictValue: undefined, showModal: false });
        }}
      >
        <FontAwesomeIcon
          icon={faEllipsisV}
          className=" text-xl text-gray cursor-pointer w-6 h-6"
        />
        <OptionMenu
          showOptionMenu={
            showOptionsMenu.showModal &&
            row.original.id === showOptionsMenu.dictValue?.id
          }
          onOptionClick={(selectedItem) => {
            onOptionClick(row.original, selectedItem);
          }}
        />
      </td>
    </tr>
  );
};

export default TableRow;
