import Button, { ButtonType } from "../../Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV, faPlus } from "@fortawesome/pro-regular-svg-icons";
import {
  getBlocksTree,
  reorderBlock,
  editBlockNameOrBlockParent,
  duplicateBlock,
  deleteBlock,
  updateBlock,
} from "../../../api/blocks";
import { useEffect, useState } from "react";
import { TreeModel, StatusBoxType } from "../../../types/types";
import OrderableTree, {
  DataChangesProps,
  OptionItemType,
  OptionMenu,
  TreeMode,
} from "./OrderableTree";
import AddBlockModal from "./AddBlockModal";
import ChangeParentModal from "./ChangeParentModal";
import ContentSettingsHeader, { ContentMode } from "../ContentSettingsHeader";
import { useParams, useNavigate } from "react-router-dom";
import PageContainer from "../../layout/PageContainer";
import AreYouSureModal from "../AreYouSureModal";
import { ContentSubmit } from "../../../api/content";

interface ContentData {
  title: string;
  status: StatusBoxType;
  type: string;
  isUsedMessage: string | null;
  blockListTree: Array<TreeModel>;
}

const DEFAULT_ID = -1;

const swap = (array: any[], firstIndex: number, secondIndex: number) => {
  [array[firstIndex], array[secondIndex]] = [
    array[secondIndex],
    array[firstIndex],
  ];
};

export default function ManageBlocks() {
  const { contentId } = useParams();
  const navigate = useNavigate();

  const [contentData, setContentData] = useState<ContentData>({
    title: "",
    status: StatusBoxType.PB,
    type: "Page",
    isUsedMessage: null,
    blockListTree: [],
  });
  const [showAddBlockModal, setShowAddBlockModal] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");
  const [hasDeletePermission, setHasDeletePermission] = useState(true);
  const [showAreYouSureModal, setShowAreYouSureModal] = useState<{
    show: boolean;
    blockId: number;
  }>({
    show: false,
    blockId: DEFAULT_ID,
  });
  const [showChangeParentModal, setShowChangeParentModal] = useState<{
    show: boolean;
    block: TreeModel | null;
    parentId: number;
  }>({
    show: false,
    block: null,
    parentId: DEFAULT_ID,
  });
  const [tableDataChanges, setTableDataChanges] = useState<
    DataChangesProps | undefined
  >(undefined);
  /* Only used in the content with Post Type **/
  const [postSummaryBlock, setPostSummaryBlock] = useState<TreeModel | null>(
    null
  );
  const [showSummaryOptionsMenu, setShowSummaryOptionsMenu] = useState(false);
  const [refreshPage, setRefreshPage] = useState(true);

  const isFooter = Number(contentId) === 2;
  const [dataChanged, setDataChanged] = useState(false);

  useEffect(() => {
    setRefreshPage(true);
  }, [contentId]);

  useEffect(() => {
    if (refreshPage) {
      getBlocksTree(contentId!)
        .then((res) => {
          if (res.type === "Post") {
            const postSummary = res.blockList[0];
            setPostSummaryBlock(postSummary);

            res.blockList.shift();
          }
          setContentData({
            title: res.title,
            status: res.status,
            type: res.type,
            isUsedMessage: res.isUsedMessage,
            blockListTree: res.blockList,
          });

          setRefreshPage(false);
        })
        .catch((execption) => {
          setRefreshPage(false);

          if (execption.response.status === 404) {
            navigate("/404");
          }
        });
    }
  }, [refreshPage]);

  useEffect(() => {
    setContentData({
      ...contentData,
      status:
        contentData.status === StatusBoxType.PB ||
        contentData.status === StatusBoxType.DP
          ? StatusBoxType.DP
          : StatusBoxType.DF,
    });
  }, [dataChanged]);

  const updateListLocally = (
    blockArr: Array<TreeModel>,
    blockId: number,
    newName: string
  ) => {
    return blockArr.map((block: TreeModel) => {
      if (block.id === blockId) {
        return { ...block, name: newName };
      }

      if (block.child && block.child.length > 0) {
        block.child = updateListLocally(block.child, blockId, newName);
        return block;
      }

      return block;
    });
  };

  const renderPostBlockSummary = () => {
    return (
      <div className="flex flex-col mt-6 mx-14">
        <div className="flex py-4 px-2 justify-between items-center border-b-[1px] ">
          <label className="flex font-light items-center">
            Post Summary Block
          </label>
          <div
            className="flex cursor-pointer w-[24px] text-center items-center bg-white focus:bg-secondary-300 rounded-[4px]"
            onClick={() => setShowSummaryOptionsMenu(!showSummaryOptionsMenu)}
            tabIndex={0}
            onBlur={() => setShowSummaryOptionsMenu(false)}
          >
            <div className="w-full">
              <FontAwesomeIcon
                icon={faEllipsisV}
                className="flex w-full text-xl text-gray"
              />
              <OptionMenu
                treeMode={TreeMode.BlockTree}
                showOptionMenu={showSummaryOptionsMenu}
                showHasTextSize={false}
                optionItems={[{ type: OptionItemType.Manage, title: "Manage" }]}
                onOptionClick={(selectedItem) => {
                  navigate(`${postSummaryBlock!.id}/?type=PostSummaryBlock`, {
                    state: { ParentBlock: null },
                  });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const reorderCompletedHandler = (
    sourceIndex: number,
    destinationIndex: number
  ) => {
    setDataChanged(true);
    setContentData((_contentData) => {
      const newBlockListTree = [..._contentData.blockListTree];
      swap(newBlockListTree, sourceIndex, destinationIndex);
      return { ..._contentData, blockListTree: newBlockListTree };
    });
  };

  return (
    <PageContainer>
      <div className="flex flex-col">
        <ContentSettingsHeader
          headerTitle={isFooter ? "Manage Footer Blocks" : "Manage Blocks"}
          contentId={contentId!}
          contentTitle={contentData.title}
          contentStatus={contentData.status}
          contentType={contentData.type}
          contentMode={ContentMode.ManageBlocks}
          isUsedMessage={contentData.isUsedMessage}
          blockCount={
            contentData.type === "Post"
              ? contentData.blockListTree.length + 1
              : contentData.blockListTree.length
          }
          contentStatusChanged={(newStatus: ContentSubmit) => {
            // if (newStatus === ContentSubmit.Discard) {
            setRefreshPage(true);
            // } else {
            //   navigate("/content", { replace: true });
            // }
          }}
        />

        <div className="w-full h-[8px] bg-secondary-100 mt-6" />

        <div className="flex px-12 mt-6">
          <Button
            className="font-normal"
            type={ButtonType.PRIMARY}
            onClick={() => setShowAddBlockModal(true)}
          >
            <div className="flex items-center">
              <FontAwesomeIcon
                className="mr-2 text-white cursor-pointer"
                icon={faPlus}
              />
              <h1>Add Block</h1>
            </div>
          </Button>
        </div>

        {postSummaryBlock && renderPostBlockSummary()}

        {contentData.blockListTree.length > 0 ? (
          <div className="px-12 mt-6">
            <OrderableTree
              treeMode={TreeMode.BlockTree}
              blockList={contentData.blockListTree}
              isFooter={isFooter}
              tableDataIsChanging={tableDataChanges}
              onRowMoved={(blockId, originIndex, destinationIndex) => {
                reorderBlock(
                  contentId!,
                  blockId,
                  destinationIndex,
                  originIndex,
                  () => reorderCompletedHandler(originIndex, destinationIndex)
                );
              }}
              onOptionItemClick={(
                block,
                parentId,
                selectedItem: OptionItemType
              ) => {
                switch (selectedItem) {
                  case OptionItemType.ChangeParent:
                    setShowChangeParentModal({
                      show: true,
                      block: block,
                      parentId: parentId,
                    });
                    break;
                  case OptionItemType.Duplicate:
                    duplicateBlock(contentId!, block.id, () =>
                      setRefreshPage(true)
                    );
                    break;
                  case OptionItemType.Manage:
                    navigate(`${block.id}`, {
                      state: { ParentBlock: parentId === 0 ? null : parentId },
                    });
                    break;
                  case OptionItemType.Delete:
                    if (block.isUsedMessage) {
                      setHasDeletePermission(false);
                      setWarningMessage(block.isUsedMessage);
                    } else {
                      setHasDeletePermission(true);
                      setWarningMessage("");
                    }
                    setShowAreYouSureModal({ show: true, blockId: block.id });
                    break;
                  case OptionItemType.HasTextSize:
                    updateBlock(
                      contentId!,
                      block.hasTextSize ? null : block.id
                    ).then(() => setRefreshPage(true));
                    break;
                }
              }}
              onBlockNameEdited={(blockId, newName) => {
                setTableDataChanges({
                  changedRowId: blockId,
                  dataIsChanging: true,
                });

                editBlockNameOrBlockParent(
                  contentId!,
                  blockId,
                  (statusCode) => {
                    if (statusCode === 200) {
                      // Update list with changing the name of target block locally
                      const newStatuses = updateListLocally(
                        contentData.blockListTree,
                        blockId,
                        newName
                      );

                      setContentData({
                        ...contentData,
                        blockListTree: newStatuses,
                      });

                      setTableDataChanges({
                        changedRowId: blockId,
                        dataIsChanging: false,
                      });
                    }
                  },
                  null,
                  newName
                );
              }}
            />
          </div>
        ) : (
          <img
            className="self-center mt-16"
            src={require("../../../images/no_block_available_img.png")}
            width={572}
            height={406}
          />
        )}

        {showAddBlockModal && (
          <AddBlockModal
            contentId={contentId!}
            showModal={showAddBlockModal}
            onNegativeBtnClick={() => setShowAddBlockModal(false)}
            onPositiveBtnClick={(resultData) => {
              navigate(`0/?type=${resultData![1]}`, {
                state: { ParentBlock: resultData![0] },
              });
            }}
          />
        )}

        {showChangeParentModal.show && (
          <ChangeParentModal
            contentId={contentId!}
            block={showChangeParentModal.block!}
            parentId={showChangeParentModal.parentId}
            showModal={showChangeParentModal.show}
            onNegativeBtnClick={() =>
              setShowChangeParentModal({
                show: false,
                block: null,
                parentId: DEFAULT_ID,
              })
            }
            onPositiveBtnClick={() => {
              setRefreshPage(true);
              setShowChangeParentModal({
                show: false,
                block: null,
                parentId: DEFAULT_ID,
              });
            }}
          />
        )}

        {showAreYouSureModal.show && (
          <AreYouSureModal
            showModal={showAreYouSureModal.show}
            title={hasDeletePermission ? "Delete Block!" : ""}
            description={
              hasDeletePermission
                ? "Are you sure to delete this block?"
                : warningMessage
            }
            positiveButtonTitle={hasDeletePermission ? "Delete" : "Cancel"}
            negativeButtonTitle={hasDeletePermission ? "Cancel" : ""}
            onPositiveClicked={() => {
              hasDeletePermission
                ? deleteBlock(contentId!, showAreYouSureModal.blockId, () => {
                    setShowAreYouSureModal({
                      show: false,
                      blockId: DEFAULT_ID,
                    });
                    setRefreshPage(true);
                  })
                : setShowAreYouSureModal({ show: false, blockId: DEFAULT_ID });
            }}
            onNegativeClicked={() =>
              setShowAreYouSureModal({ show: false, blockId: DEFAULT_ID })
            }
          />
        )}
      </div>
    </PageContainer>
  );
}
