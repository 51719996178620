import React, { useState } from "react";
import SiteThemeContainer from "./SiteThemeContainer";
import { SiteThemeType, TooltipStyle } from "../../types/types";
import BorderBlock from "./blocks/BorderBlock";
import PaddingBlock from "./blocks/PaddingBlock";
import ShadowBlock from "./blocks/ShadowBlock";
import GeneralSettingsTooltip from "./blocks/GeneralSettingsTooltip";

const ManageTooltip: React.FC<{
  onCancel: () => void;
  loading: boolean;
  onSave: (style: TooltipStyle) => void;
  siteThemeStyle: SiteThemeType;
}> = ({ loading, onCancel, onSave, siteThemeStyle }) => {
  const [tooltipStyle, setTooltipStyle] = useState(siteThemeStyle.tooltipStyle);

  return (
    <SiteThemeContainer hasLeftBorder loading={loading} onCancel={onCancel} onSave={() => onSave(tooltipStyle)}>
      <GeneralSettingsTooltip
        onChange={(style) =>
          setTooltipStyle({
            ...tooltipStyle,
            backgroundColor: style.color,
            maxWidth: style.maxWidth,
            position: style.position,
          })
        }
        positionOptions={siteThemeStyle.tooltipPositionChoices}
        style={{
          color: tooltipStyle.backgroundColor,
          maxWidth: tooltipStyle.maxWidth,
          position: tooltipStyle.position,
        }}
      />
      <BorderBlock
        border={tooltipStyle.border}
        borderSideOptions={siteThemeStyle.borderSideChoices}
        borderStyleOptions={siteThemeStyle.borderStyleChoices}
        borderWidthOptions={siteThemeStyle.borderWidthChoices}
        onChange={(border) => setTooltipStyle({ ...tooltipStyle, border: border })}
        title="Border"
        className="mt-4"
      />
      <PaddingBlock
        onChange={(padding) => setTooltipStyle({ ...tooltipStyle, padding: padding })}
        padding={tooltipStyle.padding}
        className="mt-4"
      />
      <ShadowBlock
        onChange={(shadow) => setTooltipStyle({ ...tooltipStyle, shadow: shadow })}
        shadowStyle={tooltipStyle.shadow}
        className="mt-4"
      />
    </SiteThemeContainer>
  );
};

export default ManageTooltip;
