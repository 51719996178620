import React, { useEffect } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown } from "@fortawesome/pro-solid-svg-icons";
import TableRow from "./TableRow";
import { ContentListType } from "../../../../types/types";
import Pagination from "../../../Pagination";

export type Column = {
  Header: string;
  accessor: any;
};

//////////////////////////////////////////////
const Table: React.FC<{
  columns: Column[];
  data: ContentListType[];
  deleteRow: (id: string) => void;
  duplicateRow: (id: string) => void;
  setAsHomePage: (id: string, status: boolean) => void;
}> = ({ columns, data, deleteRow, duplicateRow, setAsHomePage }) => {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    // @ts-ignore
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    // @ts-ignore
    canPreviousPage,
    // @ts-ignore
    canNextPage,
    // @ts-ignore
    pageCount,
    // @ts-ignore
    gotoPage,
    // @ts-ignore
    nextPage,
    // @ts-ignore
    previousPage,
    // @ts-ignore
    setPageSize,
    state,
  } = useTable(
    {
      columns,
      data,
      // @ts-ignore
      autoResetPage: false,
    },

    useSortBy,
    usePagination
  );

  useEffect(() => {
    page.length === 0 && previousPage();
  }, [page]);

  const selectPage = (_pageNum: number) => {
    gotoPage(_pageNum - 1);
  };

  const notSortableColumns = ["Language"];

  return (
    <>
      {/* table */}
      <div className="relative z-0">
        <div style={{ maxHeight: page.length * 100 + 200 }} className={` mx-auto bg-white  overflow-x-auto pb-[180px] no-scrollbar`}>
          <table {...getTableProps()} className="border-collapse w-full  ">
            <thead className="bg-secondary-200  rounded  overflow-hidden">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()} className="border-b border-gray-200 ">
                  {headerGroup.headers.map((column, index) => (
                    // Add the sorting props to control sorting. For this example
                    // we can add them into the header props

                    <th
                      scope="col"
                      className={`border-b td-firefox border-secondary-200 table__cell sticky top-0 z-10 text-sm font-normal  text-gray py-[12.3px]  tracking-wider px-4 bg-secondary-200 ${
                        index === 0 ? "left-0 z-20 " : "left-0 z-10"
                      }`}
                      // @ts-ignore
                      {...column.getHeaderProps(column.getSortByToggleProps())} //i replace this method
                    >
                      {column.render("Header") !== " " && (
                        <div onClick={() => selectPage(1)} className="flex w-fit items-center">
                          <h3 className="text-sm font-bold text-black41">{column.render("Header")}</h3>

                          {!notSortableColumns.includes(column.render("Header") as string) && (
                            <div className="flex flex-col-reverse ml-4 relative">
                              <FontAwesomeIcon
                                className={`text-sm absolute top-[3px] ${
                                  // @ts-ignore
                                  column.isSorted && column.isSortedDesc ? "text-primary" : "text-secondary-500"
                                }`}
                                icon={faSortDown}
                              />

                              <FontAwesomeIcon
                                className={`text-sm rotate-180 ${
                                  // @ts-ignore
                                  column.isSorted && !column.isSortedDesc ? "text-primary" : "text-secondary-500"
                                }`}
                                icon={faSortDown}
                              />
                            </div>
                          )}
                        </div>
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {
                // @ts-ignore
                page.map((row, i) => {
                  prepareRow(row);

                  return (
                    <TableRow
                      row={row}
                      key={row.id}
                      deleteRow={(id) => deleteRow(id)}
                      className={`${page.length - 1 === i && "mb-28"}`}
                      duplicateRow={duplicateRow}
                      setAsHomePage={setAsHomePage}
                    />
                  );
                })
              }
            </tbody>
          </table>
        </div>
        <div className="mb-4 absolute bottom-0 -translate-y-[70px] w-full">
          <Pagination
            previousPage={previousPage}
            nextPage={nextPage}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            setPageSize={setPageSize}
            gotoPage={gotoPage}
            pageCount={pageCount}
            // @ts-ignore
            pageIndex={state.pageIndex}
            // @ts-ignore
            pageSize={state.pageSize}
            pageLength={page.length}
            totalLength={data.length}
            pageName="ContentList"
            numberOfItemsArray={["5", "10", "20"]}
            selectedNumber="5"
          />
        </div>
      </div>
    </>
  );
};

export default Table;
