import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/pro-regular-svg-icons";

const isInvalidNumber = (value: string, min?: number, max?: number) =>
  (min !== undefined && +value < min!) || (max !== undefined && +value > max!);

const InputField: React.FC<{
  type: "T" | "NT" | "N" | "F" | "U" | "E";
  label: string;
  value: string;
  onChange: (value: string | null) => void;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  className?: string;
  maxLength?: number;
  min?: number;
  max?: number;
  errorMessage?: string;
  isTextArea?: boolean;
  textAreaCustomHeight?: number;
  rowNum?: number;
}> = ({
  type,
  label,
  value,
  onChange,
  placeholder,
  disabled,
  required,
  className,
  maxLength,
  min,
  max,
  errorMessage,
  isTextArea,
  textAreaCustomHeight,
  rowNum,
}) => {
  const inputClassNames =
    "px-4 py-[14px] rounded-[5px] border border-secondary-300 outline-none bg-white text-[13px] font-light text-black2b w-full placeholder-secondary-300 min-h-[50px] hover:border-primary";

  const onValueChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
    const _value = e.target.value;

    switch (type) {
      case "N":
        if (_value !== "" && !/\d/.test(_value[_value.length - 1])) {
          e.preventDefault();
        } else {
          onChange(_value === "" ? null : _value);
        }
        break;
      case "F":
        if ((_value !== "" && _value[_value.length - 1] !== "." && !/\d/.test(_value[_value.length - 1])) || isNaN(+_value)) {
          e.preventDefault();
        } else {
          onChange(_value === "" ? null : _value);
        }
        break;
      case "T":
      case "NT":
      case "U":
      case "E":
        onChange(_value);
        break;
    }
  };

  const increment = () => {
    const incrementValue = type === "F" ? 0.1 : 1;
    let _value = +value + incrementValue;
    if (type === "F") _value = +_value.toFixed(1);
    if (isInvalidNumber(`${_value}`, min, max)) return;
    onChange(`${_value}`);
  };

  const decrement = () => {
    const decrementValue = type === "F" ? 0.1 : 1;
    let _value = +value - decrementValue;
    if (type === "F") _value = +_value.toFixed(1);
    if (isInvalidNumber(`${_value}`, min, max)) return;
    onChange(`${_value}`);
  };

  const onInputBlur = () => {
    if (["T", "NT", "U", "E"].indexOf(type) !== -1) return;

    if ((value && isInvalidNumber(`${value}`, min, max)) || (!value && required)) {
      onChange(`${min}`);
    }
  };

  return (
    <div className={`flex flex-col ${className}`}>
      <label className={`text-sm font-light ${errorMessage ? "text-error" : "text-black2B"}`}>{`${label} ${required ? "*" : ""}`}</label>
      <div className="relative mt-2 h-full">
        {isTextArea ? (
          <textarea
            className={inputClassNames}
            style={{ height: textAreaCustomHeight ?? "100%", borderColor: errorMessage ? "#E55D63" : undefined }}
            placeholder={placeholder}
            value={value}
            onChange={onValueChange}
            disabled={disabled}
            maxLength={maxLength}
            onBlur={onInputBlur}
            rows={rowNum}
          />
        ) : (
          <input
            className={inputClassNames}
            style={{ borderColor: errorMessage ? "#E55D63" : undefined }}
            placeholder={placeholder}
            value={value}
            onChange={onValueChange}
            disabled={disabled}
            maxLength={maxLength}
            onBlur={onInputBlur}
          />
        )}
        {(type === "N" || type === "F") && (
          <div className="flex flex-col absolute top-1/2 right-4 -mt-4 text-primary">
            <FontAwesomeIcon icon={faAngleUp} className="cursor-pointer" onClick={increment} />
            <FontAwesomeIcon icon={faAngleDown} className="cursor-pointer" onClick={decrement} />
          </div>
        )}
      </div>
      {errorMessage && <p className="mt-2 font-light text-error text-xs">{errorMessage}</p>}
    </div>
  );
};

export default InputField;
