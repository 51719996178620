import React, { useEffect, useState } from "react";
import { capitalizeFirstLetter } from "../utils/utils";

export const DEFAULT_LANGUGAE = "de";

const LanguageBox: React.FC<{
  languagesArr: string[];
  defaultLang: string;
  onChange: (lng: string) => void;
  className?: string;
  shouldShowDefaultLanguageStar?: boolean;
}> = ({ languagesArr, defaultLang, onChange, className, shouldShowDefaultLanguageStar = true }) => {
  const [selectedLanguage, setSelectedLanguage] = useState<string>(defaultLang);

  useEffect(() => {
    setSelectedLanguage(defaultLang);
  }, [defaultLang]);

  useEffect(() => {
    onChange(selectedLanguage.toLowerCase());
  }, [selectedLanguage]);

  useEffect(() => {
    if (languagesArr.length === 1) {
      setSelectedLanguage(languagesArr[0]);
    }
  }, [languagesArr]);

  return (
    <ul className={`flex w-fit flex-wrap rounded-[5px] ${className} bg-white items-center border-[#C4C4C4] border-[1px]`}>
      {languagesArr.map((lang) => {
        return (
          <li
            key={lang}
            className={`flex w-[40px] h-[40px] items-center justify-center cursor-pointer first:rounded-l last:rounded-r last:border-r-[0px] border-r-[1px] border-[#C4C4C4] ${
              selectedLanguage.toUpperCase() === lang.toUpperCase() ? "bg-primary" : "white"
            } ${selectedLanguage.toUpperCase() === lang.toUpperCase() ? "text-white" : "text-black"}`}
            onClick={() => setSelectedLanguage(lang)}
          >
            {`${capitalizeFirstLetter(lang)}${shouldShowDefaultLanguageStar && lang === defaultLang ? "*" : ""}`}
          </li>
        );
      })}
    </ul>
  );
};

export default LanguageBox;
