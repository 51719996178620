import { useState, useEffect, useContext } from "react";
import { faPalette } from "@fortawesome/pro-regular-svg-icons";

import { BlockDetailsFieldType, ImageValueType, OptionType } from "../../utils/types";
import { ManageBlockDetailContext } from "../../store/ManageBlockErrorContext";
import { RowData } from "../cms_table/CmsTable";
import { getTargetField } from "../../utils/utils";
import Dropdown from "./Dropdown";
import BlockDetailsSectionContainer from "../layout/BlockDetailsSectionContainer";
import BlockDetailsSection from "../manage_block_detail/BlockDetailsSection";

const DropdownObj: React.FC<{
  field: BlockDetailsFieldType;
  onChangeValue: (slug: string, value: string | number | boolean | ImageValueType | RowData[] | string[] | null) => void;
  blockDetailsAllItems: BlockDetailsFieldType[];
  onPaletteIconClick: (fields: BlockDetailsFieldType[]) => void;
  setHoverFieldValues?: (field: BlockDetailsFieldType) => void;
}> = ({ field, onChangeValue, blockDetailsAllItems, onPaletteIconClick, setHoverFieldValues }) => {
  const [relatedObjs, setRelatedObjs] = useState<BlockDetailsFieldType[]>([]);

  const { addSlug, removeSlug } = useContext(ManageBlockDetailContext);

  const { slug, name, value, values, isSearchable } = field;

  useEffect(() => {
    const _relatedObjs: BlockDetailsFieldType[] = [];

    relatedObjs.forEach(({ slug }) => removeSlug(slug));

    (values! as OptionType[])
      .find(({ key }) => key === value)
      ?.relatedObjs?.forEach((relatedObj) => {
        const targetField = getTargetField(blockDetailsAllItems, relatedObj);
        targetField && _relatedObjs.push(targetField);
      });

    _relatedObjs.forEach(({ errorMessage, slug }) => {
      if (errorMessage) addSlug(slug);
    });

    setRelatedObjs(_relatedObjs);
  }, [value, blockDetailsAllItems]);

  const renderRelatedObj = (item: BlockDetailsFieldType) => {
    const switchField = item.fields?.find(({ type }) => type === "switch");

    if (item.type === "component") {
      return (
        <BlockDetailsSectionContainer
          key={item.slug}
          title={item.name}
          headerActions={
            item.fields && item.fields.filter(({ isStyle }) => isStyle).length > 0
              ? [
                  {
                    icon: faPalette,
                    onClick: () => {
                      if (onPaletteIconClick) onPaletteIconClick(item.fields!.filter(({ isStyle }) => isStyle));
                    },
                  },
                ]
              : []
          }
          switchableActionIndex={0}
          switchValue={switchField ? (switchField.value as boolean) : undefined}
          setSwitchValue={switchField ? (value) => onChangeValue(switchField.slug, value) : undefined}
          collapsible={switchField != undefined}
          collapsed={(switchField?.value as boolean) === false}
          nested
        >
          <BlockDetailsSection
            item={item}
            onChangeValue={onChangeValue}
            onPaletteIconClick={onPaletteIconClick}
            setHoverFieldValues={setHoverFieldValues}
            expandParentOnDropdownShowOptions={switchField != undefined}
          />
        </BlockDetailsSectionContainer>
      );
    } else {
      return <BlockDetailsSection key={item.slug} item={item} onChangeValue={onChangeValue} />;
    }
  };

  return (
    <>
      <Dropdown
        options={values! as OptionType[]}
        onOptionSelect={(option) => onChangeValue(slug, option.key)}
        selectedOption={(values! as OptionType[]).find(({ key }) => key === value) ?? null}
        label={name}
        isSearchable={isSearchable}
      />
      {relatedObjs.length > 0 && (
        <ul className="grid grid-cols-1 gap-4 mt-4">{relatedObjs.map((relatedObj) => renderRelatedObj(relatedObj))}</ul>
      )}
    </>
  );
};

export default DropdownObj;
