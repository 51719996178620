import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/pro-regular-svg-icons";
import { useNavigate } from "react-router-dom";
const TableSetting: React.FC<{ id: string }> = ({ id }) => {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  return (
    <div
      onMouseOver={() => setIsHovered(true)}
      onMouseOut={() => setIsHovered(false)}
      onTouchStart={() => setIsHovered(true)}
      onTouchEnd={() => setIsHovered(false)}
      className="text-center hover:bg-gray-100 rounded relative duration-100 mr-1"
    >
      <FontAwesomeIcon onClick={() => navigate(`/content/${id}/blocks/`)} icon={faGear} className="cursor-pointer  text-gray text-[19px] p-1" />

      <div
        className={`bg-white absolute p-1 shadow w-[90px] text-xs top-0 left-0 -translate-x-[94px] -translate-y-[8px] rounded duration-100 ${
          isHovered ? "visible" : "invisible"
        }`}
      >
        Manage Blocks
      </div>
    </div>
  );
};

export default TableSetting;
