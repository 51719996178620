import React, { useState } from "react";
import BlockBox from "./BlockBox";
import Dropdown from "../../inputs/Dropdown";
import ColorPicker from "../../inputs/ColorPicker";
import InputField from "../../inputs/InputField";
import { BorderStyleType, BorderWidth, BorderStyle, DropdownChoice } from "../../../types/types";
import CheckBoxInput from "../../inputs/checkboxInput/CheckBoxInput";

const BorderBlock: React.FC<{
  title: string;
  className?: string;
  border: BorderStyleType | undefined;
  onChange: (borderStyle: BorderStyleType) => void;
  borderStyleOptions: DropdownChoice[];
  borderWidthOptions: DropdownChoice[];
  borderSideOptions: DropdownChoice[];
}> = ({ className, border, onChange, borderStyleOptions, borderWidthOptions, borderSideOptions, title }) => {
  const [color, setColor] = useState(border?.borderColor!);
  const [borderStyleState, setBorderStyleState] = useState(border);

  return (
    <BlockBox className={className} title={title}>
      <div className="flex w-full justify-between flex-wrap">
        {borderStyleOptions && (
          <div className="w-[238px]">
            <Dropdown
              options={borderStyleOptions}
              onOptionSelect={(value) => {
                setBorderStyleState({ ...borderStyleState!, borderStyle: value.key as BorderStyle });
                onChange({ ...borderStyleState!, borderStyle: value.key as BorderStyle });
              }}
              selectedOption={borderStyleOptions.find((key) => key.key === border?.borderStyle) || null}
              label="Border Style"
            />
          </div>
        )}
        <div className="w-[238px] ">
          <ColorPicker
            color={color}
            label="Border Color"
            setColor={(color) => {
              setColor(color);
              setBorderStyleState({ ...borderStyleState!, borderColor: color });
              onChange({ ...borderStyleState!, borderColor: color.toString() });
            }}
          />
        </div>

        {borderSideOptions && (
          <div className="w-[238px]">
            <CheckBoxInput
              label="Border Side"
              options={borderSideOptions}
              onChange={(input) => {
                setBorderStyleState({ ...borderStyleState!, borderSideArray: input });
                onChange({ ...borderStyleState!, borderSideArray: input });
              }}
              borderSideArray={border?.borderSideArray}
            />
          </div>
        )}
        {borderWidthOptions && (
          <div className="w-[238px]">
            <Dropdown
              options={borderWidthOptions}
              onOptionSelect={(value) => {
                setBorderStyleState({ ...borderStyleState!, borderWidth: value.key as BorderWidth });
                onChange({ ...borderStyleState!, borderWidth: value.key as BorderWidth });
              }}
              selectedOption={borderWidthOptions.find((key) => border?.borderWidth === key.key) || null}
              label="Border Width"
            />
          </div>
        )}
      </div>

      <BlockBox title="Border Radius" className="mt-4">
        <div className="flex flex-wrap justify-between">
          <InputField
            required
            min={0}
            type="N"
            value={borderStyleState?.borderRadius.bottomLeft!}
            onChange={(value) => {
              setBorderStyleState({
                ...borderStyleState!,
                borderRadius: { ...borderStyleState!.borderRadius, bottomLeft: value! },
              });
              onChange({
                ...borderStyleState!,
                borderRadius: { ...borderStyleState!.borderRadius, bottomLeft: value! },
              });
            }}
            label="Bottom Left (pixel)"
            className="w-[234px]"
          />
          <InputField
            required
            min={0}
            type="N"
            value={borderStyleState?.borderRadius.bottomRight!}
            onChange={(value) => {
              setBorderStyleState({
                ...borderStyleState!,
                borderRadius: { ...borderStyleState!.borderRadius, bottomRight: value! },
              });
              onChange({
                ...borderStyleState!,
                borderRadius: { ...borderStyleState!.borderRadius, bottomRight: value! },
              });
            }}
            label="Bottom Right (pixel)"
            className="w-[234px]"
          />
          <InputField
            required
            min={0}
            type="N"
            value={borderStyleState?.borderRadius.topLeft!}
            onChange={(value) => {
              setBorderStyleState({
                ...borderStyleState!,
                borderRadius: { ...borderStyleState!.borderRadius, topLeft: value! },
              });
              onChange({ ...borderStyleState!, borderRadius: { ...borderStyleState!.borderRadius, topLeft: value! } });
            }}
            label="Top Left (pixel)"
            className="w-[234px]"
          />
          <InputField
            required
            min={0}
            type="N"
            value={borderStyleState?.borderRadius.topRight!}
            onChange={(value) => {
              setBorderStyleState({
                ...borderStyleState!,
                borderRadius: { ...borderStyleState!.borderRadius, topRight: value! },
              });
              onChange({ ...borderStyleState!, borderRadius: { ...borderStyleState!.borderRadius, topRight: value! } });
            }}
            label="Top Right (pixel)"
            className="w-[234px]"
          />
        </div>
      </BlockBox>
    </BlockBox>
  );
};

export default BorderBlock;
