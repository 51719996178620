import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays } from "@fortawesome/pro-solid-svg-icons";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

export interface ChildrenInputProps {
  inputValidation: (validationError?: string) => void;
  onDateChanged: (selectedDate: Date) => void;
  placeHolder: string;
  className?: string;
  resetIsEnable: boolean;
  defaultDate?: Date;
}
export default function DateInput(props: ChildrenInputProps) {
  const [selectedDate, setSelectedDate] = useState<Date | null>(props.defaultDate ?? null);
  const [showDatePicker, setShowDatePicker] = useState(false);
  useEffect(() => {
    setSelectedDate(props.defaultDate || null);
  }, [props.resetIsEnable]);

  return (
    <div className={`hover:border-primary rounded border border-secondary-300 flex flex-row-reverse ${props.className}`}>
      <div
        onClick={() => setShowDatePicker(true)}
        className="w-[51px]  bg-secondary-200 rounded-r  flex items-center justify-center cursor-pointer"
      >
        <FontAwesomeIcon icon={faCalendarDays} className="text-[#868686]" />
      </div>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          open={showDatePicker}
          onClose={() => setShowDatePicker(false)}
          InputAdornmentProps={{ style: { display: "none" } }}
          inputFormat="DD.MM.YYYY"
          value={selectedDate}
          onChange={(newValue) => {
            if (newValue) {
              const newDate = new Date(newValue);
              setSelectedDate(newDate);

              if (props.onDateChanged) {
                props.onDateChanged(newDate);
              }
            }
          }}
          renderInput={(params) => (
          
            <input
              ref={params.inputRef} 
              className="outline-none  w-[164.7px] pl-4 rounded-l cursor-pointer"
              onClick={() => setShowDatePicker(true)}
              value={params.inputProps?.value}
              placeholder={props.placeHolder}
            ></input>
          )}
        />
      </LocalizationProvider>
    </div>
  );
}
