import { useEffect, useState } from "react";
import FormContainer from "./FormContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash } from "@fortawesome/pro-light-svg-icons";
import LoginContainer from "./LoginContainer";
import { useParams } from "react-router-dom";
import { resetPasswordValidation, changePassword } from "../../api/auth";
import useRecaptchaV3 from "./reCaptchaV3/useRecaptchaV3";
import { useNavigate } from "react-router-dom";

const ChangePassword = () => {
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const [emailIsValid, setEmailIsValid] = useState<boolean>();
  const [loading, setLoading] = useState(false);
  const [submitIsClicked, setSubmitIsClicked] = useState(false);

  const { uid, hashToken } = useParams<{ uid: string; hashToken: string }>();

  const RECAPTCHA_SITE_KEY = "6LeFlVkoAAAAACXmpvL0bnTMhADka00VW4EhC0p1";
  const executeRecaptcha = useRecaptchaV3(RECAPTCHA_SITE_KEY);

  const navigate = useNavigate();

  const submitHandler = async (newPassword: string, repeatPassword: string) => {
    password.length >= 8 && password === repeatPassword && setLoading(true);
    setSubmitIsClicked(true);
    const recaptchaToken = await executeRecaptcha("resetPassword");
    password === repeatPassword &&
      password.length >= 8 &&
      changePassword(uid as string, hashToken as string, { token: recaptchaToken, new_password: newPassword })
        .then((payload) => {
          if (payload.status === 204) {
            navigate("/login");
          }
        })
        .finally(() => {
          setLoading(false);
        });
  };
  useEffect(() => {
    resetPasswordValidation(uid as string, hashToken as string)
      .then((payload) => {
        if (payload.status === 200) {
          setEmailIsValid(true);
        } else {
          setEmailIsValid(false);
        }
      })
      .finally(() => {});
  }, [uid, hashToken]);
  if (emailIsValid === undefined) {
    return null;
  }
  return (
    <>
      {emailIsValid ? (
        <LoginContainer>
          <FormContainer
            loading={loading}
            submitHandler={() => submitHandler(password, repeatPassword)}
            buttonText="Change Password"
            disableButton={password === "" || repeatPassword === ""}
          >
            <h1 className="text-center text-3xl font-bold mt-12">Change Password</h1>
            <form onSubmit={() => submitHandler(password, repeatPassword)} className="mt-8">
              <div className="relative">
                <label htmlFor="password" className="text-base ">
                  New Password *
                </label>
                <input
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  id="password"
                  type={`${showPassword ? "text" : "password"}`}
                  placeholder="password"
                  className="border  w-full mt-2 h-11 px-1 outline-0 border-secondary-300 rounded"
                ></input>
                <FontAwesomeIcon
                  onClick={() => setShowPassword(!showPassword)}
                  icon={faEyeSlash}
                  className="text-xs cursor-pointer absolute text-secondary-300 translate-y-6 -translate-x-6"
                />
              </div>
              <div className="mt-4">
                <label htmlFor="repeatedPassword" className="text-base">
                  Repeat New Password *
                </label>
                <div className="relative">
                  <input
                    value={repeatPassword}
                    onChange={(e) => setRepeatPassword(e.target.value)}
                    type={`${showRepeatPassword ? "text" : "password"}`}
                    id="repeatedPassword"
                    placeholder="repeated Password"
                    className="border mt-2 p-1 w-full h-11 outline-0  border-secondary-300 rounded"
                  ></input>
                  <FontAwesomeIcon
                    onClick={() => setShowRepeatPassword(!showRepeatPassword)}
                    icon={faEyeSlash}
                    className="text-xs cursor-pointer absolute text-secondary-300 translate-y-6 -translate-x-6"
                  />
                </div>
                {password !== repeatPassword && submitIsClicked && (
                  <div className="text-red-500">Passwords are not match</div>
                )}
                {password.length < 8 && submitIsClicked && (
                  <div className="text-red-500">Password must be at least 8 characters.</div>
                )}
              </div>
            </form>
          </FormContainer>
        </LoginContainer>
      ) : (
        <LoginContainer>
          <div className="h-screen flex justify-end text-black font-extrabold text-4xl items-center">
            <div className="w-[500px] text-center">The URL is either incorrect or no longer valid.</div>
          </div>
        </LoginContainer>
      )}
    </>
  );
};

export default ChangePassword;
