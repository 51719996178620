import React, { useState } from "react";
import BlockBox from "./BlockBox";
import InputField from "../../inputs/InputField";
import Dropdown from "../../inputs/Dropdown";
import { DropdownChoice } from "../../../types/types";
import Checkbox from "../../inputs/Checkbox";

export type GeneralSettingsBlock = {
  isCentralizedInMobile: boolean;
  margin: string;
  maxHeight: null | string;
  maxWidth: null | string;
  minHeight: string | null;
};

const GeneralSettingsBlockBlock: React.FC<{
  className?: string;
  marginChoices: DropdownChoice[];
  style: GeneralSettingsBlock;
  onChange: (style: GeneralSettingsBlock) => void;
}> = ({ className, style, onChange, marginChoices }) => {
  const [styleState, setStyleState] = useState(style);

  return (
    <BlockBox title="General Settings" className={className}>
      <div className="flex justify-between flex-wrap">
        <InputField
          type="N"
          value={styleState.maxHeight!}
          onChange={(value) => {
            setStyleState({ ...styleState, maxHeight: value });
            onChange({ ...styleState, maxHeight: value });
          }}
          label="Max Height"
          className="w-[234px]"
        />
        <InputField
          type="N"
          value={styleState.minHeight!}
          onChange={(value) => {
            setStyleState({ ...styleState, minHeight: value });
            onChange({ ...styleState, minHeight: value });
          }}
          label="Min Height"
          className="w-[234px]"
        />
        <InputField
          min={1}
          type="N"
          value={styleState.maxWidth!}
          onChange={(value) => {
            setStyleState({ ...styleState, maxWidth: value });
            onChange({ ...styleState, maxWidth: value });
          }}
          label="Max Width"
          className="w-[234px]"
        />
        {marginChoices && (
          <div className="w-[234px]">
            <Dropdown
              options={marginChoices}
              onOptionSelect={(value) => {
                setStyleState({ ...styleState, margin: value.key as string });
                onChange({ ...styleState, margin: value.key as string });
              }}
              selectedOption={marginChoices.find((key) => style.margin === key.key) || null}
              label="Margin"
            />
          </div>
        )}
        <div className="w-[234px] mt-6">
          <Checkbox
            name="Is Centralized In Mobile"
            value={styleState.isCentralizedInMobile}
            onChange={(value) => {
              setStyleState({ ...styleState, isCentralizedInMobile: value });
              onChange({ ...styleState, isCentralizedInMobile: value });
            }}
          />
        </div>
      </div>
    </BlockBox>
  );
};

export default GeneralSettingsBlockBlock;
