import ManageValueAndPostType from "./postType/manageValue/ManagePostType";

const PostType = () => {
  return (
    <div className="flex w-full">
      <ManageValueAndPostType />
    </div>
  );
};

export default PostType;
