import React, { useState } from "react";
import BlockBox from "./BlockBox";
import { Direction, DropdownChoice, GradientBackgroundType } from "../../../types/types";
import ColorPicker from "../../inputs/ColorPicker";
import InputField from "../../inputs/InputField";
import Dropdown from "../../inputs/Dropdown";

const GradientBackground: React.FC<{
  gradientBackgroundStyle: GradientBackgroundType;
  onChange: (gradientBackgroundStyle: GradientBackgroundType) => void;
  directionChoices: DropdownChoice[];
  className?: string;
}> = ({ gradientBackgroundStyle, onChange, directionChoices, className }) => {
  const [style, setStyle] = useState(gradientBackgroundStyle);

  return (
    <BlockBox className={className} title="GradientBackground">
      <div className="flex justify-between flex-wrap">
        <div className="w-[238px] ">
          <ColorPicker
            color={style.color1}
            label="Color1"
            setColor={(color) => setStyle({ ...style, color1: color })}
          />
        </div>
        <div className="w-[238px] ">
          <ColorPicker
            color={style.color2}
            label="Color2"
            setColor={(color) => setStyle({ ...style, color2: color })}
          />
        </div>

        <InputField
          max={1}
          min={0}
          type="F"
          value={style.opacity1 ? style.opacity1 : ""}
          onChange={(value) => {
            setStyle({ ...style, opacity1: value });
            onChange({ ...style, opacity1: value });
          }}
          label="Opacity1 *"
          className="w-[234px]"
        />
        <InputField
          max={1}
          min={0}
          type="F"
          value={style.opacity2 ? style.opacity2 : ""}
          onChange={(value) => {
            setStyle({ ...style, opacity2: value });
            onChange({ ...style, opacity2: value });
          }}
          label="Opacity2 *"
          className="w-[234px]"
        />
      </div>
      {directionChoices && (
        <div className="w-[238px] mt-4">
          <Dropdown
            options={directionChoices}
            onOptionSelect={(value) => {
              setStyle({ ...style, direction: value.key as Direction });
              onChange({ ...style, direction: value.key as Direction });
            }}
            selectedOption={directionChoices.find((key) => style.direction === key.key) || null}
            label="Direction"
          />
        </div>
      )}
    </BlockBox>
  );
};

export default GradientBackground;
