import React, { useEffect, useState } from "react";
import Checkbox from "../Checkbox";
import { OptionType } from "../../../utils/types";
const Row: React.FC<{
  option: OptionType;
  onChange: (input: { state: string; status: boolean }) => void;
  selectedBorderSides: string[] | undefined;
}> = ({ option, onChange, selectedBorderSides }) => {
  const [isChecked, setIsChecked] = useState(false);
  useEffect(() => {
    onChange({ state: option.key as string, status: isChecked });
  }, [isChecked, option]);
  useEffect(() => {
    selectedBorderSides && selectedBorderSides.includes(option.key as string) && setIsChecked(true);
  }, [selectedBorderSides, option]);
  return (
    <li className="px-4 py-[14px] border-secondary-300 cursor-pointer text-[13px] font-light text-black2B">
      <div className="flex" onClick={() => setIsChecked(!isChecked)}>
        <Checkbox value={isChecked} onChange={() => {}} name="" />
        {option.label}
      </div>
    </li>
  );
};

export default Row;
