import React, { useState } from "react";

export const ManageBlockDetailContext = React.createContext<{
  language: string;
  setLanguage: React.Dispatch<React.SetStateAction<string>>;
  hasErrorSlugs: string[];
  addSlug: (slug: string) => void;
  removeSlug: (slug: string) => void;
  reload: boolean;
  setReload: React.Dispatch<React.SetStateAction<boolean>>;
}>({
  language: "",
  setLanguage: () => {},
  hasErrorSlugs: [],
  addSlug: () => {
    //
  },
  removeSlug: () => {
    //
  },
  reload: false,
  setReload: () => {
    //
  },
});

export const ManageBlockDetailContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [hasErrorSlugs, setHasErrorSlugs] = useState<string[]>([]);
  const [language, setLanguage] = useState("");
  const [reload, setReload] = useState(false);

  const addSlug = (slug: string) =>
    setHasErrorSlugs((_hasErrorSlugs) => {
      if (_hasErrorSlugs.indexOf(slug) === -1) return [..._hasErrorSlugs, slug];
      return _hasErrorSlugs;
    });

  const removeSlug = (slug: string) =>
    setHasErrorSlugs((_hasErrorSlugs) => {
      if (_hasErrorSlugs.indexOf(slug) === -1) return _hasErrorSlugs;
      return _hasErrorSlugs.filter((_slug) => _slug !== slug);
    });

  return (
    <ManageBlockDetailContext.Provider
      value={{
        language,
        setLanguage,
        hasErrorSlugs,
        addSlug,
        removeSlug,
        reload,
        setReload,
      }}
    >
      {children}
    </ManageBlockDetailContext.Provider>
  );
};

export default ManageBlockDetailContextProvider;
