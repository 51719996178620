import PageContainer from "../../layout/PageContainer";
import BreadCrumbAndHeader from "./BreadCrumbAndHeader";
import { useParams } from "react-router-dom";
import FileDataBox from "./FileDataBox";
import UploadBox from "./UploadBox";
import { useEffect, useState } from "react";
import { getFiles } from "../../../api/fileManager";
import { Snackbar, SnackbarContent } from "@mui/material";
import { FileDataType } from "../../../types/types";

const EditFile = () => {
  const [data, setData] = useState<FileDataType>({
    isUsed: "",
    fileName: "",
    type: "",
    uploadDate: "",
    numberOfUsage: 0,
    alternativeText: "",
    size: "",
    url: "",
  });
  const [snackbarState, setSnackbarState] = useState({ message: "", showSnackbar: false });
  const { fileId } = useParams();
  useEffect(() => {
    getFiles(`${fileId}/`).then((resp) => {
      setData({
        url: resp.data.url,
        isUsed: resp.data.numberOfUsage > 0 ? "true" : "false",
        alternativeText: resp.data.alternativeText,
        fileName: resp.data.name,
        numberOfUsage: resp.data.numberOfUsage,
        size: resp.data.size,
        type: resp.data.type,
        uploadDate: resp.data.uploadDate,
      });
    });
  }, [fileId]);

  return (
    <PageContainer>
      <Snackbar
        open={snackbarState.showSnackbar}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "#03A678",
          },
          "& .MuiSnackbarContent-message": {
            fontSize: "16px",
            fontFamily: "'Open Sans', sans-serif",
            fontWeight: "100",
          },
        }}
        onClose={() => {
          setSnackbarState({ ...snackbarState, showSnackbar: false });
        }}
      >
        <SnackbarContent message={snackbarState.message} />
      </Snackbar>
      <BreadCrumbAndHeader fileName={data.fileName} />
      <div className="w-full bg-secondary-100  h-[8px] mt-6" />
      <div className="mx-12 mt-6 bg-secondary-100 pt-6 1304:flex flex-col 1304:flex-row 1304:justify-between items-center 1304:items-start min-h-[478px pb-6">
        <FileDataBox data={data} />
        <UploadBox
          id={fileId!}
          respHandler={(resp) => {
            setSnackbarState({ message: "File Edited Successfully", showSnackbar: true });
            setData(resp);
          }}
          data={data}
        />
      </div>
    </PageContainer>
  );
};

export default EditFile;
