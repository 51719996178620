export enum LoadingMode {
  Button,
  Normal,
}

type Props = {
  loadingMode: LoadingMode;
  style?: React.CSSProperties;
};

export default function Loading(props: Props) {
  const getColor = `${
    props.loadingMode === LoadingMode.Button ? "border-gray border-t-gray/40" : "border-primary border-t-primary/40"
  }`;
  const getSize = `${
    props.loadingMode === LoadingMode.Button
      ? "w-[20px] h-[20px] border-[4px] border-t-[4px]"
      : "w-[96px] h-[96px] border-[12px] border-t-[12px]"
  }`;

  return <div style={props.style} className={`${getSize} bg-white rounded-[50%] animate-spin ${getColor}`} />;
}
