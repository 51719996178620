import React, { useState, useRef, useEffect } from "react";
import { SketchPicker } from "react-color";

const RGBAToHex = (r: number, g: number, b: number) => {
  let rHex = r.toString(16);
  let gHex = g.toString(16);
  let bHex = b.toString(16);

  if (rHex.length === 1) rHex = "0" + rHex;
  if (gHex.length === 1) gHex = "0" + gHex;
  if (bHex.length === 1) bHex = "0" + bHex;

  return `#${rHex}${gHex}${bHex}`;
};

const REGEX = /^#([0-9a-f]{3}){1,2}$/i;
export const isValidHexColor = (hexColor: string) => {
  if (hexColor) return REGEX.test(hexColor);
  return true;
};

const isWhite = (color: string) => {
  color = color.toLocaleLowerCase();
  return color === "#fff" || color === "#ffffff";
};

const ColorPicker: React.FC<{
  label: string;
  color: string;
  setColor: (color: string) => void;
  hasError?: boolean;
}> = ({ label, color, setColor, hasError }) => {
  const [showColorPicker, setShowColorPicker] = useState(false);

  const colorPickerContainerRef = useRef<HTMLDivElement>(null);
  const colorInputContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleOutSideClick = (event: MouseEvent) => {
      if (
        !colorPickerContainerRef.current?.contains(event.target as Node) &&
        !colorInputContainerRef.current?.contains(event.target as Node)
      ) {
        setShowColorPicker(false);
      }
    };

    window.addEventListener("click", handleOutSideClick);

    return () => {
      window.removeEventListener("click", handleOutSideClick);
    };
  }, [colorPickerContainerRef, colorInputContainerRef]);

  return (
    <div className="relative flex flex-col">
      <label className="text-sm font-light text-black2B">{label}</label>
      <div ref={colorInputContainerRef} className="relative mt-2 max-w-[234px]">
        <input
          value={color}
          onChange={(e) => setColor(e.target.value)}
          onBlur={() => hasError && setColor("")}
          onClick={() => setShowColorPicker((_showColorPicker) => !_showColorPicker)}
          className={`border rounded-[5px] text-black2B px-4 py-[10px] text-sm w-full h-full font-light bg-white min-h-[50px] outline-none ${
            hasError ? "border-error" : "border-secondary-300"
          }`}
        />
        {!hasError && (
          <div
            className={`w-6 h-6 rounded-[4px] absolute right-4 top-[13px] ${color !== "" && "border-[1px]"}`}
            style={{ backgroundColor: color, borderColor: isWhite(color) ? "#D2CEC6" : color }}
          />
        )}
      </div>
      {showColorPicker && (
        <div ref={colorPickerContainerRef} className="absolute left-0 top-full z-10">
          <SketchPicker color={color} onChange={(_color) => setColor(RGBAToHex(_color.rgb.r, _color.rgb.g, _color.rgb.b))} disableAlpha />
        </div>
      )}
    </div>
  );
};

export default ColorPicker;
