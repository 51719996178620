import React from "react";

const Line: React.FC<{ className: string }> = ({ className }) => {
  return (
    <div
      className={`border border-t-0 border-b-secondary-100 border-b-8 w-full fixed z-30 max-w-[1550px] mx-auto ${className}`}
    ></div>
  );
};

export default Line;
