import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangle } from "@fortawesome/pro-solid-svg-icons";

import { HeaderLinkType } from "../../../types/types";

const LinksMenu: React.FC<{ showMenu: boolean; links: HeaderLinkType[]; listClicked: () => void }> = ({
  showMenu,
  links,
  listClicked,
}) => {
  const [subMenuClicked, setSubMenuClicked] = useState(false);

  return (
    <>
      {showMenu && (
        <div className=" absolute top-[100px] bottom-0 left-0 right-0 bg-secondary-400 h-screen z-50 border border-l-0 border-r-0 border-b-0 border-t-primary">
          <ul className="pl-4">
            {links.map((link, i) => (
              <li className="py-2 flex justify-between" key={i}>
                <div className="w-full mr-4">
                  {link.linkAddress ? (
                    <Link className="inline-block  w-full hover:bg-primary" to={link.linkAddress}>
                      {link.linkName}
                    </Link>
                  ) : (
                    <div
                      onClick={() => setSubMenuClicked(!subMenuClicked)}
                      className="inline-block  w-full hover:bg-primary font-semibold cursor-pointer"
                    >
                      {link.linkName}
                    </div>
                  )}
                  {subMenuClicked && (
                    <ul>
                      {link.subMenu?.length !== 0 &&
                        link.subMenu?.map((menu, i) => (
                          <li className="py-1 w-full hover:bg-primary" onClick={() => setSubMenuClicked(false)} key={i}>
                            <Link className=" inline-block w-full" to={menu.linkAddress} onClick={listClicked}>
                              {menu.linkName}
                            </Link>
                          </li>
                        ))}
                    </ul>
                  )}
                </div>
                {link.subMenu?.length !== 0 && (
                  <FontAwesomeIcon
                    onClick={() => setSubMenuClicked(!subMenuClicked)}
                    icon={faTriangle}
                    className={`text-base mr-2  ${subMenuClicked ? "rotate-180" : "rotate-90"}`}
                  />
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

export default LinksMenu;
