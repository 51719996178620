import { TreeModel } from "../types/types";
import { OptionType } from "../utils/types";
import API from "./_config";

export function getBlocksTree(contentId: string) {
  return API.get(`content/${contentId}/blocks/`)
    .then((response) => {
      return {
        title: response.data.title,
        status: response.data.status,
        type: response.data.type,
        isUsedMessage: response.data.isUsedMessage,
        blockList: response.data.blocks as Array<TreeModel>,
      };
    })
    .catch((e) => {
      throw e;
    });
}

export function editBlockNameOrBlockParent(
  contentId: string,
  blockId: number,
  onResponse: (statusCode: number) => void,
  newParentId: string | number | null,
  newName?: string
) {
  const body: any = {};
  if (newName) {
    body.name = newName;
  } else {
    body.parent = newParentId ?? null;
  }

  API.patch(`content/${contentId}/blocks/${blockId}/`, body)
    .then((response) => {
      onResponse(response.status);
    })
    .catch((e) => {
      throw e;
    });
}

export function reorderBlock(
  contentId: string,
  blockId: number,
  newOrder: number,
  oldOrder: number,
  reorderCompleted: () => void
) {
  const body = {
    new_order: newOrder,
    old_order: oldOrder,
  };
  return API.post(`content/${contentId}/blocks/${blockId}/reorder/`, body)
    .then((response) => {
      if (response.status === 200) {
        reorderCompleted();
      }
    })
    .catch((e) => {});
}

export function getParentBlocks(
  contentId: string,
  onResponse: (blockTypes: OptionType[], parentList: OptionType[]) => void
) {
  return API.get(`dropdowns/?type=${contentId}`)
    .then((response) => {
      onResponse(response.data.blockTypes, response.data.parentList);
    })
    .catch((e) => {});
}

export function duplicateBlock(
  contentId: string,
  blockId: number,
  onResponse: () => void
) {
  return API.post(`content/${contentId}/blocks/${blockId}/duplicate/`)
    .then((response) => {
      onResponse();
    })
    .catch((e) => {});
}

export function deleteBlock(
  contentId: string,
  blockId: number,
  onResponse: () => void
) {
  return API.delete(`content/${contentId}/blocks/${blockId}/`)
    .then((response) => {
      onResponse();
    })
    .catch((e) => {});
}

export function updateBlock(contentId: string, blockId: number | null) {
  const body = {
    text_size_block: blockId,
  };
  return API.patch(`content/${contentId}/`, body);
}
