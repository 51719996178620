import ManagePostTypeFields from "./postType/fieldManagement/manageValue/ManagePostTypeFields";

const PostTypeFields = () => {
  return (
    <div className="flex w-full">
      <ManagePostTypeFields />
    </div>
  );
};

export default PostTypeFields;
