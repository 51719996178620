import React, { useState } from "react";
import BlockBox from "./BlockBox";
import ColorPicker from "../../inputs/ColorPicker";
import Dropdown from "../../inputs/Dropdown";
import { DropdownChoice, ColorPattern } from "../../../types/types";

type ColorStyle = { primaryBackgroundColor: string; secondaryBackgroundColor: string; colorPattern: ColorPattern };

const ColorBLock: React.FC<{
  className: string;
  colorPatternChoices: DropdownChoice[];
  colorPattren: ColorPattern;
  colorStyle: ColorStyle;
  onChange: (colorStyle: ColorStyle) => void;
}> = ({ className, colorPatternChoices, colorPattren, colorStyle, onChange }) => {
  const [colorState, setColorState] = useState(colorStyle);

  return (
    <BlockBox title="Color" className={className}>
      <div className="flex justify-between flex-wrap">
        <div className="w-[238px]">
          <ColorPicker
            color={colorState.primaryBackgroundColor}
            label="Primary Background Color"
            setColor={(color) => {
              setColorState({ ...colorState, primaryBackgroundColor: color });
              onChange({ ...colorState, primaryBackgroundColor: color });
            }}
          />
        </div>
        <div className="w-[238px]">
          <ColorPicker
            color={colorState.secondaryBackgroundColor}
            label="Secondary Background Color"
            setColor={(color) => {
              setColorState({ ...colorState, secondaryBackgroundColor: color });
              onChange({ ...colorState, secondaryBackgroundColor: color });
            }}
          />
        </div>
        <div className="w-[238px]">
          <Dropdown
            onOptionSelect={(option) => {
              setColorState({ ...colorState, colorPattern: option.key as ColorPattern });
              onChange({ ...colorState, colorPattern: option.key as ColorPattern });
            }}
            options={colorPatternChoices}
            selectedOption={colorPatternChoices.find((key) => colorPattren === key.key) || null}
            label="Color Pattern"
          />
        </div>
      </div>
    </BlockBox>
  );
};

export default ColorBLock;
