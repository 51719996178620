import React from "react";
import MenuItem from "../../../../MenuItem";
import { PostTypeOptionType } from "./ManagePostTypeFields";

const OptionMenu: React.FC<{
  showOptionMenu: boolean;
  onOptionClick: (selectedItem: PostTypeOptionType) => void;
}> = ({ showOptionMenu, onOptionClick }) => {
  return (
    <div className="relative">
      <div
        className={`absolute z-10 ${
          showOptionMenu ? "block" : "hidden"
        } right-[5%]`}
      >
        <ul className="w-36 wrounded shadow bg-white py-1">
          <MenuItem
            className="py-2"
            titleKey="Edit"
            onClick={() => onOptionClick(PostTypeOptionType.Edit)}
          />
          <MenuItem
            className="py-2"
            titleKey="Delete"
            onClick={() => onOptionClick(PostTypeOptionType.Delete)}
          />
        </ul>
      </div>
    </div>
  );
};

export default OptionMenu;
