import { useCallback, useEffect, useState } from "react";

const useRecaptchaV3 = (sitekey: string) => {
  const [isRecaptchaReady, setIsRecaptchaReady] = useState(false);
  useEffect(() => {
    if ((window as any).grecaptcha) {
      setIsRecaptchaReady(true);
    } else {
      const script = document.createElement("script");
      script.src = `https://www.google.com/recaptcha/api.js?render=${sitekey}`;
      script.async = true;
      document.head.appendChild(script);
      script.onload = () => {
        setIsRecaptchaReady(true);
      };
    }
  }, [sitekey]);

  const executeRecaptcha = useCallback(
    async (action: any) => {
      if (isRecaptchaReady && (window as any).grecaptcha) {
        return await (window as any).grecaptcha.execute(sitekey, { action: action });
      }
    },
    [isRecaptchaReady, sitekey]
  );

  return executeRecaptcha;
};

export default useRecaptchaV3;
