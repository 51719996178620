import { SlugValuePairType } from "../utils/types";
import API from "./_config";

export const getBlockDetails = async (contentId: string, blockId: string, type: string) => {
  return API.get(`content/${contentId}/blocks/${blockId}/?type=${type}`)
    .then((response) => {
      return JSON.parse(JSON.stringify(response.data));
    })
    .catch((error) => {
      return error;
    });
};

export const addBlock = async (contentId: string, body: SlugValuePairType[]) => {
  return API.post(`content/${contentId}/blocks/`, body)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const dropdownAPIChange = async (contentId: string, blockId: string, body: SlugValuePairType[]) => {
  return API.post(`content/${contentId}/blocks/${blockId}/related-fields/`, body)
    .then((response) => {
      return JSON.parse(JSON.stringify(response.data));
    })
    .catch((error) => {
      throw error;
    });
};

export const editBlock = async (contentId: string, blockId: string, body: SlugValuePairType[], isPostSummaryBlock: boolean) => {
  let url = `content/${contentId}/blocks/${blockId}/`;
  if (isPostSummaryBlock) url += "?type=PostSummaryBlock";

  return API.put(url, body)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const blockPreview = async (contentId: string, body: SlugValuePairType[], language: string) => {
  return API.post(`content/${contentId}/block-preview/`, body, {
    headers: {
      "accept-language": language,
    },
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};
