import React, { useEffect, useRef, useState, ChangeEvent } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/pro-regular-svg-icons";

const NumberInput: React.FC<{
  className: string;
  internalLable: string;
  onChange: (input: string) => void;
  defaultValue: string;
  id: string;
}> = ({ className, internalLable, onChange, defaultValue, id }) => {
  const [value, setValue] = useState(defaultValue);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.setAttribute("type", "text");
    }
  }, []);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const numericValue = e.target.value.replace(/[^0-9]/g, "");
    setValue(numericValue);
    onChange(Number(numericValue).toString());
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      switch (event.key) {
        case "ArrowUp":
          setValue((Number(value) + 1).toString());
          onChange((Number(value) + 1).toString());
          // Handle the Arrow Up key press
          break;
        case "ArrowDown":
          setValue(Math.max(Number(value) - 1, 0).toString());
          onChange(Math.max(Number(value) - 1, 0).toString());
          // Handle the Arrow Down key press
          break;
        default:
          break;
      }
    };
    const inputElement = inputRef.current;
    if (inputElement) {
      inputElement.addEventListener("keydown", handleKeyDown);
    }
    // Cleanup the event listener on component unmount
    return () => {
      if (inputElement) {
        inputElement.removeEventListener("keydown", handleKeyDown);
      }
    };
  }, [inputRef, value]);

  return (
    <div
      className={`w-[144px] h-[43px] hover:border-primary rounded border border-secondary-300  ${className} overflow-hidden flex bg-white relative`}
    >
      <label htmlFor={`numberInput${id}`} className="flex items-center ml-4 text-[#D2CEC6]">
        {internalLable}
      </label>
      <input
        id={`numberInput${id}`}
        ref={inputRef}
        value={value}
        onChange={handleInputChange}
        className="w-full h-full outline-none border-none  pr-12"
        style={{ direction: "rtl", WebkitAppearance: "none", MozAppearance: "textfield" }}
        type="number"
      ></input>

      <div className="flex items-center absolute top-1/2 right-0 -translate-y-1/2 -translate-x-[6px] ">
        <div>KB</div>
        <div className="flex flex-col ml-1 text-primary">
          <FontAwesomeIcon
            icon={faAngleUp}
            className="cursor-pointer"
            onClick={() => {
              setValue((Number(value) + 1).toString());
              onChange((Number(value) + 1).toString());
            }}
          />
          <FontAwesomeIcon
            icon={faAngleDown}
            className="cursor-pointer"
            onClick={() => {
              setValue(Math.max(Number(value) - 1, 0).toString());
              onChange(Math.max(Number(value) - 1, 0).toString());
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default NumberInput;
