import React, { useEffect, useState } from "react";
import { useTable } from "react-table";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";
import { faPen, faCirclePlus, faCircleMinus, faGripDotsVertical } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TreeModel } from "../../../types/types";
import MenuItem from "../../MenuItem";
import { faEllipsisV, faCheck, faClose } from "@fortawesome/pro-regular-svg-icons";
import Popup from "reactjs-popup";
import Loading, { LoadingMode } from "../../Loading";

const DND_ITEM_TYPE = "row";

export enum TreeMode {
  BlockTree,
  MenuTree,
}

export enum OptionItemType {
  ChangeParent,
  Manage,
  Duplicate,
  Delete,
  HasTextSize,
}

export type OptionItem = {
  type: OptionItemType;
  title: string;
};

export interface DataChangesProps {
  dataIsChanging: boolean;
  changedRowId: number; // => blockId for our case
}

interface RowProps {
  row: any;
  index: number;
  treeMode: TreeMode;
  moveRow: (dragIndex: number, hoverIndex: number) => void;
  block: TreeModel;
  parentId: number;
  expandedBlocksId: Array<number>;
  onRowExpanded: (blockId: number) => void;
  onNameEdited: (blockId: number, newName: string) => void;
  onOptionClick: (block: TreeModel, parentId: number, selectedItem: OptionItemType) => void;
  dataChangesProps?: DataChangesProps;
  isFooter?: boolean;
}

interface PublicTreeProps {
  treeMode: TreeMode;
  blockList: Array<TreeModel>;
  isFooter?: boolean;
  tableDataIsChanging?: DataChangesProps;
  parentId?: number;
  onRowMoved?: (blockId: number, originIndex: number, destinationIndex: number) => void;
  onOptionItemClick: (block: TreeModel, parentId: number, selectedItem: OptionItemType) => void;
  onBlockNameEdited?: (blockId: number, newName: string) => void;
}

export default function OrderableTree(props: PublicTreeProps) {
  const [records, setRecords] = useState<Array<TreeModel>>([]);
  const [expandedBlocksId, setExpandedBlocksId] = useState<Array<number>>([]);

  useEffect(() => {
    setRecords(props.blockList);
  }, [props.blockList]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Blocks",
        columns: [
          {
            Header: "Name",
            accessor: "name",
          },
        ],
      },
    ],
    []
  );

  const getRowId = React.useCallback((row: any) => {
    return row.id;
  }, []);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    data: records,
    columns,
    getRowId,
  });

  const moveRow = (dragIndex: number, hoverIndex: number) => {
    const dragRecord = records[dragIndex];
    setRecords(
      update(records, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRecord],
        ],
      })
    );

    if (props.onRowMoved) props.onRowMoved(dragRecord.id, dragIndex, hoverIndex);
  };

  const onRowExpanded = (blockId: number) => {
    const finalBlockIdsArr = [...expandedBlocksId];

    if (finalBlockIdsArr.includes(blockId)) {
      // Remove blockId from array => for collapsing the row
      const idIndex = finalBlockIdsArr.indexOf(blockId);
      finalBlockIdsArr.splice(idIndex, 1);
    } else {
      // Add blockId to array => for expanding the row
      finalBlockIdsArr.push(blockId);
    }
    setExpandedBlocksId(finalBlockIdsArr);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <table
        {...getTableProps()}
        className="flex flex-col"
        style={{ marginLeft: props.parentId ? 48 : 0 }}
        id="block_tree"
      >
        {props.treeMode === TreeMode.MenuTree && !props.parentId && (
          <thead className="flex bg-secondary-200 px-6 py-3">
            <label className="flex-1 text-gray">Menu Item Title</label>
            <label className="flex-1 text-gray">URL</label>
          </thead>
        )}
        <tbody {...getTableBodyProps()} className="w-full text-center">
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <div className="flex flex-col" key={`${i}_${records[i].id}`}>
                <Row
                  index={i}
                  row={row}
                  treeMode={props.treeMode}
                  moveRow={(dragIndex, hoverIndex) => moveRow(dragIndex, hoverIndex)}
                  {...row.getRowProps()}
                  block={records[i]}
                  parentId={props.parentId ?? 0} // The 0 meaning we are in the ROOT!
                  isFooter={props.isFooter}
                  expandedBlocksId={expandedBlocksId}
                  dataChangesProps={props.tableDataIsChanging}
                  onRowExpanded={onRowExpanded}
                  onNameEdited={(blockId, newName) => {
                    props.onBlockNameEdited?.(blockId, newName);
                  }}
                  onOptionClick={props.onOptionItemClick}
                />
                {expandedBlocksId.includes(records[i].id) && (
                  <OrderableTree
                    treeMode={props.treeMode}
                    blockList={records[i].child}
                    parentId={records[i].id}
                    isFooter={props.isFooter}
                    onRowMoved={(blockId, dragIndex, hoverIndex) => {
                      if (props.onRowMoved) props.onRowMoved(blockId, dragIndex, hoverIndex);
                    }}
                    onOptionItemClick={props.onOptionItemClick}
                    onBlockNameEdited={(blockId, newName) => {
                      if (props.treeMode === TreeMode.BlockTree) {
                        props.onBlockNameEdited?.(blockId, newName);
                      }
                    }}
                    tableDataIsChanging={props.tableDataIsChanging}
                  />
                )}
              </div>
            );
          })}
        </tbody>
      </table>
    </DndProvider>
  );
}

export const EditableInput = (props: {
  value: string;
  onSaveClick: (newName: string) => void;
  onCancelClick: () => void;
}) => {
  const [name, setName] = useState(props.value);

  return (
    <div className="flex items-center flex-wrap gap-2">
      <input
        className="outline-none border-secondary-300  border-[1px] rounded-[8px] py-2 px-4"
        value={name}
        onChange={(event) => setName(event.target.value)}
      />
      <div className="flex">
        <FontAwesomeIcon
          className="text-[20px] text-success mx-4 cursor-pointer"
          icon={faCheck}
          onClick={() => props.onSaveClick(name)}
        />
        <FontAwesomeIcon
          className="text-[20px] text-error cursor-pointer"
          icon={faClose}
          onClick={() => props.onCancelClick()}
        />
      </div>
    </div>
  );
};

const Row: React.FC<RowProps> = ({
  row,
  index,
  treeMode,
  moveRow,
  block,
  parentId,
  isFooter,
  expandedBlocksId,
  dataChangesProps,
  onRowExpanded,
  onNameEdited,
  onOptionClick,
}) => {
  const dropRef = React.useRef<HTMLTableRowElement>(null);
  const dragRef = React.useRef<HTMLTableCellElement>(null);
  const [showOptionsMenu, setShowOptionsMenu] = useState(false);
  const [isRowEditable, setIsRowEditable] = useState(false);

  const [, drop] = useDrop({
    accept: DND_ITEM_TYPE,
    hover(item: any, monitor) {
      if (parentId !== item.parentId) {
        return;
      }

      if (!dropRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = dropRef.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset!.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveRow(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
    canDrop: (item) => parentId === item.parentId, // the rows belongs to same parent table so we have drop permission!
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: DND_ITEM_TYPE,
    item: { type: DND_ITEM_TYPE, index, parentId: parentId, blockId: block.id },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;

  preview(drop(dropRef));
  drag(dragRef);

  return (
    <tr
      className="flex justify-between border-b-[1px] border-secondary-300 h-[48px] items-center px-4"
      style={{ opacity }}
      ref={dropRef}
    >
      <div className="flex items-center w-full">
        <td ref={dragRef}>
          <FontAwesomeIcon icon={faGripDotsVertical} className="cursor-pointer font-[12px] text-primary" />
        </td>

        {treeMode === TreeMode.BlockTree ? (
          <div className="flex items-center">
            {row.cells.map((cell: any) => {
              return (
                <td className="flex ml-3 font-light items-center" {...cell.getCellProps()}>
                  {block.child.length > 0 && (
                    <FontAwesomeIcon
                      className="text-primary mr-3 cursor-pointer"
                      icon={expandedBlocksId.includes(block.id) ? faCircleMinus : faCirclePlus}
                      onClick={() => onRowExpanded(block.id)}
                    />
                  )}
                  {isRowEditable ? (
                    <EditableInput
                      value={block.name}
                      onSaveClick={(newName: string) => {
                        onNameEdited(block.id, newName);
                        setIsRowEditable(false);
                      }}
                      onCancelClick={() => setIsRowEditable(false)}
                    />
                  ) : (
                    cell.render("Cell")
                  )}
                </td>
              );
            })}

            {!isRowEditable && (
              <td className="flex text-primary mr-2 cursor-pointer ml-3" onClick={() => setIsRowEditable(true)}>
                {dataChangesProps?.dataIsChanging && dataChangesProps.changedRowId === block.id ? (
                  <Loading style={{ marginLeft: 16 }} loadingMode={LoadingMode.Button} />
                ) : (
                  <Popup
                    position="right center"
                    on={["hover"]}
                    trigger={
                      <div className="w-6 hover:bg-secondary-100 rounded-[4px]">
                        <FontAwesomeIcon icon={faPen} />
                      </div>
                    }
                  >
                    <label className="bg-secondary-400 px-2 py-1 rounded-[4px] font-light">
                      You can change the name of each block
                    </label>
                  </Popup>
                )}
              </td>
            )}
          </div>
        ) : (
          <div className="flex items-center w-full">
            <tr className="flex w-full">
              <td className="flex ml-3 font-light items-center">
                <FontAwesomeIcon
                  className={`mr-3 ${block.child.length > 0 ? "text-primary cursor-pointer" : "text-[#AAAAAA]"}`}
                  icon={expandedBlocksId.includes(block.id) ? faCircleMinus : faCirclePlus}
                  onClick={() => {
                    if (block.child.length > 0) {
                      onRowExpanded(block.id);
                    }
                  }}
                />
              </td>

              <td>
                <label className="text-black2B font-light">{block.name}</label>
              </td>
              <td className="flex-1 pr-24">
                <label className="text-black2B font-light">{block.url ?? "URL"}</label>
              </td>
            </tr>
          </div>
        )}
      </div>
      <td
        className="flex cursor-pointer w-[24px] text-center items-center bg-white focus:bg-secondary-300 rounded-[4px]"
        onClick={() => setShowOptionsMenu(!showOptionsMenu)}
        tabIndex={0}
        onBlur={() => {
          setTimeout(() => {
            setShowOptionsMenu(false);
          }, 200);
        }}
      >
        <div className="w-full">
          <FontAwesomeIcon icon={faEllipsisV} className="flex w-full text-xl text-gray" />
          <OptionMenu
            showHasTextSize={Boolean(isFooter) && block.type === "SimpleBlock"}
            alreadyHasTextSize={block.hasTextSize}
            treeMode={treeMode}
            showOptionMenu={showOptionsMenu}
            onOptionClick={(selectedItem) => onOptionClick(block, parentId, selectedItem)}
          />
        </div>
      </td>
    </tr>
  );
};

export interface OptionMenuProps {
  treeMode: TreeMode;
  showOptionMenu: boolean;
  onOptionClick: (selectedItem: OptionItemType) => void;
  showHasTextSize: boolean;
  alreadyHasTextSize?: boolean;
  optionItems?: Array<OptionItem>;
}

export const OptionMenu = (props: OptionMenuProps) => {
  const blockTreeOptionMenuItems: Array<OptionItem> = [
    { type: OptionItemType.Manage, title: "Manage" },
    { type: OptionItemType.ChangeParent, title: "Change Parent" },
    { type: OptionItemType.Duplicate, title: "Duplicate" },
    { type: OptionItemType.Delete, title: "Delete" },
  ];
  if (props.showHasTextSize) {
    blockTreeOptionMenuItems.push({
      type: OptionItemType.HasTextSize,
      title: props.alreadyHasTextSize ? "Unset Text Size" : "Set Text Size",
    });
  }

  const menuTreeOptionMenuItems: Array<OptionItem> = [
    { type: OptionItemType.ChangeParent, title: "Edit & Change Parent" },
    { type: OptionItemType.Delete, title: "Delete" },
  ];

  const optionMenuItems = props.optionItems
    ? props.optionItems
    : props.treeMode === TreeMode.BlockTree
    ? blockTreeOptionMenuItems
    : menuTreeOptionMenuItems;

  return (
    <div className="relative">
      <div className={`absolute z-10 ${props.showOptionMenu ? "block" : "hidden"} right-[5%]`}>
        <ul className="w-36 wrounded shadow py-1 bg-white text-start">
          {optionMenuItems.map((item) => (
            <MenuItem
              key={item.title}
              className="py-2"
              titleKey={item.title}
              onClick={() => props.onOptionClick(item.type)}
            />
          ))}
        </ul>
      </div>
    </div>
  );
};
