import { StatusBoxType } from "../types/types";
import { BlockDetailsFieldType, SlugValuePairType } from "./types";

export const isValidURL = (url: string) => {
  const pattern = new RegExp(
    "^([a-zA-Z]+:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR IP (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$", // fragment locator
    "i"
  );
  if (pattern.test(url)) {
    try {
      const _url = new URL(url);
      return ["http:", "https:", "ftp:", "ftps:"].indexOf(_url.protocol) !== -1;
    } catch (e) {
      return false;
    }
  }
  return false;
};

export const isValidEmail = (email: string) => {
  const EMAIL_REGEX = /^([a-z0-9._-]+)@([a-z0-9._-]+)\.([a-z]{2,8})(\.[a-z]{2,8})?$/;
  return EMAIL_REGEX.test(email.trim().toLocaleLowerCase());
};

export const getTargetField = (fields: BlockDetailsFieldType[], slug: string) => {
  let targetField: BlockDetailsFieldType;
  for (const field of fields) {
    if (field.slug === slug) return field;
    targetField = getTargetField(field.fields ?? [], slug) as BlockDetailsFieldType;
    if (targetField) return targetField;
  }
};

export const getOrderableItem = (fields: BlockDetailsFieldType[]) => {
  let orderableFiled: BlockDetailsFieldType;
  for (const field of fields) {
    if (field.isOrderable) return field;
    orderableFiled = getOrderableItem(field.fields ?? []) as BlockDetailsFieldType;
    if (orderableFiled) return orderableFiled;
  }
};

export const getTargetFieldParent = (fields: BlockDetailsFieldType[], slug: string) => {
  let targetFieldParent: BlockDetailsFieldType;
  for (const field of fields) {
    if (field.fields && field.fields.findIndex((field) => field.slug === slug) !== -1) {
      return field;
    }

    targetFieldParent = getTargetFieldParent(field.fields ?? [], slug) as BlockDetailsFieldType;
    if (targetFieldParent) return targetFieldParent;
  }
};

export const setRequiredFields = (fields: BlockDetailsFieldType[], requiredFields: BlockDetailsFieldType[]) => {
  for (const field of fields) {
    if (field.required && !field.isStyle && ["itemList", "component"].indexOf(field.type) === -1 && field.slug.indexOf("#") === -1)
      requiredFields.push(field);

    if (field.fields) {
      setRequiredFields(field.fields, requiredFields);
    }
  }
};

export const blockAddedItemSetSlug = (id: string, field: BlockDetailsFieldType, nestedId?: string) => {
  field.slug = field.slug.replace("#", id);
  if (nestedId) field.slug = field.slug.replace("##", nestedId);
  if (field.fields) {
    for (const _field of field.fields) {
      blockAddedItemSetSlug(id, _field, nestedId);
    }
  }
};

export const blockDetailGetSlugValuePairs = (fields: BlockDetailsFieldType[], slugValuePairs: SlugValuePairType[]) => {
  for (const field of fields) {
    if (field.slug.includes("empty")) continue;

    if (["style", "component", "itemList", "tabStyle"].indexOf(field.type) === -1) {
      if (field.value && typeof field.value === "object" && "id" in field.value) {
        slugValuePairs.push({ slug: field.slug, value: field.value.id });
      } else {
        slugValuePairs.push({ slug: field.slug, value: field.value });
      }
    }

    if (field.fields) blockDetailGetSlugValuePairs(field.fields, slugValuePairs);
  }
};

export const getStatusTypeStr = (type: StatusBoxType) => {
  switch (type) {
    case StatusBoxType.AL:
      return "All";
    case StatusBoxType.DF:
      return "Draft";
    case StatusBoxType.PB:
    case StatusBoxType.DP:
      return "Published";
  }
};

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const redirectToLogin = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("refresh");
  localStorage.removeItem("loginTime");
  localStorage.removeItem("myapp-email");
  localStorage.removeItem("myapp-password");
  window.location.pathname = "/login";
};

export const characterSpliter = (input: string, number: number) => {
  if (input.length > number) {
    return input.slice(0, number) + "...";
  } else {
    return input;
  }
};
