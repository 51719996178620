import axios from "axios";
import { redirectToLogin } from "../utils/utils";

const API = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_API,
  headers: {
    "accept-language": "en",
  },
});

API.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token === null) {
    return config;
  }
  config.headers.Authorization = `Bearer ${token}`;
  config.timeout = 150000;
  return config;
});

API.interceptors.response.use(
  (response) => {
    return response;
  },

  async (error) => {
    const originalRequest = error.config;
    // If the error status is 401 and there is no originalRequest._retry flag,
    // it means the token has expired and we need to refresh it

    if (originalRequest.url !== "token/refresh/" && error.response) {
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        try {
          const refreshToken = localStorage.getItem("refresh");
          const response = await API.post("token/refresh/", { refresh: refreshToken });
          const { access } = response!.data;
          localStorage.setItem("token", access);
          //Retry the original request with the new token
          originalRequest.headers.Authorization = `Bearer ${access}`;
          return axios(originalRequest);
        } catch (error) {
          // Handle refresh token error or redirect to login
          return redirectToLogin();
        }
      }
    }

    if (error.code === "ECONNABORTED" && error.message.includes("timeout")) {
      console.log("Request timed out");
    }
    return Promise.reject(error);
  }
);

export default API;
