import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/pro-regular-svg-icons";

import { OptionType } from "../../utils/types";

const Dropdown: React.FC<{
  options: OptionType[];
  selectedOption: OptionType | null;
  onOptionSelect: (option: OptionType) => void;
  label?: string;
  placeholder?: string;
  className?: string;
  required?: boolean;
  horizontal?: boolean;
  isSearchable?: boolean;
  expandParentOnShowOptions?: boolean;
  disabled?: boolean;
}> = ({
  options,
  selectedOption,
  onOptionSelect,
  label,
  placeholder,
  className,
  required,
  horizontal,
  isSearchable,
  expandParentOnShowOptions,
  disabled = false,
}) => {
  const [{ showOptions, filteredOptions, inputValue, optionsHover }, setState] = useState({
    showOptions: false,
    filteredOptions: options,
    inputValue: selectedOption?.label || "",
    optionsHover: false,
  });

  const optionsRef = useRef<HTMLUListElement>(null);
  const parentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (parentRef?.current && expandParentOnShowOptions)
      parentRef.current.style.marginBottom = showOptions ? `${optionsRef.current!.getBoundingClientRect().height}px` : "0";
  }, [optionsRef, parentRef, showOptions, expandParentOnShowOptions]);

  useEffect(() => {
    setState((_state) => ({ ..._state, filteredOptions: options, inputValue: selectedOption?.label || "" }));
  }, [options, selectedOption]);

  const _setFilteredOptions = (searchTerm: string) => {
    if (!searchTerm) {
      setState((_state) => ({ ..._state, inputValue: searchTerm, showOptions: true, filteredOptions: options }));
    } else {
      const _filteredOptions = options.filter(({ label }) => label.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1);
      if (_filteredOptions.length === 0) {
        setState((_state) => ({
          ..._state,
          inputValue: searchTerm,
          filteredOptions: _filteredOptions,
          showOptions: false,
        }));
      } else {
        setState((_state) => ({
          ..._state,
          showOptions: true,
          inputValue: searchTerm,
          filteredOptions: _filteredOptions,
        }));
      }
    }
  };

  const onOptionClick = (option: OptionType) => {
    if (disabled) return;
    setState((_state) => ({ ..._state, showOptions: false, inputValue: option.label, optionsHover: false }));
    onOptionSelect(option);
  };

  const onInputValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (disabled) return;
    if (isSearchable) {
      const _inputValue = e.target.value.trim();
      _setFilteredOptions(_inputValue);
    } else {
      e.preventDefault();
    }
  };

  const onInputClick = () => {
    if (isSearchable) {
      _setFilteredOptions(inputValue);
    } else {
      setState((_state) => ({ ..._state, showOptions: true, filteredOptions: options }));
    }
  };

  const selectedOptionLabelColor = selectedOption ? "text-black2b" : "text-secondary-300";

  return (
    <div
      ref={parentRef}
      className={className}
      style={{
        display: "flex",
        flexDirection: horizontal ? "row" : "column",
        alignItems: horizontal ? "center" : "stretch",
      }}
    >
      <label className="text-sm font-light text-black2B">{`${label} ${required ? "*" : ""}`}</label>
      <div
        className={`relative ${horizontal ? "ml-2 flex-1" : "mt-2"}`}
        tabIndex={0}
        onBlur={() => {
          if (optionsHover) return;
          setState((_state) => ({ ..._state, showOptions: false }));
        }}
      >
        <input
          className={`w-full px-4 py-[14px] rounded-[5px] border border-secondary-300 cursor-pointer bg-white text-[13px] text-black2B font-light min-h-[50px] outline-none hover:border-primary ${selectedOptionLabelColor} ${
            !isSearchable && "caret-transparent"
          } ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
          value={inputValue}
          onChange={onInputValueChange}
          onClick={onInputClick}
          placeholder={placeholder}
          disabled={options.length === 0 || disabled}
        />
        <FontAwesomeIcon
          icon={faAngleDown}
          className={`absolute right-4 top-1/2 -mt-2 text-secondary-300 duration-300 ${showOptions ? "rotate-180" : "rotate-0"}`}
        />
        {showOptions && (
          <ul
            ref={optionsRef}
            className="absolute top-full w-full mt-1 border border-secondary-300 rounded-[5px] max-h-40 overflow-y-scroll z-10"
            onMouseOver={() => setState((_state) => ({ ..._state, optionsHover: true }))}
            onMouseLeave={() => setState((_state) => ({ ..._state, optionsHover: false }))}
          >
            {filteredOptions.map((filteredOption, index) => (
              <li
                key={index}
                className={`px-4 py-[14px] border-secondary-300 cursor-pointer text-[13px] font-light text-black2B bg-white ${
                  index !== filteredOptions.length - 1 && "border-b"
                }`}
                onClick={() => onOptionClick(filteredOption)}
                onMouseDown={(e) => e.preventDefault()}
              >
                {filteredOption.richLabel ? (
                  <div dangerouslySetInnerHTML={{ __html: filteredOption.richLabel }} />
                ) : (
                  <p>{filteredOption.label}</p>
                )}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default Dropdown;
