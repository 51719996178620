import PageContainer from "../layout/PageContainer";
import { ThemeMenuType, SiteThemeType } from "../../types/types";
import { useEffect, useState } from "react";
import SiteThemeDefault from "./SiteThemeDefault";
import ManageBlockDefaults from "./ManageBlockDefaults";
import ManageButtonDefaults from "./ManageButtonDefaults";
import ManageFormDefaults from "./ManageFormDefaults";
import ManageTableDefaults from "./ManageTableDefaults";
import ManageElementDefaults from "./ManageElementDefaults";
import { getSiteTheme, editSiteTheme } from "../../api/siteTheme";
import LeftMenuSIteTheme from "./LeftMenuSIteTheme";
import PageTitle, { Title } from "../PageTitle";
import Line from "../Line";
import { Snackbar, SnackbarContent } from "@mui/material";
import ManageTooltip from "./ManageTooltip";
import ManageSiteDefault from "./ManageSiteDefault";

const SiteTheme = () => {
  const [selectedMenu, setSelectedMenu] = useState<string | undefined>(undefined);
  const [leftMenuIsClicked, setLeftMenuIsClicked] = useState(false);
  const menuArray: ThemeMenuType[] = [
    { title: Title.blockDefaults, isClicked: selectedMenu === Title.blockDefaults },
    { title: Title.buttonDefaults, isClicked: selectedMenu === Title.buttonDefaults },
    { title: Title.formDefaults, isClicked: selectedMenu === Title.formDefaults },
    { title: Title.tableDefaults, isClicked: selectedMenu === Title.tableDefaults },
    { title: Title.elementDefaults, isClicked: selectedMenu === Title.elementDefaults },
    { title: Title.tooltip, isClicked: selectedMenu === Title.tooltip },
    { title: Title.siteDefault, isClicked: selectedMenu === Title.siteDefault },
  ];
  const initialState: SiteThemeType = {
    hoverButtonStyle: {
      background: { color: "", id: 0, image: "", imageMobile: "", imageWidescreen: "" },
      border: {
        borderColor: "",
        borderRadius: { bottomLeft: "", bottomRight: "", id: "", topLeft: "", topRight: "" },
        borderSideArray: [],
        borderStyle: "DS",
        borderWidth: "MD",
        id: "",
      },
      gradientBackground: { color1: "", color2: "", opacity1: "", opacity2: "" },
      padding: { bottom: "", id: "", left: "", right: "", top: "" },
      shadow: { blur: "", id: "", x: "", y: "" },
    },
    buttonStyle: {
      gradientBackground: { color1: "", color2: "", opacity1: "", opacity2: "" },
      padding: { bottom: "", id: "", left: "", right: "", top: "" },
      background: { color: "", id: 0, image: "", imageMobile: "", imageWidescreen: "" },
      border: {
        borderColor: "",
        borderRadius: { bottomLeft: "", bottomRight: "", id: "", topLeft: "", topRight: "" },
        borderSideArray: [],
        borderStyle: "NN",
        borderWidth: "MD",
        id: "0",
      },

      shadow: { blur: "", id: "", x: "", y: "" },
    },
    borderSideChoices: [{ key: "", label: "" }],
    borderStyleChoices: [{ key: "", label: "" }],
    borderWidthChoices: [{ key: "", label: "" }],
    formStyle: {
      backgroundColor: "",
      border: {
        borderColor: "",
        borderRadius: { bottomLeft: "", bottomRight: "", id: "", topLeft: "", topRight: "" },
        borderSideArray: [],
        borderStyle: "NN",
        borderWidth: "MD",
        id: "0",
      },
      disable: {
        backgroundColor: "",
        border: {
          borderColor: "",
          borderRadius: { bottomLeft: "", bottomRight: "", topLeft: "", id: "", topRight: "" },
          borderSideArray: [],
          borderStyle: "NN",
          borderWidth: "MD",
          id: "",
        },
        id: "",
        textColor: "",
      },
      error: {
        backgroundColor: "",
        border: {
          borderColor: "",
          borderRadius: { bottomLeft: "", bottomRight: "", topLeft: "", id: "", topRight: "" },
          borderSideArray: [],
          borderStyle: "NN",
          borderWidth: "MD",
          id: "",
        },
        id: "",
        textColor: "",
      },
      extraLabel: {
        backgroundColor: "",
        border: {
          borderColor: "",
          borderRadius: { bottomLeft: "", bottomRight: "", topLeft: "", id: "", topRight: "" },
          borderSideArray: [],
          borderStyle: "NN",
          borderWidth: "MD",
          id: "",
        },
        textColor: "",
        id: "",
      },
      focus: {
        backgroundColor: "",
        id: "",
        textColor: "",
        border: {
          borderColor: "",
          borderRadius: { bottomLeft: "", bottomRight: "", topLeft: "", id: "", topRight: "" },
          borderSideArray: [],
          borderStyle: "NN",
          borderWidth: "MD",
          id: "",
        },
      },
      id: "",
      padding: { bottom: "", id: "", left: "", right: "", top: "" },
      placeholderColor: "",
      textColor: "",
    },
    tableStyle: {
      border: {
        borderColor: "",
        borderRadius: { bottomLeft: "", bottomRight: "", id: "", topLeft: "", topRight: "" },
        borderSideArray: [],
        borderStyle: "NN",
        borderWidth: "MD",
        id: "0",
      },
      headerBorder: {
        borderColor: "",
        borderRadius: { bottomLeft: "", bottomRight: "", id: "", topLeft: "", topRight: "" },
        borderSideArray: [],
        borderStyle: "NN",
        borderWidth: "MD",
        id: "0",
      },
      id: "",
      primaryBackgroundColor: "",
      secondaryBackgroundColor: "",
      stickyBackgroundColor: "",
      stickyBorder: {
        borderColor: "",
        borderRadius: { bottomLeft: "", bottomRight: "", id: "", topLeft: "", topRight: "" },
        borderSideArray: [],
        borderStyle: "NN",
        borderWidth: "MD",
        id: "0",
      },
      colorPattern: "HZ",
      headerBackgroundColor: "",
    },
    colorPatternChoices: [{ key: "", label: "" }],
    elementBorder: {
      id: "",
      borderStyle: "NN",
      borderColor: "",
      borderRadius: { bottomLeft: "", bottomRight: "", id: "", topLeft: "", topRight: "" },
      borderSideArray: [],
      borderWidth: "TN",
    },
    blockStyle: {
      background: {
        color: "",
        id: "",
      },
      border: {
        id: "",
        borderStyle: "NN",
        borderColor: "",
        borderRadius: { bottomLeft: "", bottomRight: "", id: "", topLeft: "", topRight: "" },
        borderSideArray: [],
        borderWidth: "TN",
      },
      gradientBackground: {
        color1: "",
        color2: "",
        direction: "TB",
        id: "",
        opacity1: "",
        opacity2: "",
      },
      id: "",
      isCentralizedInMobile: false,
      margin: "",
      maxHeight: null,
      maxWidth: null,
      minHeight: null,
      padding: {
        id: "",
        bottom: "",
        left: "",
        right: "",
        top: "",
      },
      shadow: {
        id: "",
        x: "",
        y: "",
        blur: "",
      },
      verticalOverlap: null,
      width: null,
    },
    directionChoices: [],
    marginChoices: [],
    tooltipStyle: {
      id: "",
      backgroundColor: "",
      border: {
        id: "",
        borderStyle: "NN",
        borderColor: "",
        borderRadius: {
          id: "",
          bottomLeft: "",
          bottomRight: "",
          topLeft: "",
          topRight: "",
        },
        borderSideArray: [],
        borderWidth: "TN",
      },
      maxWidth: "",
      padding: {
        id: "",
        bottom: "",
        left: "",
        right: "",
        top: "",
      },
      shadow: {
        id: "",
        x: "",
        y: "",
        blur: "",
      },
      position: "",
    },
    tooltipPositionChoices: [],
    arrowColor: "",
    arrowBackgroundColor: "",
    fontWeightChoices: [],
    fontFamilyChoices: [],
    textColor: "",
    fontFamily: "",
    fontWeight: "",
  };
  const [siteTheme, setSiteTheme] = useState<SiteThemeType>(initialState);
  const [snackbarState, setSnackbarState] = useState({ isEditSuccessfully: false, showSnackbar: false });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getSiteTheme()
      .then((response) => {
        if (response.status === 200) {
          setSiteTheme(response.data);
        }
      })
      .catch();
  }, []);

  const editHandler = (style: SiteThemeType) => {
    setLoading(true);
    editSiteTheme(style)
      .then((response) => {
        if (response.statusText === "OK") {
          setSnackbarState({ isEditSuccessfully: true, showSnackbar: true });
          setLoading(false);
          setSiteTheme(style);
        } else {
          setSnackbarState({ isEditSuccessfully: false, showSnackbar: true });
        }
      })
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    const documentsContainer = document.getElementById("themeContainer");
    new ResizeObserver((entries) => {
      const width = entries[0].contentRect.width;
      if (width < 768) {
        setLeftMenuIsClicked(true);
      }
    }).observe(documentsContainer!);
  }, []);

  const [state, setstate] = useState(false);
  return (
    <PageContainer>
      <Snackbar
        open={snackbarState.showSnackbar}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: `${snackbarState.isEditSuccessfully ? "#03A678" : "#D11C1C"} `,
          },
          "& .MuiSnackbarContent-message": {
            fontSize: "16px",
            fontFamily: "'Open Sans', sans-serif",
            fontWeight: "100",
          },
        }}
        onClose={() => {
          setSnackbarState({ ...snackbarState, showSnackbar: false });
        }}
      >
        <SnackbarContent
          message={snackbarState.isEditSuccessfully ? <p>Page Edited Successfully</p> : <p>Page not Edited Successfully</p>}
        />
      </Snackbar>
      <PageTitle selectedMenu={selectedMenu} className="top-[100px] fixed" />
      <Line className="top-[178px] fixed z-30" />
      <div id="themeContainer">
        <LeftMenuSIteTheme
          openOrCloseState={leftMenuIsClicked}
          openOrClose={() => {
            setLeftMenuIsClicked(!leftMenuIsClicked);
            setstate(!state);
          }}
          menuArray={menuArray}
          setSelectedMenu={setSelectedMenu}
        />
        <div
          className={`mt-[220px] duration-500 ${selectedMenu === undefined ? "flex items-center justify-center" : " mt-12"} ${
            leftMenuIsClicked ? "ml-[45px] w-[calc(100%-100px)] " : "md:ml-[300px] md:w-[calc(100%-350px)] w-[calc(100%-100px)] ml-[50px]"
          }`}
        >
          {(() => {
            switch (selectedMenu) {
              case Title.blockDefaults:
                return (
                  <ManageBlockDefaults
                    loading={loading}
                    onCancel={() => setSelectedMenu(undefined)}
                    siteThemeStyle={siteTheme}
                    onSave={(style) => {
                      editHandler({ ...siteTheme, blockStyle: style });
                    }}
                  />
                );

              case Title.buttonDefaults:
                return (
                  <ManageButtonDefaults
                    loading={loading}
                    siteThemeStyle={siteTheme}
                    onCancel={() => setSelectedMenu(undefined)}
                    onSave={(style) => {
                      editHandler({
                        ...siteTheme,
                        buttonStyle: { ...style.buttonStyle },
                        hoverButtonStyle: { ...style.hoverButtonStyle },
                      });
                    }}
                  />
                );

              case Title.formDefaults:
                return (
                  <ManageFormDefaults
                    loading={loading}
                    siteThemeStyle={siteTheme}
                    onCancel={() => setSelectedMenu(undefined)}
                    onSave={(style) => {
                      !loading && editHandler({ ...siteTheme, ...siteTheme, formStyle: style });
                    }}
                  />
                );

              case Title.tableDefaults:
                return (
                  <ManageTableDefaults
                    loading={loading}
                    onCancel={() => setSelectedMenu(undefined)}
                    siteThemeStyle={siteTheme}
                    onSave={(style) => {
                      !loading && editHandler({ ...siteTheme, ...siteTheme, tableStyle: style });
                    }}
                  />
                );

              case Title.elementDefaults:
                return (
                  <ManageElementDefaults
                    loading={loading}
                    onCancel={() => setSelectedMenu(undefined)}
                    siteThemeStyle={siteTheme}
                    onSave={(style) => {
                      !loading &&
                        editHandler({
                          ...siteTheme,
                          ...siteTheme,
                          elementBorder: style.border,
                          arrowBackgroundColor: style.arrow.arrowBackgroundColor,
                          arrowColor: style.arrow.arrowColor,
                        });
                    }}
                  />
                );
              case Title.tooltip:
                return (
                  <ManageTooltip
                    loading={loading}
                    onCancel={() => setSelectedMenu(undefined)}
                    onSave={(style) => {
                      !loading && editHandler({ ...siteTheme, ...siteTheme, tooltipStyle: style });
                    }}
                    siteThemeStyle={siteTheme}
                  />
                );
              case Title.siteDefault:
                return (
                  <ManageSiteDefault
                    loading={loading}
                    onCancel={() => setSelectedMenu(undefined)}
                    onSave={(style) => {
                      !loading &&
                        editHandler({
                          ...siteTheme,
                          ...siteTheme,
                          textColor: style.textColor,
                          fontFamily: style.fontFamily,
                          fontWeight: style.fontWeight,
                        });
                    }}
                    siteThemeStyle={siteTheme}
                  />
                );

              default:
                return <SiteThemeDefault />;
            }
          })()}
        </div>
      </div>
    </PageContainer>
  );
};

export default SiteTheme;
