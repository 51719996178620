import React from "react";
import PageContainer from "./layout/PageContainer";

const History = () => {
  return (
    <PageContainer>
      <div>History</div>
    </PageContainer>
  );
};

export default History;
