import API from "./_config";
import { AxiosProgressEvent } from "axios";

export function getFiles(data: string) {
  return API.get(`files/${data}`)
    .then((response) => {
      return response;
    })
    .catch((e) => {
      throw e;
    });
}

export function deleteFiles(id: number) {
  return API.delete(`files/${id}/`)
    .then((response) => {
      return response;
    })
    .catch((e) => {
      throw e;
    });
}

export function editFiles(id: string, data: FormData, onUploadProgress: (progressEvent: AxiosProgressEvent) => void) {
  return API.patch(`files/${id}/`, data, { onUploadProgress })
    .then((response) => {
      return response;
    })
    .catch((e) => {
      throw e;
    });
}

export function uploadFiles(data: FormData, onUploadProgress: (progressEvent: AxiosProgressEvent) => void) {
  return API.post(`file-uploader/`, data, { onUploadProgress })
    .then((response) => {
      return response;
    })
    .catch((e) => {
      throw e;
    });
}
