import API from "./_config";

export function getSiteTheme() {
  return API.get("site-setting/")
    .then((response) => {
      return response;
    })
    .catch((e) => {
      throw e;
    });
}

export function editSiteTheme(data: any) {
  return API.put("site-setting/", data)
    .then((response) => {
      return response;
    })
    .catch((e) => {
      throw e;
    });
}
