import React, { useState } from "react";
import BlockBox from "./BlockBox";
import Dropdown from "../../inputs/Dropdown";
import { DropdownChoice } from "../../../types/types";
import ColorPicker from "../../inputs/ColorPicker";
import InputField from "../../inputs/InputField";

export type GeneralSettingsTooltipType = {
  color: string;
  maxWidth: string;
  position: string;
};

const GeneralSettingsTooltip: React.FC<{
  className?: string;
  onChange: (style: GeneralSettingsTooltipType) => void;
  positionOptions: DropdownChoice[];
  style: GeneralSettingsTooltipType;
}> = ({ onChange, className, positionOptions, style }) => {
  const [styleState, setStyleState] = useState(style);

  return (
    <BlockBox title="General Settings" className={className}>
      <div className="flex w-full justify-between flex-wrap">
        <div className="w-[234px]">
          <Dropdown
            options={positionOptions}
            onOptionSelect={(value) => {
              setStyleState({ ...styleState, position: value.key as string });
              onChange({ ...styleState, position: value.key as string });
            }}
            selectedOption={positionOptions.find((key) => styleState.position === key.key) || null}
            label="Position"
          />
        </div>
        <div className="w-[234px]">
          <ColorPicker
            color={styleState.color}
            label="Background Color"
            setColor={(color) => {
              setStyleState({ ...styleState, color: color });
              onChange({ ...styleState, color: color });
            }}
          />
        </div>
        <InputField
          required
          min={10}
          type="N"
          value={styleState.maxWidth}
          onChange={(value) => {
            setStyleState({ ...styleState, maxWidth: value! });
            onChange({ ...styleState, maxWidth: value! });
          }}
          label="bottomLeft (pixel)"
          className="w-[234px]"
        />
      </div>
    </BlockBox>
  );
};

export default GeneralSettingsTooltip;
