import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faPalette, faTrash, faPlus } from "@fortawesome/pro-regular-svg-icons";

import { BlockDetailsType, BlockDetailsFieldType, ImageValueType, HeaderAction } from "../../utils/types";
import { getTargetFieldParent } from "../../utils/utils";
import { RowData } from "./../cms_table/CmsTable";
import BlockDetailsSectionContainer from "../layout/BlockDetailsSectionContainer";
import BlockDetailsSection from "./BlockDetailsSection";
import Button, { ButtonType } from "./../Button";

const BlockDetailsList: React.FC<{
  setBlockDetails: React.Dispatch<React.SetStateAction<BlockDetailsType>>;
  items: BlockDetailsFieldType[];
  onChangeValue: (slug: string, value: string | number | boolean | ImageValueType | RowData[] | string[] | null) => void;
  onPaletteIconClick: (fields: BlockDetailsFieldType[]) => void;
  onAddNestedItemButtonClick?: (parentItemSlug: string) => void;
  setHoverFieldValues?: (field: BlockDetailsFieldType) => void;
  className?: string;
  nested?: boolean;
}> = ({
  setBlockDetails,
  items,
  className,
  onChangeValue,
  setHoverFieldValues,
  onPaletteIconClick,
  onAddNestedItemButtonClick,
  nested,
}) => {
  const [expandedItemIndex, setExpandedItemIndex] = useState(-1);

  useEffect(() => setExpandedItemIndex(items.length - 1), [items.length]);

  const onChevronIconClick = (index: number) => {
    if (index === expandedItemIndex) {
      setExpandedItemIndex(-1);
    } else {
      setExpandedItemIndex(index);
    }
  };

  const removeItem = (slug: string) => {
    setBlockDetails((blockDetails) => {
      const newBlockDetails = { ...blockDetails };
      const targetFieldParent = getTargetFieldParent(newBlockDetails.fields, slug);
      if (targetFieldParent) {
        targetFieldParent.fields = targetFieldParent.fields!.filter((field) => field.slug !== slug);
      }
      return newBlockDetails;
    });
  };

  return (
    <section className={className}>
      <ul className="grid grid-cols-1 gap-6">
        {items.map((item, index) => (
          <BlockItem
            setBlockDetails={setBlockDetails}
            itemSlug={item.slug}
            key={item.slug}
            items={item.fields!.filter(({ excluded }) => !excluded)}
            hasTrashIcon={items.length > 1 && item.indelible === undefined}
            onChevronIconClick={() => onChevronIconClick(index)}
            onPaletteIconClick={onPaletteIconClick}
            onTrashIconClick={() => removeItem(item.slug)}
            onAddNestedItemButtonClick={onAddNestedItemButtonClick}
            collapsed={index !== expandedItemIndex}
            onChangeValue={onChangeValue}
            setHoverFieldValues={setHoverFieldValues}
            nested={nested}
            editableTitle={!item.indelible}
          />
        ))}
      </ul>
    </section>
  );
};

const BlockItem: React.FC<{
  setBlockDetails: React.Dispatch<React.SetStateAction<BlockDetailsType>>;
  itemSlug: string;
  items: BlockDetailsFieldType[];
  hasTrashIcon: boolean;
  onChevronIconClick: () => void;
  onPaletteIconClick: (fields: BlockDetailsFieldType[]) => void;
  onTrashIconClick: () => void;
  onAddNestedItemButtonClick?: (parentItemSlug: string) => void;
  collapsed: boolean;
  onChangeValue: (slug: string, value: string | number | boolean | ImageValueType | RowData[] | string[] | null) => void;
  setHoverFieldValues?: (field: BlockDetailsFieldType) => void;
  nested?: boolean;
  editableTitle?: boolean;
}> = ({
  setBlockDetails,
  itemSlug,
  items,
  hasTrashIcon,
  onChevronIconClick,
  onPaletteIconClick,
  onTrashIconClick,
  onAddNestedItemButtonClick,
  collapsed,
  onChangeValue,
  setHoverFieldValues,
  nested,
  editableTitle,
}) => {
  const renderBlockDetailsSection = (item: BlockDetailsFieldType) => {
    const switchField = item.fields?.find(({ type }) => type === "switch");

    if (item.type === "component") {
      return (
        <BlockDetailsSectionContainer
          key={item.slug}
          title={item.name}
          headerActions={item.fields ? [{ icon: faPalette, onClick: () => onPaletteIconClick(item.fields!) }] : undefined}
          switchableActionIndex={0}
          switchValue={switchField ? (switchField.value as boolean) : undefined}
          setSwitchValue={
            switchField
              ? (value) => {
                  if (value && item.name === "Hover") {
                    if (setHoverFieldValues) setHoverFieldValues(item);
                  } else {
                    onChangeValue(switchField.slug, value);
                  }
                }
              : undefined
          }
          collapsible={switchField != undefined}
          collapsed={(switchField?.value as boolean) === false}
          nested
        >
          <BlockDetailsSection
            item={item}
            onChangeValue={onChangeValue}
            setHoverFieldValues={setHoverFieldValues}
            onPaletteIconClick={onPaletteIconClick}
            expandParentOnDropdownShowOptions={switchField != undefined}
          />
        </BlockDetailsSectionContainer>
      );
    } else {
      return <BlockDetailsSection key={item.slug} item={item} onChangeValue={onChangeValue} expandParentOnDropdownShowOptions />;
    }
  };

  const getItemTagField = () => items.find((item) => item.type === "itemTag");

  const getTitle = () => {
    const itemTagField = getItemTagField();
    return itemTagField?.value as string;
  };

  const onPenButtonClick = (newItemTag: string) => {
    const itemTagField = getItemTagField();
    if (itemTagField) onChangeValue(itemTagField?.slug, newItemTag);
  };

  const changeLastItemPosition = (_items: BlockDetailsFieldType[]) => {
    // If we have a readonly field as the last field of a section,
    // We move it to the 1st position.
    if (_items[_items.length - 1] && _items[_items.length - 1].type === "read_only") {
      const readonlyField = _items.splice(_items.length - 1)[0];
      _items.unshift(readonlyField);
    }
    return _items;
  };

  const headerActions: HeaderAction[] = [
    { icon: faChevronDown, className: collapsed ? undefined : "rotate-180", onClick: onChevronIconClick },
  ];

  const styleFields = items.filter(({ isStyle }) => isStyle);
  if (styleFields.length > 0) headerActions.unshift({ icon: faPalette, onClick: () => onPaletteIconClick(styleFields) });

  headerActions.unshift({ icon: faTrash, onClick: onTrashIconClick, disable: !hasTrashIcon });

  const itemListField = items.find(({ type }) => type === "itemList");

  return (
    <li>
      <BlockDetailsSectionContainer
        title={getTitle()}
        editableTitle={editableTitle}
        headerActions={headerActions}
        collapsible
        collapsed={collapsed}
        nested={nested}
        onPenButtonClick={onPenButtonClick}
      >
        <div className="grid grid-cols-1 gap-4">
          {changeLastItemPosition(items.filter(({ isStyle, type }) => !isStyle && type !== "itemList")).map((item) =>
            renderBlockDetailsSection(item)
          )}
          {itemListField && (
            <>
              <div className="flex justify-end">
                <Button
                  type={ButtonType.PRIMARY}
                  className="flex items-center"
                  onClick={() => {
                    if (onAddNestedItemButtonClick) onAddNestedItemButtonClick(itemSlug);
                  }}
                >
                  <FontAwesomeIcon icon={faPlus} />
                  <p className="ml-2">Add {itemListField.name.split(" ")[1]}</p>
                </Button>
              </div>
              <BlockDetailsList
                setBlockDetails={setBlockDetails}
                items={itemListField.fields!.filter(({ excluded }) => !excluded)}
                onChangeValue={onChangeValue}
                onPaletteIconClick={onPaletteIconClick}
                setHoverFieldValues={setHoverFieldValues}
                nested={true}
              />
            </>
          )}
        </div>
      </BlockDetailsSectionContainer>
    </li>
  );
};

export default BlockDetailsList;
