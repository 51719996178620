import React from "react";

const LoginContainer: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  let backGround = require("../../images/background.png");
  return (
    <div
      style={{ backgroundImage: `url(${backGround})`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }}
      className="  items-center  text-body_black p-[21px]  h-screen"
    >
      {children}
    </div>
  );
};

export default LoginContainer;
