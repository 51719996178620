import React, { useState, useEffect } from "react";
import BlockBox from "./BlockBox";
import ColorPicker from "../../inputs/ColorPicker";

const GeneralSettingsButtonBlock: React.FC<{
  className?: string;
  backgroundColor: string;
  onChange: (color: string) => void;
}> = ({ className, backgroundColor, onChange }) => {
  const [color, setColor] = useState<string>("");

  useEffect(() => {
    backgroundColor && setColor(backgroundColor);
  }, [backgroundColor]);

  return (
    <BlockBox className={className} title="General Settings">
      <ColorPicker
        color={color}
        label="Background Color"
        setColor={(color) => {
          setColor(color.toString());
          onChange(color.toString());
        }}
      />
    </BlockBox>
  );
};

export default GeneralSettingsButtonBlock;
