import { useEffect, useRef, useState } from "react";
import { LoaderFunctionArgs, useLoaderData, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";

import { getContentPreview } from "../../api/content";
import { StatusBoxType } from "../../types/types";
import PageContainer from "../layout/PageContainer";
import LanguageBox from "../LanguageBox";

type ContentPreviewType = {
  status: StatusBoxType;
  type: string;
  previewValue: string;
  contentId: string;
  contentName: string;
  languages: string[];
  defaultLanguage: string;
};

const ContentPreview = () => {
  const loaderData = useLoaderData() as ContentPreviewType;

  const [previewValue, setPreviewValue] = useState(loaderData.previewValue);
  const [language, setLanguage] = useState(loaderData.defaultLanguage);

  const iframeRef = useRef<HTMLIFrameElement>(null);

  const { status, type, languages, defaultLanguage, contentId, contentName } = loaderData;

  useEffect(() => {
    if (iframeRef && iframeRef.current) {
      iframeRef.current!.contentWindow?.postMessage({ type: "previewValue", previewValue }, process.env.REACT_APP_TARGET_ORIGIN!);
    }
  }, [iframeRef, previewValue]);

  useEffect(() => {
    setTimeout(() => languageChangeHandler(), 3000);
  }, [language]);

  const languageChangeHandler = async () => {
    const result = await getContentPreview(contentId, language);
    setPreviewValue(JSON.stringify(result));
  };

  return (
    <PageContainer>
      <div className="w-[95%] mx-auto flex flex-col" style={{ height: "calc(100vh - 100px)" }}>
        <header className="py-10">
          <div className="flex items-center gap-6">
            <div className="flex text-sm font-light text-black">
              <Link to="/content">Contents</Link>
              <span className="mx-1">&gt;</span>
              <Link to={`/content/${contentId}`}>{contentName}</Link>
              <span className="mx-1">&gt;</span>
              <Link to={`/content/${contentId}/blocks`}>Manage Blocks</Link>
              <span className="mx-1">&gt;</span>
              <p className="font-normal">Preview</p>
            </div>
            <LanguageBox
              languagesArr={languages}
              defaultLang={defaultLanguage ?? languages[0]}
              onChange={(lang) => setLanguage(lang)}
              className="z-10 border border-primary"
            />
          </div>
          {(status === StatusBoxType.DF || status === StatusBoxType.DP) && (
            <div className="flex items-center mt-10 py-5 px-7 bg-[#FFF5DB] rounded-[5px]">
              <FontAwesomeIcon icon={faExclamationTriangle} size="2x" className="text-orange" />
              <p className="ml-5">This page contains the preview of the unpublished changes for the page.</p>
            </div>
          )}
        </header>
        <iframe
          ref={iframeRef}
          src={`/preview/${type.toLocaleLowerCase()}/preview_loader.html`}
          className="w-full flex-1 h-full border mb-10"
        ></iframe>
      </div>
    </PageContainer>
  );
};

export default ContentPreview;

export const loader = async ({ params }: LoaderFunctionArgs) => {
  const result = await getContentPreview(params.contentId!);
  return {
    status: result.status,
    type: result.type,
    languages: result.languages,
    defaultLanguage: result.defaultLanguage,
    previewValue: JSON.stringify(result),
    contentId: params.contentId,
    contentName: result.title,
  };
};
