import API from "./_config";

export function logIn(credentials: any) {
  return API.post("token/", credentials)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}
export function sendEmail(credentials: any) {
  return API.post("send-mail/", credentials)
    .then((response) => {
      return response;
    })
    .catch((e) => {
      return e;
    });
}

export function resetPasswordValidation(uid: string, hashToken: string) {
  return API.get(`reset-password/${uid}/${hashToken}/`)
    .then((response) => {
      return response;
    })
    .catch((e) => {
      return e;
    });
}
export function changePassword(uid: string, hashToken: string, credentials: any) {
  return API.put(`reset-password/${uid}/${hashToken}/`, credentials)
    .then((response) => {
      return response;
    })
    .catch((e) => {
      return e;
    });
}
