import React, { useState } from "react";
import { StatusBoxType } from "../../types/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDiskCircleXmark } from "@fortawesome/pro-regular-svg-icons";
const StatusBox: React.FC<{ type: StatusBoxType }> = ({ type }) => {
  let className, text;
  switch (type) {
    case StatusBoxType.DF:
      className = "bg-gray  text-white text-[13px] w-[46px]";
      text = "Draft";
      break;
    case StatusBoxType.PB:
      className = "bg-green text-white text-[13px] w-[70px]";
      text = "Published";
      break;
    case StatusBoxType.DP:
      className = "bg-orange text-white text-[13px] w-[100px]";
      text = "Published";
      break;
  }
  const [isHovered, setIsHovered] = useState(false);
  return (
    <div
      onMouseOver={() => setIsHovered(true)}
      onMouseOut={() => setIsHovered(false)}
      className={`p-2 rounded   ${className}`}
    >
      {text}
      {type === StatusBoxType.DP && (
        <span>
          <FontAwesomeIcon icon={faFloppyDiskCircleXmark} className={`text-sm  ${isHovered ? "ml-[10px]" : "ml-2"}`} />
        </span>
      )}
      {type === StatusBoxType.DP && (
        <div
          className={`absolute text-black2B bg-secondary-400 w-[190px] text-center p-1 text-xs rounded translate-x-[100px] -translate-y-[24px] duration-100 ${
            isHovered ? "visible" : "invisible"
          }`}
        >
          Contains Unpublished Changes
        </div>
      )}
    </div>
  );
};

export default StatusBox;
