import {
  DictPostValueType,
  ManageValueAndPostCategoryType,
} from "../components/siteSetting/data_dictionary/manageValue/ManageValueAndPostCategory";
import API from "./_config";

export const getAllDictionaries = () => {
  return API.get(`dictionary/`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const createNewDictionary = (_name: string) => {
  return API.post(`dictionary/`, { name: _name })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateNewDictionary = (dictId: number, _name: string) => {
  return API.put(`dictionary/${dictId}/`, { name: _name })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const getAllDictValuesAndPosts = (
  type: ManageValueAndPostCategoryType,
  dictId: number,
  postTypeId?: string
) => {
  return API.get(
    type === ManageValueAndPostCategoryType.ManageValue
      ? `dictionary/${dictId}/value/`
      : `/post-type/${postTypeId}/post-category/`
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const createDictValueAndPost = (
  type: ManageValueAndPostCategoryType,
  dictId: number,
  dictValue: DictPostValueType,
  postTypeId?: string,
  publicStyle?: {}
) => {
  const body = {
    value: dictValue.value,
    is_active: dictValue.isActive,
    // type: dictValue.type,
    public_style: dictValue.publicStyle,
  };

  return API.post(
    type === ManageValueAndPostCategoryType.ManageValue
      ? `dictionary/${dictId}/value/`
      : `/post-type/${postTypeId}/post-category/`,
    body
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateDictValueAndPost = (
  type: ManageValueAndPostCategoryType,
  dictId: number,
  dictValue: DictPostValueType,
  inactivate?: boolean,
  postTypeId?: string,
  publicStyle?: {}
) => {
  const body = {
    value: dictValue.value,
    is_active: inactivate ? false : dictValue.isActive,
    // type: dictValue.type,
    public_style: dictValue.publicStyle,
  };
  return API.put(
    type === ManageValueAndPostCategoryType.ManageValue
      ? `dictionary/${dictId}/value/${dictValue.id}/`
      : `post-type/${postTypeId}/post-category/${dictValue.id}/`,
    body
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const deleteDictValueAndPost = (
  type: ManageValueAndPostCategoryType,
  dictId: number,
  dictValueId: number,
  postTypeId?: string
) => {
  return API.delete(
    type === ManageValueAndPostCategoryType.ManageValue
      ? `dictionary/${dictId}/value/${dictValueId}/`
      : `post-type/${postTypeId}/post-category/${dictValueId}/`
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};
export const updateManageValueOrder = (
  dictionary_id: string,
  row_id: number,
  oldOrder: number,
  newOrder: number
) => {
  return API.post(`dictionary/${dictionary_id}/value/${row_id}/reorder/`, {
    old_order: oldOrder,
    new_order: newOrder,
  });
};

export const updatePostCategoryOrder = (
  row_id: number,
  oldOrder: number,
  newOrder: number
) => {
  return API.post(`post-category/${row_id}/reorder/`, {
    old_order: oldOrder,
    new_order: newOrder,
  });
};

// Post Type

export const updatePostTypeOrder = (
  row_id: number,
  oldOrder: number,
  newOrder: number
) => {
  return API.post(`post-type/${row_id}/reorder/`, {
    old_order: oldOrder,
    new_order: newOrder,
  });
};
