import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import { faDownload } from "@fortawesome/pro-light-svg-icons";
import InUsedBox from "./InUsedBox";
import ShowingData from "./ShowingData";
import { FileDataType } from "../../../types/types";

const FileDataBox: React.FC<{
  data: FileDataType;
}> = ({ data }) => {
  return (
    <div className="max-w-[594px] flex-1 mx-auto 1304:mx-0">
      <p className="px-6">
        <FontAwesomeIcon icon={faInfoCircle} className={`text-base`} />
        <span className="ml-1">If you modify the file, the change will be reflected wherever it is used.</span>
      </p>
      <div className="mt-6 flex">
        <InUsedBox isUsed={data.isUsed} />
        <span className="text-base font-medium ml-2">File Name</span>
      </div>
      <div className="mt-3 pl-6 text-sm">
        {data.fileName}
        <FontAwesomeIcon icon={faDownload} className={`ml-4 text-base text-[#62A630]`} />
      </div>
      <ShowingData size={data.size} type={data.type} uploadDate={data.uploadDate} numberOfUsage={data.numberOfUsage} />
    </div>
  );
};

export default FileDataBox;
