import Dropdown from "../../inputs/Dropdown";
import CmsModalMainShape, { CmsModalMainShapePublic } from "../../CmsModalMainShape";
import { useEffect, useState } from "react";
import { editBlockNameOrBlockParent, getParentBlocks } from "../../../api/blocks";
import { OptionType } from "../../../utils/types";
import { TreeModel } from "../../../types/types";

interface Props extends CmsModalMainShapePublic {
  contentId: string;
  block: TreeModel;
  parentId: number;
}

export default function ChangeParentModal(props: Props) {
  const DEFAULT_OPTION = { key: 0, label: "No Parent" };
  const [parentBlock, setParentBlock] = useState<OptionType>(DEFAULT_OPTION);
  const [parentOptions, setParentOptions] = useState<OptionType[]>([]);

  useEffect(() => {
    if (props.showModal) {
      getParentBlocks(props.contentId, (blockTypes: OptionType[], parentList: OptionType[]) => {
        parentList.unshift(DEFAULT_OPTION);
        setParentOptions(parentList);

        parentList.forEach((parent) => {
          if (parent.key === props.parentId) {
            setParentBlock(parent);
          }
        });
      });
    }
  }, [props.showModal]);

  return (
    <CmsModalMainShape
      {...props}
      title="Change Parent"
      positiveBtnTitle="Save"
      onPositiveBtnClick={() => {
        editBlockNameOrBlockParent(
          props.contentId,
          props.block.id,
          () => props.onPositiveBtnClick(),
          parentBlock.key === 0 ? null : parentBlock.key
        );
      }}
    >
      <h1 className="font-semibold mt-8">{`${props.block.name} - (${props.block.type})`}</h1>

      <h1 className="font-semibold mt-6">Parent Block *</h1>
      <Dropdown
        label=""
        options={parentOptions}
        selectedOption={parentBlock}
        onOptionSelect={(selectedOption) => setParentBlock(selectedOption)}
      />
    </CmsModalMainShape>
  );
}
