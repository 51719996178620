import React, { useState } from "react";
import ColorPicker from "../inputs/ColorPicker";
import BlockBox from "./blocks/BlockBox";

type elementDefaultType = { arrowColor: string; arrowBackgroundColor: string };

const GeneralSettingsElementDefault: React.FC<{
  style: elementDefaultType;
  onChange: (style: elementDefaultType) => void;
}> = ({ style, onChange }) => {
  const [styleState, setStyleState] = useState(style);

  return (
    <BlockBox title="General Settings">
      <div className="flex justify-between flex-wrap">
        <div className="w-[238px]">
          <ColorPicker
            color={style.arrowColor}
            label="Arrow Color"
            setColor={(color) => {
              setStyleState({ ...styleState, arrowColor: color });
              onChange({ ...styleState, arrowColor: color });
            }}
          />
        </div>
        <div className="w-[238px]">
          <ColorPicker
            color={style.arrowBackgroundColor}
            label="Arrow Background Color"
            setColor={(color) => {
              setStyleState({ ...styleState, arrowBackgroundColor: color });
              onChange({ ...styleState, arrowBackgroundColor: color });
            }}
          />
        </div>
        <div className="w-[238px]"></div>
      </div>
    </BlockBox>
  );
};

export default GeneralSettingsElementDefault;
