import React, { useEffect, useRef, useState, useCallback } from "react";
import { HeaderGroup, useTable } from "react-table";
import { DndProvider, useDrag, useDrop, DropTargetMonitor, DragSourceMonitor } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripDotsVertical } from "@fortawesome/pro-solid-svg-icons";
import { faCirclePlus, faTrash, faUpDownLeftRight } from "@fortawesome/pro-regular-svg-icons";
import CmsTableCellInput from "./CmsTableCellInput";

export interface RowData {
  [key: string]: any;
}

interface Column {
  id: string;
  Header: string;
  accessor: string;
}

enum RowAndColumnChanges {
  Add,
  Remove,
}

interface Props {
  value: RowData[];
  onValueChanged: (newValue: RowData[]) => void;
  className?: string;
}

const TABLE_LENGTH_VALIDATION = "The table must have at least one row and column!";

export default function (props: Props) {
  const { value, onValueChanged, className } = props;

  const [tableLengthErrMsg, setTableLengthErrMsg] = useState<string | undefined>(undefined);
  const [columns, setColumns] = useState<Column[]>([]);

  useEffect(() => {
    const colAccessors = Object.keys(value[0]).filter((key) => key.startsWith("col"));
    setColumns(
      colAccessors.map((colAccessor) => ({
        id: colAccessor.substring(3, colAccessor.length),
        Header: "",
        accessor: colAccessor,
      }))
    );
  }, [value]);

  const getRowId = useCallback((row: RowData) => row.id, []);

  const { getTableBodyProps, headerGroups, rows, prepareRow } = useTable<RowData>({
    columns,
    data: value,
    getRowId,
  });

  const getLastRowId = () => {
    let lastRowId = 0;
    value.forEach((row) => {
      const rowId = +row.id;
      if (rowId > lastRowId) lastRowId = rowId;
    });
    return lastRowId;
  };

  const getLastColumnId = () => {
    let lastColId = 0;
    columns.forEach((col) => {
      const colId = +col.id;
      if (colId > lastColId) lastColId = colId;
    });
    return lastColId;
  };

  const moveRow = (dragIndex: number, hoverIndex: number) => {
    const dragRecord = value[dragIndex];
    onValueChanged(
      update(value, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRecord],
        ],
      })
    );
  };

  const addRow = (targetIndex: number) => {
    const newRow: RowData = {
      id: `${getLastRowId() + 1}`,
    };

    for (const element of columns) {
      const colName = `col${element.id}`;
      newRow[colName] = "";
    }

    const newValue = value.slice(0);
    newValue.splice(targetIndex + 1, 0, newRow);

    onValueChanged(newValue);
  };

  const removeRow = (rowId: string) => {
    onValueChanged(value.filter(({ id }) => id !== rowId));
  };

  const addColToRowAtIndex = (row: RowData, index: number, key: string) => {
    const entries = Object.entries(row);
    entries.splice(index, 0, [key, ""]);
    return Object.fromEntries(entries);
  };

  const removeColFromRow = (row: RowData, colAccessor: string) => {
    const newRow = { ...row };
    delete newRow[colAccessor];
    return newRow;
  };

  const swapTwoColInRow = (row: RowData, firstIndex: number, secondIndex: number) => {
    const entries = Object.entries(row);
    [entries[firstIndex], entries[secondIndex]] = [entries[secondIndex], entries[firstIndex]];
    return Object.fromEntries(entries);
  };

  const addColumn = (targetIndex: number) => {
    onValueChanged(value.map((row) => addColToRowAtIndex(row, targetIndex + 1, `col${getLastColumnId() + 1}`)));
  };

  const removeColumn = (columnAccessor: string) => {
    onValueChanged(value.map((row) => removeColFromRow(row, columnAccessor)));
  };

  const moveColumn = (dragIndex: number, hoverIndex: number) => {
    console.log(
      dragIndex,
      hoverIndex,
      value.map((row) => swapTwoColInRow(row, dragIndex, hoverIndex))
    );
    onValueChanged(value.map((row) => swapTwoColInRow(row, dragIndex, hoverIndex)));
  };

  const handleCellChange = (rowIndex: number, columnId: string, _value: any) => {
    // Make a shallow copy of the data array to modify it
    const updatedData = [...value];
    updatedData[rowIndex]["col" + columnId] = _value;
    onValueChanged(updatedData);
  };

  return (
    <div className={`${className} flex flex-col w-full overflow-auto pt-10 border-[1px] border-gray-300 rounded-md`}>
      <DndProvider backend={HTML5Backend}>
        <table>
          <thead className="flex">
            {headerGroups.map((headerGroup) => (
              <tr className="flex flex-1" {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => {
                  return (
                    <TableHeaderCell
                      key={index}
                      index={index}
                      moveColumn={moveColumn}
                      column={column}
                      columnChangesListener={(change: RowAndColumnChanges) => {
                        switch (change) {
                          case RowAndColumnChanges.Add:
                            addColumn(index);
                            break;
                          case RowAndColumnChanges.Remove:
                            if (columns.length > 1) {
                              removeColumn(columns[index].accessor);
                            } else {
                              setTableLengthErrMsg(TABLE_LENGTH_VALIDATION);
                            }
                            break;
                        }
                      }}
                    />
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody className="flex" {...getTableBodyProps()}>
            <div className="flex w-full flex-col">
              {rows.map((row, index) => {
                prepareRow(row);
                return (
                  <TableRow
                    rowChangesListener={(change: RowAndColumnChanges) => {
                      switch (change) {
                        case RowAndColumnChanges.Add:
                          addRow(index);
                          break;
                        case RowAndColumnChanges.Remove:
                          if (rows.length > 1) {
                            removeRow(row.id);
                          } else {
                            setTableLengthErrMsg(TABLE_LENGTH_VALIDATION);
                          }
                          break;
                      }
                    }}
                    {...row.getRowProps()}
                    index={index}
                    rowData={row}
                    moveRow={(dragIndex, hoverIndex) => moveRow(dragIndex, hoverIndex)}
                    onValueChanged={(changedRowIndex, columnId, newValue) => handleCellChange(changedRowIndex, columnId, newValue)}
                  />
                );
              })}
            </div>
          </tbody>
        </table>
        {tableLengthErrMsg && <label className="mx-4 my-2 text-error ">{tableLengthErrMsg}</label>}
      </DndProvider>
    </div>
  );
}

interface TableHeaderCellProps {
  column: HeaderGroup<RowData>;
  index: number;
  moveColumn: (dragIndex: number, hoverIndex: number) => void;
  columnChangesListener: (change: RowAndColumnChanges) => void;
}

function TableHeaderCell(props: TableHeaderCellProps) {
  const { column, index, moveColumn, columnChangesListener } = props;

  const [showAddColumnBtn, setShowAddColumnBtn] = useState(false);
  const [holdAddColumnIcons, setHoldAddColumnIcons] = useState(false);
  const selectedColumnBottomDiv = useRef<HTMLButtonElement>(null);

  const ref = useRef<HTMLTableCellElement>(null);
  const DND_COLUMN_TYPE = "column";

  const [{ isDragging }, drag] = useDrag({
    type: DND_COLUMN_TYPE,
    item: { id: index, index: index },
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: DND_COLUMN_TYPE,
    hover: (item: { id: string; index: number }, monitor: DropTargetMonitor) => {
      if (!item || item.index === index) {
        return;
      }
      moveColumn(item.index, index);
      item.index = index;
    },
  });

  const opacity = isDragging ? 0.5 : 1;

  drag(drop(ref));

  useEffect(() => {
    if (!showAddColumnBtn) {
      setHoldAddColumnIcons(false);
    }
  }, [showAddColumnBtn]);

  const clearAddOrRemoveIcons = () => {
    setShowAddColumnBtn(false);
    selectedColumnBottomDiv.current?.blur();
  };

  return (
    <div
      className="flex flex-1 min-h-[50px] min-w-[200px] items-center justify-center border-l-2 first:ml-[31px] first:border-l-0 "
      {...column.getHeaderProps()}
    >
      <th ref={ref} style={{ opacity }} className="flex flex-1 h-full items-center justify-center">
        <FontAwesomeIcon icon={faUpDownLeftRight} className="mr-2 text-primary cursor-pointer" />
        {column.render("Header")}
      </th>
      <button
        ref={selectedColumnBottomDiv}
        className="w-[4px] h-full hover:bg-blue-400 focus:bg-blue-400"
        onMouseEnter={() => setShowAddColumnBtn(true)}
        onClick={() => setHoldAddColumnIcons(true)}
        onMouseLeave={() => setShowAddColumnBtn(holdAddColumnIcons)}
        onBlur={clearAddOrRemoveIcons}
      >
        {showAddColumnBtn && (
          <div className="relative">
            <div className="absolute bottom-0 right-4">
              <div className="flex items-center justify-center">
                <FontAwesomeIcon
                  icon={faCirclePlus}
                  className="w-[20px] h-full cursor-pointer text-green mr-1"
                  onClick={() => {
                    clearAddOrRemoveIcons();
                    columnChangesListener(RowAndColumnChanges.Add);
                  }}
                />
                <FontAwesomeIcon
                  icon={faTrash}
                  className="w-[20px] h-full cursor-pointer text-error"
                  onClick={() => {
                    clearAddOrRemoveIcons();
                    columnChangesListener(RowAndColumnChanges.Remove);
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </button>
    </div>
  );
}

interface TableRowProps {
  rowData: RowData;
  index: number;
  moveRow: (dragIndex: number, hoverIndex: number) => void;
  rowChangesListener: (change: RowAndColumnChanges) => void;
  onValueChanged: (rowId: number, columnId: string, newValue: string) => void;
}

const DND_ITEM_TYPE = "row";

function TableRow(props: TableRowProps) {
  const { rowData, index, moveRow, rowChangesListener, onValueChanged } = props;

  const dropRef = React.useRef<HTMLTableRowElement>(null);
  const dragRef = React.useRef<HTMLTableCellElement>(null);

  const [showAddRowBtn, setShowAddRowBtn] = useState(false);
  const [holdAddRowIcons, setHoldAddRowIcons] = useState(false);
  const [mouseLocalCoordinates, setMouseLocalCoordinates] = useState({ x: 0, y: 0 });
  const selectedRowBottomDiv = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (showAddRowBtn) {
      const handleMouseMove = (event: MouseEvent) => {
        setMouseLocalCoordinates({ x: event.clientX, y: event.clientY });
        document.removeEventListener("mousemove", handleMouseMove);
      };

      document.addEventListener("mousemove", handleMouseMove);

      return () => {
        document.removeEventListener("mousemove", handleMouseMove);
      };
    } else {
      setHoldAddRowIcons(false);
      setMouseLocalCoordinates({ x: 0, y: 0 });
    }
  }, [showAddRowBtn]);

  const [{ isDragging }, drag, preview] = useDrag({
    type: DND_ITEM_TYPE,
    item: { type: DND_ITEM_TYPE, index: index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: DND_ITEM_TYPE,
    hover: (item: RowData, monitor: DropTargetMonitor) => {
      const dragIndex = item.index;
      const hoverIndex = index;

      if (!dropRef.current) {
        return;
      }
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = dropRef.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset!.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Reorder the data array
      moveRow(dragIndex, hoverIndex);

      item.index = hoverIndex;
    },
  });

  const opacity = isDragging ? 0 : 1;

  preview(drop(dropRef));
  drag(dragRef);

  const clearAddOrRemoveIcons = () => {
    setShowAddRowBtn(false);
    selectedRowBottomDiv.current?.blur();
  };

  return (
    <div className="flex flex-col w-full">
      <tr className="flex flex-col w-full border-[1px] border-secondary-200" style={{ opacity }} ref={dropRef}>
        <div className="flex w-full items-center">
          <td className="ml-2 h-full" ref={dragRef}>
            <FontAwesomeIcon icon={faGripDotsVertical} className="w-[20px] h-full cursor-pointer text-primary" />
          </td>
          {rowData.cells.map((cell: any, _index: number) => {
            return (
              <td
                className={`flex w-full items-center justify-center h-full ` + (_index === 0 ? "border-l-0" : "border-l-2")}
                {...cell.getCellProps()}
              >
                <CmsTableCellInput
                  index={_index}
                  value={cell.value}
                  onValueChanged={(newValue) => onValueChanged(index, cell.column.id, newValue)}
                />
              </td>
            );
          })}
        </div>
      </tr>
      <button
        ref={selectedRowBottomDiv}
        className="w-full h-[4px] hover:bg-blue-400 focus:bg-blue-400"
        onMouseEnter={() => setShowAddRowBtn(true)}
        onClick={(event) => {
          setMouseLocalCoordinates({ x: event.clientX, y: event.clientY });
          setHoldAddRowIcons(true);
        }}
        onMouseLeave={() => setShowAddRowBtn(holdAddRowIcons)}
        onBlur={clearAddOrRemoveIcons}
      >
        {showAddRowBtn && mouseLocalCoordinates && mouseLocalCoordinates.x !== 0 && (
          <div className="relative">
            <div className="absolute" style={{ left: mouseLocalCoordinates.x, bottom: 10 }}>
              <FontAwesomeIcon
                icon={faCirclePlus}
                className="w-[20px] h-full cursor-pointer text-green mr-1"
                onClick={() => {
                  clearAddOrRemoveIcons();
                  rowChangesListener(RowAndColumnChanges.Add);
                }}
              />
              <FontAwesomeIcon
                icon={faTrash}
                className="w-[20px] h-full cursor-pointer text-error"
                onClick={() => rowChangesListener(RowAndColumnChanges.Remove)}
              />
            </div>
          </div>
        )}
      </button>
    </div>
  );
}
