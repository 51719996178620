import React from "react";
import BlockBox from "./BlockBox";
import { PaddingContent } from "./PaddingContent";
import { Padding } from "../../../types/types";

const PaddingBlock: React.FC<{ padding: Padding; onChange: (padding: Padding) => void; className?: string }> = ({
  padding,
  onChange,
  className,
}) => {
  return (
    <BlockBox title="Padding" className={className}>
      <PaddingContent onChange={onChange} padding={padding} />
    </BlockBox>
  );
};

export default PaddingBlock;
