import React, { useState } from "react";
import SiteThemeContainer from "./SiteThemeContainer";
import GeneralSettingsButtonBlock from "./blocks/GeneralSettingsButtonBlock";
import BorderBlock from "./blocks/BorderBlock";
import { ButtonStyleType, SiteThemeType } from "../../types/types";
import ShadowBlock from "./blocks/ShadowBlock";
import HoverBlock from "./blocks/HoverBlock";
import PaddingBlock from "./blocks/PaddingBlock";

const ManageButtonDefaults: React.FC<{
  loading: boolean;
  siteThemeStyle: SiteThemeType;
  onSave: (style: { buttonStyle: ButtonStyleType; hoverButtonStyle: ButtonStyleType }) => void;

  onCancel: () => void;
}> = ({ siteThemeStyle, onSave, onCancel, loading }) => {
  const [buttonStyleState, setButtonStyleState] = useState<{
    buttonStyle: ButtonStyleType;
    hoverButtonStyle: ButtonStyleType;
  }>({
    buttonStyle: siteThemeStyle.buttonStyle,
    hoverButtonStyle: siteThemeStyle.hoverButtonStyle,
  });

  return (
    <SiteThemeContainer hasLeftBorder loading={loading} onCancel={onCancel} onSave={() => onSave(buttonStyleState)}>
      <GeneralSettingsButtonBlock
        backgroundColor={
          buttonStyleState.buttonStyle.background.color ? buttonStyleState.buttonStyle.background.color : ""
        }
        onChange={(color) => {
          setButtonStyleState({
            ...buttonStyleState,
            buttonStyle: {
              ...buttonStyleState.buttonStyle,
              background: { ...buttonStyleState.buttonStyle.background, color: color },
            },
          });
        }}
      />
      <BorderBlock
        className="mt-4"
        title="Border"
        border={buttonStyleState.buttonStyle.border}
        onChange={(value) => {
          setButtonStyleState({ ...buttonStyleState, buttonStyle: { ...buttonStyleState.buttonStyle, border: value } });
        }}
        borderStyleOptions={siteThemeStyle.borderStyleChoices}
        borderWidthOptions={siteThemeStyle.borderWidthChoices}
        borderSideOptions={siteThemeStyle.borderSideChoices}
      />
      <PaddingBlock
        onChange={(value) => {
          setButtonStyleState({
            ...buttonStyleState,
            buttonStyle: { ...buttonStyleState.buttonStyle, padding: value },
          });
        }}
        padding={buttonStyleState.buttonStyle.padding}
        className="mt-4"
      />
      <ShadowBlock
        className="mt-4"
        shadowStyle={buttonStyleState.buttonStyle.shadow}
        onChange={(value) =>
          setButtonStyleState({ ...buttonStyleState, buttonStyle: { ...buttonStyleState.buttonStyle, shadow: value } })
        }
      />
      <HoverBlock
        className="mt-4"
        borderSideOptions={siteThemeStyle.borderSideChoices}
        borderStyleOptions={siteThemeStyle.borderStyleChoices}
        borderWidthOptions={siteThemeStyle.borderWidthChoices}
        setButtonStyleState={(style) => setButtonStyleState({ ...buttonStyleState, hoverButtonStyle: { ...style } })}
        hoverButtonStyle={buttonStyleState.hoverButtonStyle}
      />
    </SiteThemeContainer>
  );
};

export default ManageButtonDefaults;
