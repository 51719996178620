import React, { useState } from "react";
import SiteThemeContainer from "./SiteThemeContainer";
import StyleOfFormFields from "./blocks/StyleOfFormFields";
import BorderBlock from "./blocks/BorderBlock";
import { FormStyleType, SiteThemeType } from "../../types/types";
import TextBorderAndBackgroundBlock from "./blocks/TextBorderAndBackgroundBlock";

const ManageFormDefaults: React.FC<{
  onCancel: () => void;
  onSave: (formStyle: FormStyleType) => void;
  siteThemeStyle: SiteThemeType;
  loading: boolean;
}> = ({ onCancel, onSave, siteThemeStyle, loading }) => {
  const [formStyleState, setFormStyleState] = useState<FormStyleType>(siteThemeStyle.formStyle);

  return (
    <SiteThemeContainer
      hasLeftBorder
      loading={loading}
      onCancel={onCancel}
      onSave={() => {
        onSave(formStyleState);
      }}
    >
      <StyleOfFormFields
        className="mt-4"
        formStyle={formStyleState}
        onChange={(value) => {
          setFormStyleState({
            ...formStyleState,
            textColor: value.textColor,
            padding: value.padding,
            backgroundColor: value.backgroundColor,
            placeholderColor: value.placeholderColor,
          });
        }}
      />
      <BorderBlock
        title="Border"
        border={formStyleState.border}
        onChange={(value) => {
          setFormStyleState({ ...formStyleState, border: value });
        }}
        className="mt-4"
        borderStyleOptions={siteThemeStyle.borderStyleChoices}
        borderWidthOptions={siteThemeStyle.borderWidthChoices}
        borderSideOptions={siteThemeStyle.borderSideChoices}
      />
      <TextBorderAndBackgroundBlock
        style={formStyleState.focus}
        title="Focus"
        className="mt-4"
        onChange={(value) => {
          setFormStyleState({ ...formStyleState, focus: value });
        }}
        borderStyleOptions={siteThemeStyle.borderStyleChoices}
        borderWidthOptions={siteThemeStyle.borderWidthChoices}
        borderSideOptions={siteThemeStyle.borderSideChoices}
      />
      <TextBorderAndBackgroundBlock
        style={formStyleState.disable}
        title="Disable"
        className="mt-4"
        onChange={(value) => {
          setFormStyleState({ ...formStyleState, disable: value });
        }}
        borderStyleOptions={siteThemeStyle.borderStyleChoices}
        borderWidthOptions={siteThemeStyle.borderWidthChoices}
        borderSideOptions={siteThemeStyle.borderSideChoices}
      />
      <TextBorderAndBackgroundBlock
        style={formStyleState.error}
        title="Error"
        className="mt-4"
        onChange={(value) => {
          setFormStyleState({ ...formStyleState, error: value });
        }}
        borderStyleOptions={siteThemeStyle.borderStyleChoices}
        borderWidthOptions={siteThemeStyle.borderWidthChoices}
        borderSideOptions={siteThemeStyle.borderSideChoices}
      />
      <TextBorderAndBackgroundBlock
        style={formStyleState.extraLabel}
        title="Extra Label"
        className="mt-4"
        onChange={(value) => {
          setFormStyleState({ ...formStyleState, extraLabel: value });
        }}
        borderStyleOptions={siteThemeStyle.borderStyleChoices}
        borderWidthOptions={siteThemeStyle.borderWidthChoices}
        borderSideOptions={siteThemeStyle.borderSideChoices}
      />
    </SiteThemeContainer>
  );
};

export default ManageFormDefaults;
