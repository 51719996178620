import React from "react";
import PageContainer from "../layout/PageContainer";

const Footer = () => {
  return (
    <PageContainer>
      <div>ManageFooter</div>
    </PageContainer>
  );
};

export default Footer;
