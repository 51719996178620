import React, { useEffect, useState } from "react";
import { uploadFiles } from "../../api/fileManager";
import { AxiosProgressEvent, AxiosResponse } from "axios";
import { Snackbar, SnackbarContent } from "@mui/material";

const UploadFileButton: React.FC<{ fileResponseHandler: (resp: AxiosResponse<any, any>) => void }> = ({ fileResponseHandler }) => {
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [showSnackbar, setShowSnackbar] = useState(false);

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file as Blob);
      uploadFiles(formData, (progressEvent: AxiosProgressEvent) => {
        if (progressEvent.total) {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(percentCompleted);
        }
      }).then((resp) => {
        if (resp.status === 200) {
          setShowSnackbar(true);
          fileResponseHandler(resp);
        }
      });

      ///////////////////
    }
  };

  useEffect(() => {
    if (uploadProgress === 100) {
      setTimeout(() => {
        setUploadProgress(0);
      }, 500);
    }
  }, [uploadProgress]);

  return (
    <div className="mt-4 sm:mt-0 ">
      <Snackbar
        open={showSnackbar}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "#03A678",
          },
          "& .MuiSnackbarContent-message": {
            fontSize: "16px",
            fontFamily: "'Open Sans', sans-serif",
            fontWeight: "100",
          },
        }}
        onClose={() => {
          setShowSnackbar(false);
        }}
      >
        <SnackbarContent message={"File Uploaded Successfully."} />
      </Snackbar>
      <input id="file-input" type="file" style={{ display: "none" }} onChange={(e) => onChangeHandler(e)} />
      <label htmlFor="file-input" className="cursor-pointer ml-1 px-4 py-[11px] bg-primary text-white rounded shadow">
        {uploadProgress ? "File " + uploadProgress + "% Uploaded" : " Upload Files"}
      </label>
    </div>
  );
};

export default UploadFileButton;
