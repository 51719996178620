import React, { useState } from "react";
import { faEye, faEyeSlash } from "@fortawesome/pro-regular-svg-icons";

import BlockDetailsSectionContainer from "../layout/BlockDetailsSectionContainer";

const BlockPreview: React.FC<{
  value: string;
  type?: string;
}> = ({ value }) => {
  const [collapsed, setCollapsed] = useState(false);

  const icon = collapsed ? faEyeSlash : faEye;

  return (
    <BlockDetailsSectionContainer
      title="Preview"
      headerActions={[{ icon, onClick: () => setCollapsed((_collapse) => !_collapse) }]}
      collapsible
      collapsed={collapsed}
      previewSource="/preview/page/preview_loader.html"
      previewValue={value}
    />
  );
};

export default BlockPreview;
