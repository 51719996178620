import React, { ReactNode } from "react";

const Modal: React.FC<{
  children: ReactNode;
  showModal: boolean;
  containerStyle?: React.CSSProperties;
}> = ({ children, showModal, containerStyle }) => {
  return (
    <>
      {showModal && (
        <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black/30">
          <div className="relative w-auto my-6 mx-auto max-w-3xl">
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              <div style={containerStyle} className="relative p-6 flex-auto">
                {children}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
