import React from "react";
import { Row } from "react-table";
import { DraggableProvided } from "react-beautiful-dnd";
import {
  DictPostValueType,
  DictValueOptionType,
  ManageValueAndPostCategoryType,
} from "./ManageValueAndPostCategory";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripDotsVertical } from "@fortawesome/pro-solid-svg-icons";
import { faEllipsisV } from "@fortawesome/pro-regular-svg-icons";
import { faCheck, faClose } from "@fortawesome/pro-solid-svg-icons";
import { OptionType } from "../../../../utils/types";
import OptionMenu from "./OptionMenu";
const TableRow: React.FC<{
  siteDefaultLanguage: string;
  row: Row<DictPostValueType>;
  allLanguags: string[];
  setShowOptionsMenu: (state: {
    dictValue?: DictPostValueType;
    showModal: boolean;
  }) => void;
  showOptionsMenu: { dictValue?: DictPostValueType; showModal: boolean };
  postTypes: OptionType[];
  onOptionClick: (
    dictValue: DictPostValueType,
    selectedValue: DictValueOptionType
  ) => void;
  rowHandler: (id: number) => void;
  provided: DraggableProvided;
  pageType: ManageValueAndPostCategoryType;
}> = ({
  siteDefaultLanguage,
  row,
  allLanguags,
  setShowOptionsMenu,
  showOptionsMenu,
  postTypes,
  onOptionClick,
  rowHandler,
  provided,
  pageType,
}) => {
  const renderLanguageRow = (dict: DictPostValueType) => {
    return (
      <div>
        {allLanguags?.map((lng) => {
          return (
            <label
              key={lng}
              className={`font-normal text-md mr-1 last:mr-0 ${
                dict.translation.includes(lng)
                  ? "text-primary"
                  : "text-secondary-300"
              }`}
            >
              {lng.toUpperCase()}
            </label>
          );
        })}
      </div>
    );
  };
  return (
    <tr
      {...provided.draggableProps}
      ref={provided.innerRef}
      onMouseDown={() => rowHandler(row.original.id)}
      className={`px-4 text-black2B border border-secondary-200 border-t-0 border-r-0 border-l-0  border-b`}
    >
      <td className="text-sm font-light pl-4 td-firefox table__cell sticky left-0">
        <div className="absolute left-0" {...provided.dragHandleProps}>
          <FontAwesomeIcon
            icon={faGripDotsVertical}
            className=" font-[12px] text-primary"
          />
        </div>
        {row.original.value[siteDefaultLanguage]}
      </td>
      {row.original.type &&
        pageType === ManageValueAndPostCategoryType.ManageValue && (
          <td className="text-sm font-light pl-4  td-firefox">
            {postTypes.find((pt) => pt.key === row.original.type)?.label}
          </td>
        )}
      <td className="text-sm font-light pl-4  td-firefox">
        <FontAwesomeIcon
          icon={row.original.isActive ? faCheck : faClose}
          className={`text-lg pl-4  ${
            row.original.isActive ? "text-success" : "text-error"
          }`}
        />
      </td>
      <td className="text-sm font-light pl-4  td-firefox">
        {renderLanguageRow(row.original)}
      </td>
      <td
        className="text-sm font-light pl-4 py-[13px] flex flex-row-reverse mr-3  td-firefox"
        onClick={() =>
          setShowOptionsMenu({
            dictValue: row.original,
            showModal: !showOptionsMenu.showModal,
          })
        }
        tabIndex={0}
        onBlur={() => {
          setShowOptionsMenu({ dictValue: undefined, showModal: false });
        }}
      >
        <FontAwesomeIcon
          icon={faEllipsisV}
          className=" text-xl text-gray cursor-pointer w-6 h-6"
        />
        <OptionMenu
          showOptionMenu={
            showOptionsMenu.showModal &&
            row.original.id === showOptionsMenu.dictValue?.id
          }
          onOptionClick={(selectedItem) => {
            onOptionClick(row.original, selectedItem);
          }}
        />
      </td>
    </tr>
  );
};

export default TableRow;
