import React, { useState } from "react";
import BlockBox from "./BlockBox";
import Dropdown from "../../inputs/Dropdown";
import { DropdownChoice } from "../../../types/types";
import ColorPicker from "../../inputs/ColorPicker";

const FontAndTextBlock: React.FC<{
  fontOptions: DropdownChoice[];
  fontWeightOptions: DropdownChoice[];
  style: { fontFamily: string; fontWeight: string; textColor: string };
  onChange: (fontStyle: { fontFamily: string; fontWeight: string; textColor: string }) => void;
}> = ({ fontOptions, style, fontWeightOptions, onChange }) => {
  const [fontStyleState, setFontStyleState] = useState(style);
  return (
    <BlockBox title="Font Setting">
      <div className="flex w-full justify-between flex-wrap">
        <div className="w-[238px]">
          <Dropdown
            options={fontOptions}
            onOptionSelect={(value) => {
              setFontStyleState({ ...fontStyleState, fontFamily: value.key as string });
              onChange({ ...fontStyleState, fontFamily: value.key as string });
            }}
            selectedOption={fontOptions.find((key) => key.key === fontStyleState.fontFamily) || null}
            label="Font Family"
          />
        </div>
        <div className="w-[238px]">
          <Dropdown
            options={fontWeightOptions}
            onOptionSelect={(value) => {
              setFontStyleState({ ...fontStyleState, fontWeight: value.key as string });
              onChange({ ...fontStyleState, fontWeight: value.key as string });
            }}
            selectedOption={fontWeightOptions.find((key) => key.key === fontStyleState.fontWeight) || null}
            label="Font Weight"
          />
        </div>
        <div className="w-[238px]">
          <ColorPicker
            color={fontStyleState.textColor}
            label="Text Color"
            setColor={(color) => {
              setFontStyleState({ ...fontStyleState, textColor: color });
              onChange({ ...fontStyleState, textColor: color as string });
            }}
          />
        </div>
      </div>
    </BlockBox>
  );
};

export default FontAndTextBlock;
