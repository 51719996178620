import { ContentData } from "../components/manage_content/ManageContent";
import { SlugValuePairType } from "../utils/types";
import API from "./_config";

export enum ContentSubmit {
  Publish,
  unPublish,
  Discard,
}

export const editCookieStatus = (url: string) => {
  return API.post(`cookie/${url}/`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const editCookie = (body: SlugValuePairType[]) => {
  return API.put("cookie/", body)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const getCookie = () => {
  return API.get("cookie/")
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const getContentPreview = (contentId: string, language?: string) => {
  return API.get(`content/${contentId}/preview/`, language ? { headers: { "accept-language": language } } : undefined)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export function getContentList(data: string) {
  return API.get(`content/${data}`)
    .then((response) => {
      return response;
    })
    .catch((e) => {
      throw e;
    });
}

export function setAsHomePage(contentId: string, status: boolean) {
  return API.patch(`content/${contentId}/`, { isHomepage: status })
    .then((response) => {
      return response;
    })
    .catch((e) => {
      throw e;
    });
}

export function deleteContent(id: string) {
  return API.delete(`content/${id}/`).then((response) => {
    return response;
  });
}

export function createPageOrPopup(contentData: ContentData, contentTypeKey: string) {
  return API.post(`content/?type=${contentTypeKey}`, contentData)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function updateContent(contentId: string, data: ContentData) {
  return API.put(`content/${contentId}/`, data)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function duplicateContent(contentId: string) {
  return API.post(`content/${contentId}/duplicate/`).then((response) => {
    return response;
  });
}

export function getContent(contentId: string) {
  return API.get(`content/${contentId}/`).then((response) => {
    return response;
  });
}

export function determineContentStatus(contentId: string, contentSubmit: ContentSubmit) {
  let apiRoute = `content/${contentId}/`;
  switch (contentSubmit) {
    case ContentSubmit.Publish:
      apiRoute += "publish/";
      break;
    case ContentSubmit.unPublish:
      apiRoute += "unpublish/";
      break;
    case ContentSubmit.Discard:
      apiRoute += "discard/";
      break;
  }

  return API.post(apiRoute).then((response) => {
    return response;
  });
}

export function getLanguages() {
  return API.get("get-site-languages/").then((response) => {
    return response;
  });
}

export function getContentRelatedLanguages(isTranslation: boolean, contentId?: string, contentType?: string) {
  let url = `content-related-languages/?translation=${isTranslation}`;
  if (contentId) url += `&id=${contentId}`;
  if (contentType) url += `&type=${contentType}`;

  return API.get(url).then((response) => {
    return response;
  });
}

export function getContentListDropDown(contentType: string) {
  return API.get(`content-list-dropdown?type=${contentType}`).then((response) => {
    return response;
  });
}
