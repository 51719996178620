import React from "react";
import AreYouSureModal from "./AreYouSureModal";
import { ContentSubmit } from "../../api/content";

interface Props {
  contentSubmit: ContentSubmit;
  showModal: boolean;
  onPositiveClicked: () => void;
  onNegativeClicked: () => void;
}

export default function UnsavedChangesModal(props: Props) {
  return (
    <AreYouSureModal
      showModal={props.showModal}
      title={props.contentSubmit === ContentSubmit.Discard ? "Discard changes !" : "Unpublish Attention !"}
      description={
        props.contentSubmit === ContentSubmit.Discard
          ? "All the changes you've made after publishing this page will be lost forever. Are you sure you want to discard unpublished changes?"
          : "Attention! Unpublishing a page with changes will permanently remove the current published version. Discard changes to keep the published version."
      }
      positiveButtonTitle={props.contentSubmit === ContentSubmit.Discard ? "Discard" : "Unpublish Anyway"}
      negativeButtonTitle="Cancel"
      onPositiveClicked={props.onPositiveClicked}
      onNegativeClicked={props.onNegativeClicked}
    />
  );
}
