import ManageValueAndPostCategory from "./data_dictionary/manageValue/ManageValueAndPostCategory";

const PostCategory = () => {
  return (
    <div className="flex w-full">
      <ManageValueAndPostCategory />
    </div>
  );
};

export default PostCategory;
