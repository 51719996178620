import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import Button, { ButtonType } from "../../Button";

const AddContentButton = () => {
  const navigate = useNavigate();
  const [showButtonModal, setShowButtonModal] = useState(false);
  const [boxIsHovered, setBoxIsHovered] = useState(false);
  return (
    <div className="mt-4 sm:mt-0 relative">
      <Button
        type={ButtonType.PRIMARY}
        onClick={() => {
          setShowButtonModal(!showButtonModal);
        }}
        onBlur={() => {
          !boxIsHovered && setShowButtonModal(false);
        }}
      >
        <FontAwesomeIcon icon={faPlus} className="text-base text-blue-primary mr-1" /> Add Content
      </Button>
      {showButtonModal && (
        <ul
          onMouseOver={() => setBoxIsHovered(true)}
          onMouseOut={() => setBoxIsHovered(false)}
          onTouchStart={() => setBoxIsHovered(true)}
          onTouchEnd={() => setBoxIsHovered(false)}
          className="absolute bg-white rounded shadow mt-1 z-50"
        >
          {[
            { key: "PA", label: "Page" },
            { key: "PO", label: "Popup" },
            { key: "PS", label: "Post" },
          ].map((item, i) => (
            <li
              className="cursor-pointer  w-[144px] text-xs font-normal h-8 flex items-center text-body_black hover:bg-secondary-100 px-2 "
              key={i}
              onClick={() => navigate(`/content/${0}/`, { state: { type: item.key } })}
            >
              {item.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default AddContentButton;
