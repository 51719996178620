import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "../../Modal";
import Button, { ButtonType } from "../../Button";
import { faExclamationCircle, faCircleXmark } from "@fortawesome/pro-light-svg-icons";
import { DictPostValueType } from "./manageValue/ManageValueAndPostCategory";
import { useState } from "react";

export enum DeleteModalType {
  Delete,
  InActive,
  Nothing,
}

interface Props {
  dictValueType: DictPostValueType;
  defaultLanguage: string;
  onPositiveClicked: (modalType: DeleteModalType) => void;
  onNegativeClicked?: () => void;
}

export default function DeleteDictValueModal(props: Props) {
  const [modalType, setModalType] = useState<DeleteModalType>(
    !props.dictValueType.isUsed
      ? DeleteModalType.Delete
      : props.dictValueType.isUsed && props.dictValueType.isActive
      ? DeleteModalType.InActive
      : DeleteModalType.Nothing
  );

  const getTitle = () => {
    switch (modalType) {
      case DeleteModalType.Delete:
        return "Delete !";
      case DeleteModalType.InActive:
        return "";
      case DeleteModalType.Nothing:
        return "";
    }
  };

  const getDescription = () => {
    const valueName = props.dictValueType.value[props.defaultLanguage];

    switch (modalType) {
      case DeleteModalType.Delete:
        return `Are you sure you want to delete ${valueName} permanently?`;
      case DeleteModalType.InActive:
        return `Unable to delete this ${valueName}. Deletion is only allowed for values without any related records.\nWould you like to inactive the ${valueName} instead?`;
      case DeleteModalType.Nothing:
        return `Unable to delete this ${valueName}. Deletion is only allowed for values without any related records.`;
    }
  };

  const getFunctionalButtons = () => {
    switch (modalType) {
      case DeleteModalType.Delete:
        return (
          <div>
            <Button className="mr-4" type={ButtonType.SECONDARY} onClick={props.onNegativeClicked!}>
              Cancel
            </Button>
            <Button type={ButtonType.PRIMARY} onClick={() => props.onPositiveClicked(modalType)}>
              Delete
            </Button>
          </div>
        );
      case DeleteModalType.InActive:
        return (
          <div>
            <Button className="mr-4" type={ButtonType.SECONDARY} onClick={props.onNegativeClicked!}>
              Cancel
            </Button>
            <Button type={ButtonType.PRIMARY} onClick={() => props.onPositiveClicked(modalType)}>
              Inactivate
            </Button>
          </div>
        );
      case DeleteModalType.Nothing:
        return (
          <Button type={ButtonType.SECONDARY} onClick={props.onNegativeClicked!}>
            Cancel
          </Button>
        );
    }
  };

  return (
    <Modal showModal>
      <div className="flex flex-col items-center w-[430px] max-w-[568px] py-8">
        <FontAwesomeIcon
          icon={modalType === DeleteModalType.Delete ? faExclamationCircle : faCircleXmark}
          className="text-[90px] text-error"
        />

        <label className="mt-[21px] text-error">{getTitle()}</label>
        <p className="text-center text-[#504F4E] mt-3 whitespace-pre-line">{getDescription()}</p>

        <div className="flex mt-8 self-center">{getFunctionalButtons()}</div>
      </div>
    </Modal>
  );
}
