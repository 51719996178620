import { useState } from "react";
import LoginContainer from "./LoginContainer";
import FormContainer from "./FormContainer";
import { sendEmail } from "../../api/auth";
import useRecaptchaV3 from "./reCaptchaV3/useRecaptchaV3";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [showMainForgotPage, setShowMainForgotPage] = useState(false);
  const [sendEmailLoading, setSendEmailLoading] = useState(false);
  const [error, setError] = useState("");
  const RECAPTCHA_SITE_KEY = "6LeFlVkoAAAAACXmpvL0bnTMhADka00VW4EhC0p1";
  const executeRecaptcha = useRecaptchaV3(RECAPTCHA_SITE_KEY);
  return (
    <LoginContainer>
      <FormContainer
        disableButton={email === ""}
        loading={sendEmailLoading}
        submitHandler={async () => {
          setSendEmailLoading(true);
          const recaptchaToken = await executeRecaptcha("forgotPassword");
          sendEmail({ email: email, token: recaptchaToken })
            .then((payload) => {
              if (payload.status === 200) {
                setSendEmailLoading(false);
                setShowMainForgotPage(true);
              }
              if (payload.response !== undefined && payload.response.status === 404) {
                setError(payload.response.data.email);
              } else if (payload.response !== undefined && payload.response.status === 403) {
                setError("Your account is locked after several wrong attempts.");
              } else {
                setError("");
              }
            })
            .finally(() => {
              setSendEmailLoading(false);
            });
        }}
        buttonText={`${showMainForgotPage ? "Didn't receive any Emails?" : "Submit"}`}
      >
        <h1 className="text-center text-3xl font-bold mt-36">Forgot Password</h1>
        {!showMainForgotPage ? (
          <div>
            <div className="text-center text-base mt-4">Please enter your registered email address.</div>
            <div className="mt-[30px]">
              <label htmlFor="email" className="text-base ">
                Email*
              </label>
              <input
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                id="email"
                type="text"
                placeholder="adam.smith@example.com"
                className="border  w-full mt-2 h-11 px-1 outline-0 border-secondary-300 rounded"
              ></input>
              {error && email !== "" && <div className="text-red-500">{error}</div>}
            </div>
          </div>
        ) : (
          <div className="text-center text-base mt-4">
            Please check your email. Assuming the email address you supplied is accurate, a message should have been
            sent to you. Kindly review your inbox and follow the instructions given.
            {error && email !== "" && <div className="text-red-500">{error}</div>}
          </div>
        )}
      </FormContainer>
    </LoginContainer>
  );
};

export default ForgotPassword;
