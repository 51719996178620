import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";

const Checkbox: React.FC<{
  name: string;
  value: boolean;
  onChange: (value: boolean) => void;
  isDisable?: boolean;
}> = ({ name, value, onChange, isDisable }) => {
  return (
    <div onClick={() => onChange(!value)} className={`flex items-center ${isDisable && "opacity-50"}`}>
      <div
        className={`w-[18px] h-[18px] mr-2 border-[1px] rounded grid place-items-center ${
          !isDisable && "cursor-pointer"
        } ${value === true ? "bg-primary border-primary" : "bg-white border-secondary-300"}`}
      >
        {value === true && <FontAwesomeIcon icon={faCheck} className="text-sm text-white" />}
      </div>
      <label className={`text-sm font-light text-black2B  ${!isDisable && "cursor-pointer"}`}>{name}</label>
    </div>
  );
};

export default Checkbox;
