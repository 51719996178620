import React, { useState } from "react";
import BlockBox from "./BlockBox";
import { BackgroundType } from "../../../types/types";
import ColorPicker from "../../inputs/ColorPicker";

const BackGroundBlock: React.FC<{
  backGround: BackgroundType;
  onChange: (backGround: BackgroundType) => void;
  className?: string;
}> = ({ backGround, onChange, className }) => {
  const [backgroundStyle, setBackgroundStyle] = useState(backGround);

  return (
    <BlockBox className={className} title="Background">
      <ColorPicker
        color={backgroundStyle.color}
        label="Color"
        setColor={(color) => {
          onChange({ ...backgroundStyle, color: color });
          setBackgroundStyle({ ...backgroundStyle, color: color });
        }}
      />
    </BlockBox>
  );
};

export default BackGroundBlock;
